import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import moment from 'moment';

import UserStageIndicator from '../../components/UserStageIndicator';
import Sidebar from '../../components/Sidebar';

import './styles.scss';

import {setCurrentUser} from '../../actions//index';

import getUserInfo from '../../api/getUserInfo';

import getUserStage from '../../utils/getUserStage';

import {TRIAL} from '../../constants';
import strings from '../../utils/strings';

class Backoffice extends React.Component {
	componentDidMount() {
		const {handleSetCurrentUser} = this.props;

		getUserInfo()
			.then((user) => {
				let isLoggedIn = false;
				if (user.subscription.super) {
					user.isSuperAdmin = true;
					user.userStage = getUserStage(user);
					isLoggedIn = true;
				} else {
					user.isSuperAdmin = false;
					user.userStage = getUserStage(user);
					isLoggedIn = true;
				}

				handleSetCurrentUser(user);

				if (!isLoggedIn) {
					throw new Error('Unauthorized error');
				}
			})
			.catch(() => {
				window.location.href = '/login';
			});
	}

	render() {
		const {currentUser} = this.props;

		if (!currentUser) {
			return null;
		}
		const {isSuperAdmin} = currentUser;
		let trialExpiryText;

		const now = moment();
		if (currentUser.userStage === TRIAL) {
			const trialValidUntilMoment = moment(
				currentUser.subscription.trialValidUntil
			);
			if (trialValidUntilMoment.isAfter(now)) {
				trialExpiryText = strings.formatString(
					strings.expiresIn,
					trialValidUntilMoment.fromNow(true)
				);
			} else {
				trialExpiryText = strings.expired;
			}
		}

		return (
			<div>
				<Sidebar isSuperAdmin={isSuperAdmin} />
				<div className="lit-view">
					<header className="lit-header">
						<div />
						<div className="lit-header-info">
							<div>{`${currentUser.firstName} ${currentUser.lastName}`}</div>
							<UserStageIndicator
								className="lit-header-info__expires"
								small
								user={currentUser}
								withText
							/>

							{currentUser.userStage === TRIAL && (
								<span className="lit-header-info__expires">
									{trialExpiryText}
								</span>
							)}
						</div>
					</header>
					<div className="lit-view__content">
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

Backoffice.propTypes = {
	currentUser: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {
	handleSetCurrentUser: setCurrentUser,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Backoffice)
);

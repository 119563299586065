import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';

class ChangeRequests extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			globalChangeRequest: this.props.newStrings,
		};
		this.textarea = React.createRef();
	}

	assembleAndOrganizeStrings(fileName, newStrings) {
		let newChangeRequests = this.state.globalChangeRequest;
		delete newChangeRequests[fileName];
		let newFileContents = this.props[fileName];
		for (let i = 0; i < newStrings.length; i++) {
			for (const property in newFileContents) {
				if (property === newStrings[i].name) {
					newFileContents[property] = newStrings[i].data;
					break;
				}
			}
		}

		this.props.setAndUpdateLanguageFiles(
			fileName,
			newFileContents,
			newChangeRequests
		);
	}

	render() {
		const {changeRequests} = this.props;
		return (
			<div className="lit-modal-stringsCatalog">
				<div
					className="lit-modal__ls-continent"
					onClick={(event) => {
						event.stopPropagation();
					}}
				></div>

				<div className="lit-string-change-catalog">
					<div className="lit-string-change-files">
						<div className="lit-close-icon-wrapper">
							<div className="lit-close-icon">
								<span
									className="lit-close-sl-icon"
									onClick={() => {
										this.props.closeWindow();
									}}
								>
									X
								</span>
							</div>
						</div>
						{changeRequests.map((requests) => {
							const {fileName, newStrings} = requests;
							return (
								<div className="lit-title-header-wrapper">
									<div className="lit-strings-change-header">
										<h3>{fileName}</h3>
										<br></br>
									</div>
									<div className="lit-strings-theader-wrapper">
										<table className="lit-list user-list">
											<thead>
												<tr>
													<th>{'key'}</th>
													<th>{'value'}</th>
												</tr>
											</thead>

											<tbody>
												{newStrings.map(
													(singleString, index) => {
														const {
															name,
															data,
														} = singleString;
														return (
															<tr
																key={index}
																className="lit-list__item"
															>
																<td className="lit-list__col large">
																	{name}
																</td>
																<td className="lit-list__col large">
																	{data}
																</td>
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>
									{changeRequests && (
										<div className="lit-update-strings">
											<div className="lit-change-btn-group">
												<div className="lit-lang-sendChange">
													<button
														className="lit-lang-sendChange__bg"
														onClick={() => {
															this.assembleAndOrganizeStrings(
																fileName,
																newStrings
															);
														}}
													>
														Mark Completed
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default ChangeRequests;

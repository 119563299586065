import React from 'react';
import classNames from 'classnames';
import TestUserInfo from './TestUserInfo';
import Subtest from './Subtest';
import SubtestReport from './SubtestReport';
import SubtestAnswers from './SubtestAnswers';
import strings from '../../utils/strings';
import moment from 'moment';

import './styles.scss';

import getSingleTestResult from '../../api/getSingleTestResult';
import updateLogEvent from '../../api/updateLogEvent';

import {withRouter} from 'react-router-dom';

import HorizontalBarChart from '../../components/HorizontalBarChart';

import test5Questions from './test5.json';
import test6Questions from './test6.json';
import test5youthQuestions from './test5youth.json';
import test5youthQuestionsNn from './test5youthNn.json';

import test5youthQuestionsDe from './test5YouthDe.json';
import test6youthQuestionsDe from './test6YouthDe.json';

import test5youthQuestionsSe from './test5YouthSe.json';
import test6youthQuestionsSe from './test6YouthSe.json';

import test6youthQuestions from './test6youth.json';
import test6youthQuestionsNn from './test6youthNn.json';

import test5childQuestions from './test5child.json';
import test5childQuestionsNn from './test5childNn.json';

import test6childQuestions from './test6child.json';
import test6childQuestionsNn from './test6childNn.json';

import test6childUpperQuestions from './test6childUpper.json';
import test6childUpperQuestionsNn from './test6childUpperNn.json';

const TESTS_REQUIRED_TO_PASS = 3;

class SingleTest extends React.Component {
	constructor() {
		super();
		this.state = {
			showTestUserInfo: false,
			testResult: null,
			testType: null,
			testId: null,
			testKey: null,
			showResultsTab: true,
			showReportTab: false,
			showInformationTab: false,
			showResultsActive: 'tablink1-active',
			showReportActive: 'tablink2',
			showInformationActive: 'tablink3',
			animator: 'marker-default',
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		window.scrollTo(0, 0);
		getSingleTestResult(id).then((testResult) => {
			const testType = testResult.type;
			const testUser = testResult.testUser;

			testResult.subtests.map((subtest) => {
				let questions = false;
				switch (testType) {
					case 'adult-screening':
						if (subtest.id === 5) {
							questions = test5Questions;
						} else if (subtest.id === 6) {
							questions = test6Questions;
						}
						break;

					case 'youth-screening':
						questions = this.setupQuestion(subtest, questions);
						break;
					case 'child-screening':
						const processedTestResult = this.reOrderChildScreening(
							testResult
						);

						const {testUser} = processedTestResult;
						const childGrade = testUser.education.youthGrade;

						if (subtest.id === 5 && subtest.language === 'no') {
							questions =
								test5childQuestions[childGrade.toString()];
						} else if (
							subtest.id === 5 &&
							subtest.language === 'nn'
						) {
							questions =
								test5childQuestionsNn[childGrade.toString()];
						} else if (
							subtest.id === 6 &&
							subtest.language === 'no'
						) {
							if (childGrade < 5) {
								questions = test6childQuestions;
							} else {
								questions = test6childUpperQuestions;
							}
						} else if (
							subtest.id === 6 &&
							subtest.language === 'nn'
						) {
							if (childGrade < 5) {
								questions = test6childQuestionsNn;
							} else {
								questions = test6childUpperQuestionsNn;
							}
						}
						break;
				}

				if (subtest.id === 4) {
					subtest.formattedAnswers = this.formatTypingSpeedAnswers(
						subtest.answers
					);
				} else if (subtest.id === 1 && testType === 'child-screening') {
					const {testUser} = testResult;
					const childGrade = testUser.education.youthGrade;

					subtest.formattedAnswers = this.getAnswersWithCorrectAnswerforWD(
						subtest.answers,
						subtest.answersCorrect,
						questions,
						childGrade
					);
				} else {
					subtest.formattedAnswers = this.getAnswersWithCorrectAnswer(
						subtest.answers,
						subtest.answersCorrect,
						questions
					);
				}

				return subtest;
			});
			this.setState({
				testResult,
				testId: testResult.id,
				testKey: testResult.key,
				testType: testType,
				testUser,
			});
		});
	}

	setupQuestion(subtest, questions) {
		switch (subtest.id) {
			case 5:
				if (subtest.language === 'no') {
					questions = test5youthQuestions;
					return questions;
				}
				if (subtest.language === 'nn') {
					questions = test5youthQuestionsNn;
					return questions;
				}
				if (subtest.language === 'se') {
					questions = test5youthQuestionsSe;
					return questions;
				}
				if (subtest.language === 'de') {
					questions = test5youthQuestionsDe;
					return questions;
				}

			case 6:
				if (subtest.language === 'no') {
					questions = test6youthQuestions;
					return questions;
				}
				if (subtest.language === 'nn') {
					questions = test6youthQuestionsNn;
					return questions;
				}
				if (subtest.language === 'de') {
					questions = test6youthQuestionsDe;
					return questions;
				}
				if (subtest.language === 'se') {
					questions = test6youthQuestionsSe;
					return questions;
				}
		}
		return false;
	}
	reOrderChildScreening(testResult) {
		let allSubtests = [];

		for (let i = 0; i < testResult.subtests.length; i++) {
			switch (testResult.subtests[i].id) {
				case 3:
					allSubtests[0] = testResult.subtests[i];
					break;
				case 2:
					allSubtests[1] = testResult.subtests[i];
					break;
				case 4:
					allSubtests[2] = testResult.subtests[i];
					break;
				case 6:
					allSubtests[3] = testResult.subtests[i];
					break;
				case 5:
					allSubtests[4] = testResult.subtests[i];
					break;
				case 1:
					allSubtests[5] = testResult.subtests[i];
					break;
			}
		}
		if (allSubtests.length) {
			allSubtests = allSubtests.filter(function (element) {
				return element !== undefined;
			});

			testResult.subtests = allSubtests;
		}
		return testResult;
	}

	getQuestionChoices(question) {
		const output = {};

		for (const choice of question.choices) {
			output[choice.letter] = choice;
		}
		return output;
	}
	updatePrintLog(action) {
		updateLogEvent('print', this.state.testId, '', action);
	}

	formatTypingSpeedAnswers(answers) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			const answerArray = answers[i].split(' ');
			output.push(
				answerArray.map((answer, index) => {
					return {
						id: index,
						value: answer,
					};
				})
			);
		}
		return output;
	}

	getAnswersWithCorrectAnswer(answers, answersCorrect, questions) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected
			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue = currentCorrectAnswer.value
						.toLowerCase()
						.trim();
					const isCorrectAnswer =
						currentAnswerValue === currentCorrectAnswer.value;

					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);
					const isCorrectAltValue =
						hasAltValues &&
						currentCorrectAnswer.altValues.indexOf(
							currentAnswerValue
						) !== -1;

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer || isCorrectAltValue,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = this.getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	getAnswersWithCorrectAnswerforWD(
		answers,
		answersCorrect,
		questions,
		childGrade
	) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected

			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue =
						childGrade < 5
							? currentCorrectAnswer.value.toLowerCase().trim()
							: currentCorrectAnswer.upperGradeValue
									.toLowerCase()
									.trim();

					const isCorrectAnswer =
						childGrade < 5
							? currentAnswerValue === currentCorrectAnswer.value
							: currentAnswerValue ===
							  currentCorrectAnswer.upperGradeValue;
					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = this.getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	render() {
		const {showTestUserInfo, testResult} = this.state;
		if (!testResult) {
			return null;
		}

		const {testUser} = testResult;

		const subtestsLength = testResult.subtests.length;
		let zScoreLowCount = 0;
		let totalSubTestsTaken = 0;
		testResult.subtests.forEach((subtest) => {
			totalSubTestsTaken++;
			if (subtest.zScore <= -1) {
				zScoreLowCount++;
			}
		});

		const completeName = testUser.firstName
			? `${testUser.firstName} ${testUser.lastName}`
			: strings.hiddenname;
		let writeUpText;
		if (zScoreLowCount >= TESTS_REQUIRED_TO_PASS) {
			writeUpText = strings.formatString(
				strings.localizedwriteuptextforlowresults,
				`${completeName}`,
				`${zScoreLowCount}`,
				`${subtestsLength}`
			);
		} else {
			writeUpText = strings.formatString(
				strings.localizedwriteuptextforNormalresults,
				`${completeName}`,
				`${subtestsLength - zScoreLowCount}`
			);
		}

		return (
			<div className="lit-single-test">
				<div
					className={
						this.state.testType === 'youth-screening'
							? 'lit-single-youthtest-title-desc'
							: 'lit-single-adulttest-title-desc'
					}
				>
					<h1 className="lit-page-title">
						{this.state.showResultsTab
							? strings.formatString(
									strings.dynamicheaderfortestresult_adult,
									this.state.testType ===
										'youth-screening' ? (
										<b>{strings.youthscreening}</b>
									) : this.state.testType ===
									  'adult-screening' ? (
										<b>{strings.adultscreening}</b>
									) : (
										<b>
											{
												'Dysmate - C (Screeningtest for barn)'
											}
										</b>
									)
							  )
							: this.state.showTestUserInfo
							? strings.formatString(
									strings.dynamicheaderfortestcandidate_adult,
									this.state.testType ===
										'youth-screening' ? (
										<b>{strings.youthscreening}</b>
									) : this.state.testType ===
									  'adult-screening' ? (
										<b>{strings.adultscreening}</b>
									) : (
										<b>
											{
												'Dysmate - C (Screeningtest for barn)'
											}
										</b>
									)
							  )
							: this.state.showReportTab
							? strings.formatString(
									strings.dynamicheaderfortestreport_adult,
									this.state.testType ===
										'youth-screening' ? (
										<b>{strings.youthscreening}</b>
									) : this.state.testType ===
									  'adult-screening' ? (
										<b>{strings.adultscreening}</b>
									) : (
										<b>
											{
												'Dysmate - C (Screeningtest for barn)'
											}
										</b>
									)
							  )
							: ''}
					</h1>
					<p className="lit-page-lead">
						{strings.formatString(
							this.state.showResultsTab
								? strings.info_results_belowpresentsinformation
								: this.state.showTestUserInfo
								? strings.info_user_belowpresentsinformation
								: this.state.showReportTab
								? strings.info_report_belowpresentsinformation
								: '',

							<b>{completeName}</b>,
							<b>
								{this.state.testType !== 'adult-screening'
									? `, ${this.state.testUser.education.youthGrade}.${strings.grade}`
									: ''}
							</b>
						)}
						{testResult.completedAt
							? strings.formatString(
									strings.info_testwascompletedon,

									<b>
										{moment(testResult.completedAt).format(
											'DD.MM.YYYY'
										)}
									</b>
							  )
							: strings.info_testwasNotcompletedon}
						{strings.formatString(
							strings.infor_scr_ageAtCommencement,
							<b>{testUser.age.years}</b>,

							<b>{testUser.age.months}</b>
						)}
					</p>
				</div>

				<div className="newBorderDiv">
					<div className="lit-tab-wrapper">
						<button
							className={this.state.showResultsActive}
							id="defaultOpen"
							onClick={() => {
								this.setState({
									showResultsTab: true,
									showReportTab: false,
									showInformationTab: false,
									showTestUserInfo: false,
									showResultsActive: 'tablink1-active',
									showReportActive: 'tablink2',
									showInformationActive: 'tablink3',
									animator: 'marker-default',
								});
							}}
						>
							Resultater
						</button>
						<button
							className={this.state.showReportActive}
							onClick={() => {
								this.setState({
									showResultsTab: false,
									showReportTab: true,
									showInformationTab: true,
									showTestUserInfo: false,
									showResultsActive: 'tablink1',
									showReportActive: 'tablink2-active',
									showInformationActive: 'tablink3',
									animator: 'marker-report',
								});
							}}
						>
							Rapport
						</button>
						<button
							className={this.state.showInformationActive}
							onClick={() => {
								this.setState({
									showResultsTab: false,
									showReportTab: true,
									showInformationTab: false,
									showTestUserInfo: true,
									showResultsActive: 'tablink1',
									showReportActive: 'tablink2',
									showInformationActive: 'tablink3-active',
									animator: 'marker-information',
								});
							}}
						>
							{strings.userinformation}
						</button>
					</div>

					{this.state.showResultsTab &&
						!this.state.showReportTab &&
						!this.state.showInformationTab && (
							<div className="lit-histogram-Test_Details">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											window.print();
											this.updatePrintLog('Results');
										}}
									>
										{strings.printtestresults}
									</button>
								</div>

								<div className="sub-test total print-summary">
									{!testResult.completedAt && (
										<div className="incomplete-notice">
											{strings.incompleteMessage}
										</div>
									)}

									<h3 className="sub-test__name big">
										{strings.summary}
									</h3>
									<div className="summary-chart">
										<HorizontalBarChart
											subtests={testResult.subtests}
											testType={this.state.testType}
										/>
									</div>
									<div className="explanations">
										<div className="explanation">
											<div className="explanation__figure negative" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscorered,
													<em>{strings.under}</em>
												)}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure neutral" />
											<p className="explanation__text">
												{strings.zscoreblue}
											</p>
										</div>
										<div className="explanation">
											<div className="explanation__figure positive" />
											<p className="explanation__text">
												{strings.formatString(
													strings.zscoregreen,
													<em>{strings.over}</em>
												)}
											</p>
										</div>
									</div>
									<div className="sub-test__scores">
										<div className="width--half center">
											<h4 className="sub-test__heading big">
												{strings.assessment}
											</h4>
											<p>{writeUpText}</p>
										</div>
									</div>
								</div>

								<div className="print-sub-tests">
									{testResult.subtests.map((subtest) => {
										return (
											<Subtest
												subtest={subtest}
												type={this.state.testType}
												testType={this.state.testType} //is going to be used for better rendering of gjennomsnitting og stadaravvik skåre
												testResult={
													this.state.testResult
												}
												key={subtest.id}
											>
												<SubtestAnswers
													subtest={subtest}
													viewModes={
														this.state.viewModes
													}
												/>
											</Subtest>
										);
									})}
								</div>
							</div>
						)}

					{showTestUserInfo && (
						<TestUserInfo
							testResult={testResult}
							testType={this.state.testType}
						/>
					)}
					{this.state.showReportTab && !this.state.showTestUserInfo && (
						<div className="lit-histogram-Test_Report">
							<div className="lit-printBtn">
								<button
									className={classNames('print-hide', {
										'lit-btn2 see-more': true,
										'bg-tertiary': true,
									})}
									onClick={() => {
										window.print();
										this.updatePrintLog('Report');
									}}
								>
									{strings.printreport}
								</button>
							</div>
							<div className="sub-test total print-summary">
								{!testResult.completedAt && (
									<div className="incomplete-notice">
										{strings.incompleteMessage}
									</div>
								)}

								<h3 className="sub-test__name big">
									{strings.summary}
								</h3>
								<div className="summary-chart">
									<HorizontalBarChart
										subtests={testResult.subtests}
										testType={this.state.testType}
									/>
								</div>
								<div className="explanations">
									<div className="explanation">
										<div className="explanation__figure negative" />
										<p className="explanation__text">
											{strings.formatString(
												strings.zscorered,
												<em>{strings.under}</em>
											)}
										</p>
									</div>
									<div className="explanation">
										<div className="explanation__figure neutral" />
										<p className="explanation__text">
											{strings.zscoreblue}
										</p>
									</div>
									<div className="explanation">
										<div className="explanation__figure positive" />
										<p className="explanation__text">
											{strings.formatString(
												strings.zscoregreen,
												<em>{strings.over}</em>
											)}
										</p>
									</div>
								</div>
								<div className="print-sub-tests">
									{testResult.subtests.map((subtest) => {
										return (
											<SubtestReport
												subtest={subtest}
												key={subtest.id}
												type={this.state.testType}
												testResult={
													this.state.testResult
												}
											></SubtestReport>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withRouter(SingleTest);

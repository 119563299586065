import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';
import {updateObject, updateItemInArray} from '../../utils/reducerUtils';

const genders = [
	{
		label: 'Jente',
		value: 'f',
	},
	{
		label: 'Gutt',
		value: 'm',
	},
	,
	{
		label: 'Annet',
		value: 'u',
	},
];
const yesAndno = [
	{
		label: 'Nei',
		value: 'no',
	},
	{
		label: 'Ja',
		value: 'yes',
	},
	{
		label: 'Vet ikke',
		value: 'doesNotKnow',
	},
];

const now = moment();

class CandidateInfo extends React.Component {
	constructor(props) {
		super(props);
		this.updateTestUser = this.updateTestUser.bind(this);
		this.state = {
			submitEnabled: true,
			isFormValid: false,
			birthDate: moment().subtract(10, 'year').toDate(),
			candidateGrade: null,
			isNorming: 0,
			excludeReading: 0,
			dateIsWrong: false,
			showReadingTestOption: false,
			testUserInfo: {
				firstName: '',
				lastName: '',
				gender: '',
				email: '',
				testDevice: '',
				age: {
					years: 0,
					months: 0,
				},
				education: {
					youthGrade: 0,
				},
			},
		};
	}
	updateTestUser(categoryKey, key, value) {
		const {testUserInfo} = this.state;

		if (categoryKey) {
			const category = updateObject(testUserInfo[categoryKey], {
				[key]: value,
			});

			this.setState({
				testUserInfo: updateObject(testUserInfo, {
					[categoryKey]: category,
				}),
			});
		} else {
			if (
				key === 'education' &&
				(value.youthGrade == 2 || value.youthGrade == 3)
			) {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
					showReadingTestOption: true,
				});
			} else if (
				key === 'education' &&
				value.youthGrade != 2 &&
				value.youthGrade != 3
			) {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
					showReadingTestOption: false,
					excludeReading: 0,
				});
			} else {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
				});
			}
		}
	}
	sendCodeAndInfoUsingEmail = (event, testUser, pin) => {
		event.preventDefault();
		const {age} = testUser;
		if (age.years < 3 || age.years > 90) {
			this.setState({dateIsWrong: true});
			return;
		}
		const {id, testKey, pinCode} = this.props.infoPanelTestKey;
		const isNorming = this.state.isNorming;
		const excludeReading = this.state.excludeReading;
		const {processTestResults, testResults} = this.props;

		const nextTestResults = updateItemInArray(
			testResults,
			id,
			(currentTestResult) =>
				updateObject(currentTestResult, {
					distributedAt: moment(),
				})
		);
		const nextBatchedTestResults = processTestResults(nextTestResults);

		this.props.onDistributeKeyAndStoreInfo(
			isNorming,
			testKey,
			testUser,
			excludeReading,
			pin
		);
	};
	render() {
		const {isFormValid, birthDate, testUserInfo} = this.state;
		const {pinCode, testKey: keyLable} = this.props.infoPanelTestKey;
		const {
			email,

			gender,
		} = testUserInfo;
		let formRef;
		const allGrades = [
			{
				id: 2,

				value: 2,
				label: '2. Trinn',
			},
			{
				id: 3,

				value: 3,
				label: '3. Trinn',
			},
			,
			{
				id: 4,

				value: 4,
				label: '4. Trinn',
			},
			{
				id: 5,

				value: 5,
				label: '5. Trinn',
			},
			,
			{
				id: 6,

				value: 6,
				label: '6. Trinn',
			},
			,
			{
				id: 7,

				value: 7,
				label: '7. Trinn',
			},
		];

		const {testResults} = this.props;
		const {id, key: testKey, testUser} = testResults;

		let firstName;
		let lastName;
		let fullName;

		if (testUser) {
			email = testUser.email;
			firstName = testUser.firstName;
			lastName = testUser.lastName;
			fullName = `${firstName} ${lastName}`;
		}

		let emailInviteRef;
		return (
			<div
				className="lit-modal-candidate"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__continent"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit-candidate-info">
						<div className="lit-candidate-container">
							<div className="lit-candidate-info-header">
								<h3 className="lit-candidate-info-header__bolder">
									{'Opplysninger om testkandidaten (eleven)'}
								</h3>
							</div>
							<form
								className="lit-input-candidate"
								onChange={() => {
									const formValidity = formRef.checkValidity();

									if (
										isFormValid !== formValidity &&
										!this.state.dateIsWrong
									) {
										this.setState({
											isFormValid: formValidity,
										});
									}
								}}
								onSubmit={(e) => {
									e.preventDefault();
									this.sendCodeAndInfoUsingEmail(
										e,
										this.state.testUserInfo,
										pinCode
									);
								}}
								ref={(ref) => {
									formRef = ref;
								}}
							>
								<div className="lit-info__main-container">
									<div className="lit-info__main-container__wrapper">
										<div className="lit-info__field">
											<div className="lit-candidate-label">
												<label
													className="lit-test__heading"
													htmlFor={`firstName`}
												>
													{strings.firstname}
												</label>
												<div>
													<input
														className="lit-input__field"
														id={`firstName-${id}`}
														name={`firstName`}
														placeholder="Ragnar"
														onChange={(event) => {
															this.updateTestUser(
																null,
																'firstName',
																event.target
																	.value
															);
														}}
														required
														type="text"
													/>
												</div>
											</div>
											<div className="lit-candidate-label">
												<label
													className="lit-test__heading"
													htmlFor={`lastName`}
												>
													{strings.lastname}
												</label>
												<div>
													<input
														className="lit-input__field"
														id="email"
														name="lastName"
														placeholder="Lothbrok"
														onChange={(event) => {
															const value =
																event.target
																	.value;
															this.updateTestUser(
																null,
																'lastName',
																value
															);
														}}
														required
														type="text"
													/>
												</div>
											</div>
										</div>

										<div className="lit-info__field-second">
											<div className="lit-candidate-label">
												<label
													className="lit-test__heading"
													htmlFor={`email`}
												>
													{strings.email}
												</label>
												<div>
													<input
														className="lit-input__field"
														id={`email${id}`}
														name={`email`}
														placeholder="ola@epost.no"
														onChange={(event) => {
															this.updateTestUser(
																null,
																'email',
																event.target
																	.value
															);
														}}
														type="email"
													/>
												</div>
											</div>
											<div className="lit-candidate-label-gender">
												<label className="lit-test__heading">
													{strings.gender}
												</label>
												<div className="lit-options-gender">
													{genders.map(
														(genderOption) => {
															const {
																label: genderLabel,
																value,
															} = genderOption;

															return (
																<span
																	className="lit-radio"
																	key={value}
																>
																	<input
																		checked={
																			value ===
																			gender
																		}
																		className="lit-radio__field"
																		id={`gender${value}`}
																		name="gender"
																		onChange={() => {
																			this.updateTestUser(
																				null,
																				'gender',
																				value
																			);
																		}}
																		required
																		type="radio"
																	/>
																	<label
																		className="lit-radio__label"
																		htmlFor={`gender${value}`}
																	>
																		{
																			genderLabel
																		}
																	</label>
																</span>
															);
														}
													)}
												</div>
											</div>
										</div>
										<div className="lit-info__field-second">
											<div className="lit-candidate-label">
												<label
													className="lit-test__heading"
													htmlFor={`age`}
												>
													{strings.birthDate}
												</label>
												<div className="lit-input__field">
													<input
														type="date"
														placeholder="dd-mm-yyyy"
														name="age"
														onChange={(event) => {
															const numberOfMonths = 12;
															const value =
																event.target
																	.value;

															const age = {
																years: now.diff(
																	value,
																	'years'
																),
																months:
																	now.diff(
																		value,
																		'months'
																	) %
																	numberOfMonths,
															};
															if (
																age.years > 2 &&
																age.years < 90
															) {
																this.setState({
																	dateIsWrong: false,
																});
															} else {
																this.setState({
																	dateIsWrong: true,
																});
															}
															this.updateTestUser(
																null,
																'age',
																age
															);
														}}
														required
													/>
												</div>
												{this.state.dateIsWrong && (
													<span
														style={{
															color: 'DarkOrange',
															fontSize: '14px',
														}}
													>
														<i>
															{`Fødselsdato er ikke riktig`}
														</i>
													</span>
												)}
											</div>

											<div className="lit-candidate-label-grade">
												<label className="lit-test__heading">
													{strings.classGrade}
												</label>
												<div>
													<select
														className="lit-input__field-trinn"
														id={`email-invite-${id}`}
														name={`youthGrade`}
														required="required"
														onChange={(event) => {
															const education = {
																youthGrade:
																	event.target
																		.value,
															};
															this.updateTestUser(
																null,
																'education',
																education
															);
														}}
													>
														<option
															id={0}
															key={0}
															value=""
															disabled
															selected
														>
															{'-Velg trinn-'}
														</option>
														{allGrades.map(
															(grades) => (
																<option
																	id={
																		grades.id
																	}
																	key={
																		grades.id
																	}
																	value={
																		grades.value
																	}
																>
																	{
																		grades.label
																	}
																</option>
															)
														)}
													</select>
												</div>
											</div>
										</div>

										<div className="lit-info__field-second">
											<div className="lit-candidate-label">
												<label className="lit-test__heading">
													{'Pinkode'}
												</label>
												<div className="lit-input__field">
													<span className="lit-candidate-pin">
														{pinCode
															? pinCode
															: 'Skjult'}
													</span>
												</div>
											</div>
											<div className="lit-candidate-label">
												<label className="lit-test__heading">
													{'Testnøkkel'}
												</label>
												<div className="lit-input__field">
													<span className="lit-candidate-pin">
														{keyLable}
													</span>
												</div>
											</div>
										</div>
										<div className="lit-info__field-third">
											{this.state
												.showReadingTestOption && (
												<div className="lit-info__field-fourth">
													<div className="lit-candidate-label">
														<label className="lit-test__heading">
															{
																strings.question_excludeReadingTest
															}
														</label>
														<br></br>
														{yesAndno.map(
															(options) => {
																const {
																	label,
																	value,
																} = options;

																return (
																	<span
																		className="lit-radio"
																		key={`ExcludeRading${value}`}
																	>
																		<input
																			checked={
																				value ===
																				this
																					.state
																					.excludeReading
																			}
																			className="lit-radio__field"
																			id={`difficulty${value}`}
																			key={`suspectsDysx${value}`}
																			name="ExcludeRading"
																			onChange={(
																				event
																			) => {
																				this.setState(
																					{
																						excludeReading: value,
																					}
																				);
																			}}
																			required
																			type="radio"
																		/>
																		<label
																			className="lit-radio__label"
																			htmlFor={`excludeReading`}
																		>
																			{
																				label
																			}
																		</label>
																	</span>
																);
															}
														)}
														<br></br>
													</div>
												</div>
											)}
											<br />
											<div className="lit-info__field-fifth">
												<p>
													{
														strings.testCandidateTakeTestWith
													}
												</p>
												<li>
													{strings.gotoDysmtateSite}
												</li>
												<li>
													{
														strings.testCommenceInstructions1
													}
												</li>
												<li>
													{
														strings.testCommenceInstructions2
													}
												</li>
												<li>
													{
														strings.testCommenceInstructions3
													}
												</li>
												<p>
													{
														strings.testCommenceInstructions4
													}
												</p>
												<p>
													{
														strings.testCommenceInstructions5
													}
												</p>
											</div>
											<br />

											<div className="lit-info__field-fifth">
												<label>
													<input
														type="checkbox"
														name="agreedToPrivacyPolicy"
														required
													/>{' '}
													{strings.iConsent}
													<br />
													{strings.formatString(
														strings.constTestChild,
														<a
															target="_blank"
															rel="noopener noreferrer"
															href="https://literate.no/personvernerklaering/"
														>
															<u>
																{
																	strings.privacyPolicy
																}
															</u>
														</a>
													)}
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="lit-btn-submit-from">
									<button
										className={
											isFormValid
												? 'lit-btn lit-btn bg-primary-glow'
												: 'lit-btn lit-btn bg-primary-dark'
										}
										onClick={() => {}}
									>
										{strings.sendtest}
									</button>
								</div>
								<br />
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CandidateInfo;

import React from 'react';
/* import './styles.scss'; */
import classNames from 'classnames';
import strings from '../../../utils/strings';
import 'react-datepicker/dist/react-datepicker.css';
import getTestAdminSummary from '../../../api/getTestAdminSummary';

import DatePicker from 'react-datepicker';
import moment from 'moment';
moment.locale('nb');
const needsApproval = {
	id: 0,
	label: 'NEEDS_APPROVAL',
	text: strings.needsapproval,
};
const notRegistered = {
	id: 1,
	label: 'AWAITING_CONFIRMATION',
	text: strings.notcompletedregistration,
};
const trialPeriod = {id: 2, label: 'TRIAL', text: strings.trialuser};
const notActive = {id: 3, label: 'INACTIVE', text: strings.inactive};
const basicSub = {id: 4, label: 'BASIC', text: strings.basic};
const dysmateC = {id: 5, label: 'Dysmate-Child', text: 'Dysmate-child'};
const dysmateY = {id: 6, label: 'Dysmate-Youth', text: 'Dysmate-youth'};
const dysmateA = {id: 7, label: 'Dysmate-Adult', text: 'Dysmate-adult'};
const fullSub = {id: 8, label: 'Full', text: 'Full'};
const dysmateYf = {
	id: 9,
	label: 'Dysmate-Y-Followup',
	text: 'Dysmate-Y-followup',
};
const dysmateAf = {
	id: 10,
	label: 'Dysmate-A-Followup',
	text: 'Dysmate-A-followup',
};
const dysmateNc = {id: 11, label: 'Norming-Child', text: 'Norming-child'};
const dysmateNy = {id: 12, label: 'Norming-Youth', text: 'Norming-youth'};
const dysmateNa = {id: 13, label: 'Norming-Adult', text: 'Norming-adult'};
const subscriptionTypes = [
	needsApproval,
	notRegistered,
	trialPeriod,
	notActive,
	basicSub,
	dysmateC,
	dysmateY,
	dysmateA,
	fullSub,
	dysmateYf,
	dysmateAf,
	dysmateNc,
	dysmateNy,
	dysmateNa,
];
const experimentalArray = [
	'NEEDS_APPROVAL',
	'AWAITING_CONFIRMATION',
	'TRIAL',
	'INACTIVE',
	'BASIC',
	'Dysmate-Child',
	'Dysmate-Youth',
	'Dysmate-Adult',
	'Full',
	'Dysmate-Y-Followup',
	'Dysmate-A-Followup',
	'Norming-Child',
	'Norming-Youth',
	'Norming-Adult',
];
class TestAdminSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate_from: moment()
				.utc()

				.startOf('month')
				.toDate(),
			selectedDate_to: moment().utc().toDate(),
			selectedCountry: 'default',
			summaryData: [],
		};
	}
	componentDidMount() {
		this.getSummary();
	}

	getSummary = () => {
		if (
			!this.state.selectedDate_from ||
			!this.state.selectedDate_to ||
			!this.state.selectedCountry
		) {
			return;
		}

		const initialDate = moment(this.state.selectedDate_from)
			.utc()
			.format('YYYY-MM-DD HH:mm:ss');
		const finalDate = moment(this.state.selectedDate_to)
			.utc()
			.format('YYYY-MM-DD HH:mm:ss');
		getTestAdminSummary(
			initialDate,
			finalDate,
			this.state.selectedCountry
		).then((results) => {
			return new Promise((resolve) => {
				if (Boolean(results.length)) {
					this.setState(
						{
							summaryData: results,
						},
						resolve
					);
				} else {
					this.setState(
						{
							summaryData: [],
						},
						resolve
					);
				}
			});
		});
	};
	renderTableHeader() {
		return (
			<div className="lit-myClass-header_grid">
				<div className="lit-myClass-header-wrapper">
					<div className="myClass_table-header">{strings.users}</div>
					<div className="myClass_table-header">
						{strings.totalnew}
					</div>
					<div className="myClass_table-header">
						{strings.subscription}
					</div>
					<div className="myClass_averagescore_table-header">
						{strings.totalnew}
					</div>
				</div>
			</div>
		);
	}
	renderDynamicView(data, index) {
		return (
			<div>
				<div className="lit-summary_grid" id={index}>
					<div className="lit-summary_title_table-cell">
						{this.state.summaryData[index] &&
							this.state.summaryData[index].firstName &&
							`${this.state.summaryData[index]['firstName']}, ${this.state.summaryData[index]['lastName']}`}
					</div>
					<div className="lit_summary_table-cell">
						{index === 0
							? `${this.state.summaryData.length}/${this.props.totalAdmins}`
							: ''}
					</div>

					<div className="myClass-subscription_table-cell">
						{subscriptionTypes[index]
							? subscriptionTypes[index].text
							: ''}
					</div>

					<div className="myClass_table-new-subs-cell">
						{subscriptionTypes[index]
							? this.determineSubscriptionTypes(
									subscriptionTypes[index].label
							  )
							: ''}
					</div>
				</div>
			</div>
		);
	}
	determineSubscriptionTypes(subscriptionType) {
		if (!subscriptionType) return '';
		let newAccumulator = 0;
		let oldAccumulator = 0;
		const allAdmins = this.props.testAdmins;
		let newFilteredSubscriptions = [];
		let allFilteredSubscriptions = [];

		if (
			subscriptionType === 'NEEDS_APPROVAL' ||
			subscriptionType === 'AWAITING_CONFIRMATION' ||
			subscriptionType === 'TRIAL' ||
			subscriptionType === 'INACTIVE' ||
			subscriptionType === 'BASIC'
		) {
			newFilteredSubscriptions = this.state.summaryData.filter((data) => {
				if (data.userStage === subscriptionType) {
					return data;
				}
				return;
			});
			allFilteredSubscriptions = allAdmins.filter((admin) => {
				if (admin.userStage === subscriptionType) {
					return admin;
				}
				return;
			});

			return `${newFilteredSubscriptions.length}/${allFilteredSubscriptions.length}`;
		} else {
			if (subscriptionType === 'Dysmate-Child') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['child'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['child'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Dysmate-Youth') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['youth'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['youth'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Dysmate-Adult') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['adult'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['adult'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			} //@
			if (subscriptionType === 'Full') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (
								allLanguages[j]['child'] === 1 &&
								allLanguages[j]['youth'] === 1 &&
								allLanguages[j]['adult'] === 1 &&
								allLanguages[j]['youth_followup'] === 1 &&
								allLanguages[j]['adult_followup'] === 1
							) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (
								allLanguages[j]['child'] === 1 &&
								allLanguages[j]['youth'] === 1 &&
								allLanguages[j]['adult'] === 1 &&
								allLanguages[j]['youth_followup'] === 1 &&
								allLanguages[j]['adult_followup'] === 1
							) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}

			if (subscriptionType === 'Dysmate-Y-Followup') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['youth_followup'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['youth_followup'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Dysmate-A-Followup') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'PRODUKSJON' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'PRODUKSJON' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['adult_followup'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['adult_followup'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Norming-Child') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'NORMING' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'NORMING' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_child'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_child'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Norming-Youth') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'NORMING' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'NORMING' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_youth'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_youth'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
			if (subscriptionType === 'Norming-Adult') {
				newFilteredSubscriptions = this.state.summaryData.filter(
					(data) => {
						if (
							data.userStage === 'NORMING' ||
							data.userStage === 'PROD_NORMING'
						) {
							return data;
						}
						return;
					}
				);

				allFilteredSubscriptions = allAdmins.filter((admin) => {
					if (
						admin.userStage === 'NORMING' ||
						admin.userStage === 'PROD_NORMING'
					) {
						return admin;
					}
					return;
				});

				if (allFilteredSubscriptions.length) {
					for (let i = 0; i < allFilteredSubscriptions.length; i++) {
						const allLanguages =
							allFilteredSubscriptions[i].subscription.languages;
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_adult'] === 1) {
								oldAccumulator = oldAccumulator + 1;
							}
						}
					}
				}
				if (newFilteredSubscriptions.length) {
					for (let i = 0; i < newFilteredSubscriptions.length; i++) {
						const allLanguages =
							newFilteredSubscriptions[i]['languages'];
						for (let j = 0; j < allLanguages.length; j++) {
							if (allLanguages[j]['norming_adult'] === 1) {
								newAccumulator = newAccumulator + 1;
							}
						}
					}
				}

				return `${newAccumulator}/${oldAccumulator}`;
			}
		}
	}
	render() {
		return (
			<div className="lit-tests-view__header">
				<div className="lit-new-test__wrapper">
					<div className="lit-date_filter">
						<div>
							<div className="lit-input_dateLabel">
								<label> {strings.from}</label>
							</div>
							<div className="customDatePickerWidth">
								<DatePicker
									className="lit-input__field"
									minDate={moment()
										.subtract(5, 'years')
										.toDate()}
									maxDate={moment()
										.subtract(0, 'years')
										.toDate()}
									timeFormat="HH:mm:ss"
									dropdownMode="select"
									dateFormat="dd-MM-yyyy"
									name="from"
									onChange={(value) => {
										this.setState(
											{
												selectedDate_from: value,
											},
											() => {
												this.getSummary();
											}
										);
									}}
									placeholderText={strings.clickToSelectDate}
									required
									selected={this.state.selectedDate_from}
									adjustDateOnChange
									showYearDropdown
								/>
							</div>
						</div>
						<div>
							<div className="lit-input_dateLabel">
								<label> {strings.to}</label>
							</div>
							<div className="customDatePickerWidth">
								<DatePicker
									className="lit-input__field"
									minDate={moment()
										.subtract(5, 'years')
										.toDate()}
									maxDate={moment()
										.subtract(0, 'years')
										.toDate()}
									timeFormat="HH:mm:ss"
									dropdownMode="select"
									dateFormat="dd-MM-yyyy"
									name="to"
									onChange={(value) => {
										this.setState(
											{
												selectedDate_to: value,
											},
											() => {
												this.getSummary();
											}
										);
									}}
									required
									selected={this.state.selectedDate_to}
									showYearDropdown
									adjustDateOnChange
								/>
							</div>
						</div>
						<div>
							<div className="lit-input_dateLabel">
								<label> {strings.country}</label>
							</div>
							<div className="lit-conutry_picker">
								<select
									className="lit-input__field"
									defaultValue={this.state.selectedCountry}
									onChange={(e) => {
										if (e.target.value !== 'Alle')
											this.setState(
												{
													selectedCountry:
														e.target.value,
												},
												() => {
													this.getSummary();
												}
											);
									}}
								>
									<option key={0} value={'default'}>
										{strings.allcountries}
									</option>
									<option
										key={1}
										value={strings.norway}
										disabled
									>
										{strings.norway}
									</option>
									<option
										key={2}
										value={strings.sweden}
										disabled
									>
										{strings.sweden}
									</option>
									<option
										key={3}
										value={strings.germany}
										disabled
									>
										{strings.germany}
									</option>
									<option
										key={4}
										value={strings.england}
										disabled
									>
										{strings.england}
									</option>
									<option
										key={5}
										value={strings.nederland}
										disabled
									>
										{strings.nederland}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div className="lit-summary-table_wrapper">
						{Boolean(this.state.summaryData.length) &&
							this.state.summaryData.length <
								experimentalArray.length && (
								<div className="lit_myClass_table-wrapper">
									{this.renderTableHeader()}
									{subscriptionTypes.map((data, index) => {
										return (
											<div key={index}>
												{this.renderDynamicView(
													data,
													index
												)}
											</div>
										);
									})}
								</div>
							)}
						{Boolean(this.state.summaryData.length) &&
							this.state.summaryData.length >
								experimentalArray.length && (
								<div className="lit_myClass_table-wrapper">
									{this.renderTableHeader()}
									{this.state.summaryData.map(
										(data, index) => {
											return (
												<div key={index}>
													{this.renderDynamicView(
														data,
														index
													)}
												</div>
											);
										}
									)}
								</div>
							)}
						{!Boolean(this.state.summaryData.length) && (
							<div className="lit_myClass_table-wrapper">
								<p>{strings.nonewusersduringtimeperiod}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default TestAdminSummary;

import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function distributeKeyAndStoreInfo(
	isNorming,
	key,
	info,
	excludeReading,
	pinCode
) {
	return fetch(
		`${ENDPOINT_URL}/test/distributekeyinfo`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				isNorming,
				key,
				info,
				excludeReading,
				pinCode,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			console.log(err);
			return Promise.reject(apiErrorHandling(err));
		});
}

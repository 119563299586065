import {ENDPOINT_URL} from '../constants';
const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function resetPassword(token, psw) {
	return fetch(`${ENDPOINT_URL}/resetcustompassword`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body: JSON.stringify({token, psw}),
	}).then((response) => {
		return response;
	});
}

import React from 'react';
import strings from '../../../utils/strings';
import './styles.scss';
import moment from 'moment';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import roundWithDecimals from '../../../utils/roundWithDecimals';
import updateLogEvent from '../../../api/updateLogEvent';
import InformationPane from '../../../components/InformationPane';
import getTestsAverageScore from '../../../api/getTestsAverageScore';

import {
	COMPLETED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
} from '../../../constants';

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
let sortOrder = true;
class MyClasses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMainChecked: false,
			singleChecks: false,
			selectedClass: '',
			individualCandidates: [],
			allSelected: [],
			customTableClass: 'lit-myClass_grid',
			allClassData: this.props.classData,
			audioData: [],
			timeOut: false,
			silence: true,
			renderPaneAlert: false,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	componentWillReceiveProps() {
		this.setState({allClassData: this.props.classData});
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results').then(() => {});
	}

	getAndSetTestCandidatesAverageScore(testData, classData) {
		const {currentUser} = this.props;
		const id = currentUser.id;
		return getTestsAverageScore(testData).then((averageZScore) => {
			return new Promise((resolve) => {
				classData.averageZscore = roundWithDecimals(averageZScore, 4);
				this.setState(
					{
						averageZScore,
					},
					resolve
				);
			});
		});
	}

	sortTableBy(identifier) {
		const {allClassData} = this.state;
		sortOrder = !sortOrder;
		switch (identifier) {
			case 'name':
				allClassData.sort(this.compareByName);
				break;
			case 'candidates':
				allClassData.sort(this.compareBytests);
				break;
		}
		this.setState({allClassData});
	}

	compareByName(a, b) {
		if (!sortOrder) {
			if (a.className.toLowerCase() < b.className.toLowerCase()) {
				return 1;
			}
			if (a.className.toLowerCase() > b.className.toLowerCase()) {
				return -1;
			}
			return 0;
		} else {
			if (a.className.toLowerCase() > b.className.toLowerCase()) {
				return 1;
			}
			if (a.className.toLowerCase() < b.className.toLowerCase()) {
				return -1;
			}
			return 0;
		}
	}
	compareBytests(a, b) {
		if (!sortOrder) {
			if (a.strength < b.strength) {
				return 1;
			}
			if (a.strength > b.strength) {
				return -1;
			}
			return 0;
		} else {
			if (a.strength > b.strength) {
				return 1;
			}
			if (a.strength < b.strength) {
				return -1;
			}
			return 0;
		}
	}
	renderCustomTableHeader() {
		return (
			<div className="lit-myClass-main-container">
				<div className="lit-myClass-header_grid">
					<div className="lit-myClass-header-wrapper">
						<div className="myClass_checkBox-cell">
							<input
								type="checkbox"
								className="user_checkbox1"
								id="main_checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										const checkedClassData = this.state.allClassData.map(
											(data) => {
												data.checked = true;
												return data;
											}
										);

										this.setState({
											isMainChecked: true,
											allClassData: checkedClassData,
										});
									} else {
										const checkedClassData = this.state.allClassData.map(
											(data) => {
												data.checked = false;
												return data;
											}
										);

										this.setState({
											isMainChecked: false,
											allClassData: checkedClassData,
										});
									}
								}}
							/>
						</div>

						<div className="myClass_table-title-header">
							<span
								className="className-title"
								onClick={() => {
									this.sortTableBy('name');
								}}
							>
								{strings.classname}
							</span>
						</div>
						<div className="myClass_table-title-header">
							<span
								className="total-candidates"
								onClick={() => {
									this.sortTableBy('candidates');
								}}
							>
								{strings.numberofcandidates}
							</span>
						</div>
						<div
							className="myClass_table-title-header"
							onClick={() => {
								/* this.sortTableBy('tests'); */
							}}
						>
							<span>{strings.testscompleted}</span>
						</div>
						<div className="myClass_averagescore_table-header">
							<span>{strings.averagezscore}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
	renderCustomTable(classData) {
		return (
			<div>
				<div
					className={
						classData.active
							? 'lit-myClass_grid-active'
							: 'lit-myClass_grid'
					}
					id={classData.className}
					onClick={() => {
						if (!classData.active) {
							classData.active = true;
							this.setState({
								selectedClass: classData.className,
							});
						} else {
							classData.active = false;
							this.setState({
								selectedClass: classData.className,
							});
						}
					}}
				>
					{!this.state.isMainChecked && !this.state.singleChecks && (
						<div
							className="myClass_checkBox-cell"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<input
								type="checkbox"
								key={classData.classId}
								className="lit-myClasses_checkbox"
								/* defaultChecked={classData.checked ? true : false} */
								id={3}
								onChange={(e) => {
									if (e.target.checked) {
										classData.checked = true;
										this.setState({
											allClassData: this.props.classData,
										});
									} else {
										classData.checked = false;
										this.setState({
											allClassData: this.props.classData,
										});
									}
								}}
							/>
						</div>
					)}
					{this.state.isMainChecked && (
						<div
							className="myClass_checkBox-cell"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<input
								type="checkbox"
								className="lit-myClasses_checkbox"
								defaultChecked
								id={4}
								onChange={(e) => {
									if (e.target.checked) {
										classData.checked = true;
										this.setState({
											allClassData: this.props.classData,
										});
									} else {
										classData.checked = false;
										this.setState({
											allClassData: this.props.classData,
										});
									}
								}}
							/>
						</div>
					)}
					<div className="myClass_name_table-cell">
						{classData.className}
					</div>
					<div className="myClass_strength_table-cell">
						{classData.strength}
					</div>
					<div className="myClass_strength_table-cell">
						{this.calculateCompletedTests(
							classData.classInfo,
							this.props.batchedFilteredTestResults
						)}
					</div>
					<div className="myClass_meanscore_table-cell">
						<span
							className="lit-average-zs"
							onClick={(event) => {
								event.stopPropagation();
								const someData = this.getFilteredTestUsers(
									classData.classInfo,
									this.props.batchedFilteredTestResults
								);
								const testData = someData.map((data) => {
									return {
										id: data.id,
										type: data.type,
										key: data.key,
									};
								});
								this.getAndSetTestCandidatesAverageScore(
									testData,
									classData
								);
							}}
						>
							{classData.averageZscore}
						</span>
					</div>
					<div className="myClass_btn-cell">
						<div className="lit-list__text">
							<button className="lit-btn lit-btn--small bg-primary6">
								{classData.active === true ? (
									<span>{'▲'}</span>
								) : (
									<span>{'▼'}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{classData.active === true &&
					this.renderSubTable(
						this.getFilteredTestUsers(
							classData.classInfo,
							this.props.batchedFilteredTestResults
						)
					)}
			</div>
		);
	}
	calculateCompletedTests(classInfo, allTestUsers) {
		const allUsers = classInfo.map((data) => {
			return data.candidateId;
		});
		const tempObj = [];
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						allUsers.includes(testsResults[j].testUser.testUserId)
					) {
						tempObj.push(testsResults[j]);
					}
				}
			}
		}
		const count = tempObj.filter(
			(singleInstance) => singleInstance.completedAt
		).length;

		return `${count}/${tempObj.length}`;
	}
	renderSubTable(singleCandidate) {
		if (singleCandidate) {
			return (
				<div className="lit-myClass_individual-test">
					<table className="lit-tests">
						<thead>
							<tr>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td className="lit-test__header">
									{strings.testkey}
								</td>
								<td className="lit-test__header">
									{strings.type}
								</td>
								<td className="lit-test__header">
									{strings.recipient}
								</td>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td colSpan="2" className="lit-test__header">
									{strings.actions}
								</td>
							</tr>
						</thead>
						<tbody>
							{singleCandidate.map((candidate) => {
								const {
									id,
									key,
									testUser,
									status,
									type,
								} = candidate;
								const firstName = testUser.firstName;
								const lastName = testUser.lastName;
								const fullName = `${firstName} ${lastName}`;
								return (
									<tr className="lit-test" key={key}>
										<td className={'lit-test__col'}>
											<div className={'lit-test-status2'}>
												<div
													className={classNames({
														'lit-test-status__indicator': true,
														[status.id]: true,
													})}
												/>
												<p className="lit-test-status__label">
													{status.label}
												</p>
											</div>
										</td>
										<td className="lit-test__col">{key}</td>
										<td className="lit-test__col">
											{TEST_TYPE_NAMES[type]}
										</td>
										<td className="lit-test__col">
											{fullName || (
												<b>{strings.notdistributed}</b>
											)}
											&nbsp;
										</td>
										{this.getTestDateInfo(
											candidate.completedAt,
											candidate.usedAt,
											candidate.distributedAt
										)}
										<td className="lit-test__col">
											<div className="lit-test__field">
												{this.renderButton(
													type,
													id,
													status,
													key
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}
		return (
			<div>
				<p>Dene Kandidater har ingen Gjennomførte tester</p>
			</div>
		);
	}
	renderButton(type, id, status, key) {
		const completed = status === COMPLETED;
		const started = status === STARTED;
		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: `/single-test/${id}`;

		if (completed || started) {
			return (
				<div>
					<Link
						className="lit-btn lit-btn--small bg-secondary"
						to={destination}
						onClick={() => this.handleClick(id, key)}
					>
						{strings.seeresults}
					</Link>
				</div>
			);
		}
		return null;
	}
	getTestDateInfo(completedAt, usedAt, distributedAt) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.completed}
						<br />
						{moment(completedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (usedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.started}
						<br />
						{moment(usedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (distributedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.distributed}
						<br />
						{moment(distributedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		}

		return testDateInfo;
	}
	getFilteredTestUsers(classData, allTestUsers) {
		const allUsers = classData.map((data) => {
			return data.candidateId;
		});

		const tempObj = [];
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						allUsers.includes(testsResults[j].testUser.testUserId)
					) {
						tempObj.push(testsResults[j]);
					}
				}
			}
		}

		if (tempObj.length) {
			return tempObj;
		}
		return null;
	}
	render() {
		return (
			<div className="lit-tests-view__header">
				{/* <div className="audio" id="audio"></div> */}
				<div className="lit-new-test__wrapper">
					<div className="lit-btn_grid">
						{!this.state.allClassData.length && (
							<p>{strings.message_noclasses}</p>
						)}
						{Boolean(this.state.allClassData.length) && (
							<div className="lit-main-candidates-contianer">
								<div className="lit_myClass_table-wrapper">
									{this.renderCustomTableHeader()}

									{this.state.allClassData.map(
										(data, index) => {
											return (
												<div key={index}>
													{this.renderCustomTable(
														data
													)}
												</div>
											);
										}
									)}
								</div>
							</div>
						)}
					</div>
					{this.state.renderPaneAlert && (
						<InformationPane
							paneMessage={strings.chooseclass}
							onApproval={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							onCloseWindow={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							onDisproval={() => {
								this.setState({
									renderPaneAlert: false,
								});
							}}
							category={'information'}
						/>
					)}
					{Boolean(this.state.allClassData.length) && (
						<div className="lit-btn_grid">
							<button
								className="lit-btn bg-primary4"
								onClick={() => {
									const extractSelectedClasses = this.state.allClassData.filter(
										(data) => data.checked
									);
									if (!extractSelectedClasses.length) {
										this.setState({renderPaneAlert: true});
									} else {
										const extractedIds = extractSelectedClasses.map(
											(classIds) => {
												return classIds.classId;
											}
										);
										const subsId =
											extractSelectedClasses[0]
												.subscription;
										if (
											window.confirm(
												strings.formatString(
													strings.confirmationdeleteclass,
													`${
														extractedIds.length > 1
															? strings.multipleclasses
															: strings.theclass
													}`
												)
											)
										) {
											this.props.onDeletingCLass(
												extractedIds,
												subsId
											);
										}
									}
								}}
							>
								{strings.deleteclass}
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default MyClasses;

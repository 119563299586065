import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function getCorrespondingGroups() {
	return fetch(
		`${ENDPOINT_URL}/tests/groups`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}

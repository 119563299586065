import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';

import create from '../../assets/create-24px.svg';
import strings from '../../utils/strings';

const getStatusAndSubscription = (subscription) => {
	let status = 'active';
	let subType = 'trial';
	let prodStatus = false;
	let normStatus = false;
	const searchAbleFields = [
		'norming_youth',
		'norming_adult',
		'norming_child',
		'child',
		'youth',
		'adult',
		'child_followup',
		'youth_followup',
		'adult_followup',
	];
	const allLanguages = subscription.languages;
	if (subscription.trial) {
		subType = 'trial';
	} else if (!subscription.trial) {
		subType = 'basic';
	}

	for (let i = 0; i < allLanguages.length; i++) {
		for (const field of searchAbleFields) {
			if (allLanguages[i][field] === 1) {
				if (
					field === 'norming_child' ||
					field === 'norming_youth' ||
					field === 'norming_adult'
				) {
					normStatus = true;
				}
				if (
					field === 'child' ||
					field === 'youth' ||
					field === 'adult' ||
					field === 'youth_followup' ||
					field === 'adult_followup'
				) {
					prodStatus = true;
				}
			}
		}
	}
	if (prodStatus && normStatus) {
		subType = 'prodAndNorm';
	} else if (prodStatus && !normStatus) {
		subType = 'production';
	} else if (!prodStatus && normStatus) {
		subType = 'norming';
	}
	if (!subscription.active && !subscription.trial) subType = 'Inactive';

	if (subscription.approved === 0) {
		status = 'Waiting for approval';
	} else if (subscription.registered === 0) {
		status = 'Not completed registration';
	} else if (subscription.active) {
		status = 'Active';
	} else if (
		subType === 'trial' &&
		moment(subscription.trialValidUntil).isAfter(moment.now())
	) {
		status = 'Active';
	} else if (
		subType === 'trial' &&
		moment(subscription.trialValidUntil).isBefore(moment.now())
	) {
		status = 'Expired';
	} else {
		status = 'Inactive';
	}

	return {status, type: subType};
};

const types = {
	adult: strings.adulttests,
	youth: strings.youthtests,
	child: strings.childtests,
	child_youth: strings.childrenAndYouthTests,
	child_adult: strings.childrenAndAdultTests,
	youth_adult: strings.youthAndAdultTests,
	child_youth_adult: strings.childYouthAndAdultTests,
	full: strings.full,
	production: strings.productiontests,
	Norming: strings.normingtests,
	prodAndNorm: strings.productionAndNorming,
	norming: strings.normingtests,
	Inactive: strings.inactive,
	trial: strings.trialperiod,
	active: strings.active,
	basic: strings.basic,
};

class TestAdminRow extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {testAdmin} = this.props;
		let isLoggedin = null;
		const loginHistory = testAdmin.loginTime;
		const currentTime = Math.floor(Date.now() / 1000 + 3600);
		if (loginHistory && loginHistory - currentTime > 0) {
			isLoggedin = true;
		} else {
			isLoggedin = false;
		}

		const {subscription} = testAdmin;
		const {
			email,
			firstName,
			organization,
			id,
			phone,
			lastName,
			isBlocked,
		} = testAdmin;
		const {status, type} = getStatusAndSubscription(testAdmin.subscription);
		return (
			<tr className="lit-list__item" key={id}>
				<td className="lit-list_check">
					{!this.props.isMainChecked && !this.props.isSingleChecked && (
						<input
							type="checkbox"
							className="user_checkbox2"
							id={id}
							onClick={(e) => {
								this.props.handleEmails(e, email);
								this.props.handleUsers(
									e,
									id,
									subscription,
									firstName,
									lastName,
									organization,
									email,
									phone
								);
							}}
						/>
					)}

					{this.props.isMainChecked && (
						<input
							type="checkbox"
							className="user_checkbox2"
							defaultChecked="true"
							onClick={(e) => {
								this.props.handleEmails(e, email);
								this.props.handleUsers(
									e,
									id,
									subscription.id,
									firstName,
									lastName,
									organization,
									email,
									phone
								);
							}}
						/>
					)}
				</td>
				<td className="lit-list__col small">
					<div className="lit-list__text">
						<a
							className="lit-link-button"
							href={`/subscription/${id}`}
						>
							{firstName && `${firstName} ${lastName}`}
						</a>
						{/* <span
							className={
								isLoggedin
									? 'lit-test-status__indicator active tiny_loggedIn '
									: 'lit-test-status__indicator active tiny_loggedOut'
							}
						></span> */}
					</div>
				</td>
				<td className="lit-list__col small">
					<div className="lit-list__text">{types[type]}</div>
				</td>
				<td className="lit-list__col small">
					<div className="lit-list__text">
						{isBlocked ? <b>Blocked</b> : status}
						<span
							className={
								isLoggedin
									? 'lit-test-status__indicator active tiny_loggedIn '
									: isBlocked
									? 'lit-test-status__indicator active tiny_blocked'
									: 'lit-test-status__indicator active tiny_loggedOut'
							}
						></span>
					</div>
				</td>
				<td className="lit-list__col">
					<div className="lit-list__text">{organization}</div>
				</td>
				<td className="lit-list__col">
					<div className="lit-list__text">{email}</div>
				</td>
				<td className="lit-list__col">
					<div className="lit-list__text">{phone}</div>
				</td>
				<td className="lit-list__col">
					<div className="lit-list__text">
						<button
							className="lit-link-button"
							onClick={this.props.editUser}
						>
							{<img src={create} alt="" />}
						</button>
					</div>
				</td>
			</tr>
		);
	}
}

TestAdminRow.propTypes = {
	editUser: PropTypes.func.isRequired,
	isMainChecked: PropTypes.bool,
	testAdmin: PropTypes.shape({}),
};

export default TestAdminRow;

import React from 'react';
import PropTypes from 'prop-types';

import {TEST_TYPES, TEST_TYPE_NAMES} from '../../../constants';
import strings from '../../../utils/strings';

const testsWithLongIntro = ['adult-screening', 'youth-screening'];
const testsWithInstruction = ['child-screening'];
class NewTestModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupId: '',
			hasConfirmedCost: false,
			newTestCount: 1,
			type: '',
			childSelfStarting: false,
			infoCandidateAdmin: true,
			availableTestTypes: [],
			isAddingGroup: false,
			isAddingLongIntro: false,
			availableLanguage: [],
			selectedLanguage: null,
		};
		this.setAvailableTypes = this.setAvailableTypes.bind(this);
	}
	componentDidMount() {
		const userLanguages = this.props.userLanguages[0];
		const tempObj = [];
		let initialTestType = null;
		const searchAbaleLanguageFields = [
			'norwegian',
			'swedish',
			'english',
			'dutch',
			'german',
		];
		const searchAbaleSubscriptionFields = [
			'child',
			'youth',
			'adult',
			'norming_child',
			'norming_youth',
			'norming_adult',
			'adult_followup',
			'child_followup',
			'youth_followup',
		];
		for (const field of searchAbaleLanguageFields) {
			if (userLanguages.hasOwnProperty(field)) {
				for (const subType of searchAbaleSubscriptionFields) {
					if (userLanguages[field][subType] === 1) {
						if (initialTestType === null) {
							initialTestType = userLanguages[field];
						}
						tempObj.push(userLanguages[field].language);
						break;
					}
				}
			}
		}

		this.setAvailableTypes(initialTestType);
		this.setState({
			availableLanguage: tempObj,
		});
	}

	setAvailableTypes(availableLanguages) {
		const availableTests = [];
		const searchAbaleSubscriptionFields = [
			'child',
			'adult',
			'youth',
			'norming_youth',
			'norming_child',
			'norming_adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
		];
		for (const field of searchAbaleSubscriptionFields) {
			if (availableLanguages[field] === 1) {
				if (field === 'child') {
					availableTests.push(
						TEST_TYPES.CHILD_SCREENING,
						TEST_TYPES.CHILD_EXPERIMENTAL
					);
				}
				if (field === 'child_followup') {
					availableTests.push(TEST_TYPES.CHILD_FOLLOWUP);
				}

				if (field === 'youth_followup' || field === 'norming_youth') {
					availableTests.push(TEST_TYPES.YOUTH_FOLLOWUP);
				}
				if (field === 'youth' || field === 'norming_youth') {
					availableTests.push(TEST_TYPES.YOUTH_SCREENING);
				}

				if (field === 'adult') {
					availableTests.push(TEST_TYPES.ADULT_SCREENING);
				}
				if (field === 'adult_followup') {
					availableTests.push(TEST_TYPES.ADULT_FOLLOWUP);
				}
			}
		}
		this.setState({
			availableTestTypes: availableTests,
		});
	}

	render() {
		const {onSave} = this.props;
		const UsersGroups = this.props.userGroups;
		const userLanguages = this.props.userLanguages[0];
		const groups = UsersGroups.map((userGroup) => {
			return userGroup.name;
		});

		const {
			hasConfirmedCost,
			newTestCount,
			availableTestTypes,
			isAddingGroup,
		} = this.state;

		const cancelButton = (
			<button className="lit-btn bg-negative2" onClick={() => onSave()}>
				{strings.cancel}
			</button>
		);

		return (
			<div
				className="lit-modal"
				onClick={() => {
					onSave();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<h3>{strings.createnewtest}</h3>
					{!hasConfirmedCost && (
						<div>
							<h4>{strings.confirmationofcost}</h4>
							<p>
								{strings.message_costsforeachtestthatis_started}
							</p>
							<button
								className="lit-btn bg-primary"
								onClick={() => {
									if (
										Boolean(groups.length) &&
										!isAddingGroup
									) {
										this.setState({
											groupId: null,
											hasConfirmedCost: true,
											selectedLanguage: this.state
												.availableLanguage[0],
											type: this.state
												.availableTestTypes[0],
										});
									} else if (
										!groups.length &&
										!isAddingGroup
									) {
										this.setState({
											groupId: null,
											hasConfirmedCost: true,
											selectedLanguage: this.state
												.availableLanguage[0],
											type: this.state
												.availableTestTypes[0],
										});
									} else {
										this.setState({
											hasConfirmedCost: true,
										});
									}
								}}
							>
								{strings.confirm}
							</button>
							{cancelButton}
						</div>
					)}
					{hasConfirmedCost && (
						<form
							className="lit-form register-form"
							onSubmit={(event) => {
								event.preventDefault();

								if (groups.length) {
									onSave(
										this.state.type,
										this.state.newTestCount,
										this.state.groupId,
										this.state.selectedLanguage,
										this.state.isAddingLongIntro,
										this.state.childSelfStarting
									);
								} else {
									onSave(
										this.state.type,
										this.state.newTestCount,
										null,
										this.state.selectedLanguage,
										this.state.isAddingLongIntro,
										this.state.childSelfStarting
									);
								}
							}}
						>
							<div className="lit-input">
								{this.state.availableLanguage.length > 1 && (
									<div className="lit-input inline">
										<label className="lit-input__groupname-label">
											{strings.chooselanguage}
										</label>
										<select
											className="lit-input__field"
											defaultValue={
												this.state.availableLanguage[0]
											}
											name="language"
											onChange={(event) => {
												this.setState({
													selectedLanguage:
														event.target.value,
												});
												this.setAvailableTypes(
													userLanguages[
														event.target.value
													]
												);
											}}
										>
											{this.state.availableLanguage.map(
												(language) => (
													<option
														key={language}
														value={language}
													>
														{language}
													</option>
												)
											)}
										</select>
									</div>
								)}

								<div className="lit-input inline">
									<label className="lit-input__label">
										{strings.test_type}
									</label>
									<select
										className="lit-input__field"
										defaultValue={this.state.type}
										name="type"
										onChange={(event) => {
											this.setState({
												type: event.target.value,
											});
										}}
									>
										{Object.entries(TEST_TYPES)
											.filter(([key, value]) =>
												availableTestTypes.includes(
													value
												)
											)
											.map(([key, value]) => (
												<option key={key} value={value}>
													{TEST_TYPE_NAMES[value]}
												</option>
											))}
									</select>
								</div>
								<div className="lit-input inline">
									<label className="lit-input__label">
										{strings.numberoftests}
									</label>
									<input
										className="lit-input__field"
										max="30"
										min="0"
										name="newTestCount"
										onChange={(event) => {
											const newTestCount =
												event.target.value;
											this.setState({
												newTestCount,
											});
										}}
										placeholder="5"
										required
										type="number"
										value={newTestCount}
									/>
								</div>

								{Boolean(groups.length) && (
									<div className="lit-input inline">
										<label className="lit-input__label">
											{strings.choosegroup}
										</label>
										<input
											type="checkbox"
											className="lit-input_checkbox"
											name="velg gruppet"
											onChange={(e) => {
												if (e.target.checked) {
													this.setState({
														isAddingGroup: true,
														groupId:
															UsersGroups[0].id,
													});
												} else {
													this.setState({
														groupId: 0,
														isAddingGroup: false,
													});
												}
											}}
										/>
									</div>
								)}

								<div>
									{isAddingGroup && (
										<div className="lit-input inline">
											<label className="lit-input__groupname-label">
												{'Gruppenavn'}
											</label>
											<select
												className="lit-input__field"
												defaultValue={groups[0]}
												name="group"
												onChange={(event) => {
													var chosenGroup = UsersGroups.filter(
														(userGroup) =>
															userGroup.name ===
															event.target.value
													);

													this.setState({
														groupId:
															chosenGroup[0].id,
													});
												}}
											>
												{groups.map((group) => (
													<option
														key={group}
														value={group}
													>
														{group}
													</option>
												))}
											</select>
										</div>
									)}
								</div>
								<br></br>
								{testsWithLongIntro.includes(
									this.state.type
								) && (
									<div>
										<div className="lit-input inline">
											<label className="lit-input__label">
												{strings.includeLongIntro}
											</label>
											<input
												type="checkbox"
												className="lit-input_checkbox"
												name="velg anamnese"
												onChange={(e) => {
													if (e.target.checked) {
														this.setState({
															isAddingLongIntro: true,
														});
													} else {
														this.setState({
															isAddingLongIntro: false,
														});
													}
												}}
											/>
										</div>
										<div>
											<p>
												({strings.anamneseexplaination})
											</p>
										</div>
									</div>
								)}

								{testsWithInstruction.includes(
									this.state.type
								) && (
									<div>
										<div className="lit-input-marginal">
											<div className="lit-input inline">
												<label className="lit-input__label">
													<b>
														{
															strings.fillSelfCandidateInfo
														}
													</b>
												</label>
												<input
													checked={
														this.state
															.infoCandidateAdmin
													}
													type="radio"
													className="lit-input_checkbox"
													name="velg anamnese"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState({
																infoCandidateAdmin: true,
																childSelfStarting: false,
															});
														}
													}}
												/>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformation1
													}
												</p>
											</div>
											<div className="lit-input inline">
												<label className="lit-input__label">
													<b>
														{
															strings.letCandidateFillInfo
														}
													</b>
												</label>
												<input
													checked={
														this.state
															.childSelfStarting
													}
													type="radio"
													className="lit-input_checkbox"
													name="velg anamnese"
													onChange={(e) => {
														if (e.target.checked) {
															this.setState({
																childSelfStarting: true,
																infoCandidateAdmin: false,
															});
														}
													}}
												/>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformationSelf1
													}
												</p>
											</div>
											<div>
												<p>
													{
														strings.fillKandidateInformationSelf2
													}
												</p>
											</div>
										</div>
										<div>
											<p>{strings.candidateInfoCommon}</p>
											<p>
												{strings.candidateInfoReminder}
											</p>
										</div>
									</div>
								)}
								{this.state.isAddingLongIntro &&
									this.state.type === 'youth-screening' && (
										<div className="lit-info__field-fifth">
											<label>
												<input
													type="checkbox"
													name="agreedToPrivacyPolicy"
													required
												/>{' '}
												{strings.iConsent}
												<br />
												{strings.formatString(
													strings.constTestYouth,
													<a
														target="_blank"
														rel="noopener noreferrer"
														href="https://literate.no/personvernerklaering/"
													>
														<u>
															{
																strings.privacyPolicy
															}
														</u>
													</a>
												)}
											</label>
										</div>
									)}
							</div>
							<div className="lit-input">
								<div>
									<button
										className="lit-btn bg-primary"
										disabled={
											newTestCount < 1 ||
											newTestCount > 30
										}
									>
										{`${
											newTestCount > 1
												? strings.create_tests
												: strings.createnewtest
										}`}
									</button>
									{cancelButton}
								</div>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}

NewTestModal.propTypes = {
	onSave: PropTypes.func.isRequired,
	userStage: PropTypes.string.isRequired,
};

export default NewTestModal;

export const apiErrorHandling = (response) => {
	return response.text().then((errorMessage) => {
		try {
			const errorJson = JSON.parse(errorMessage);
			if (errorJson.error === 'TokenExpiredError') {
				localStorage.removeItem('authtoken');
				window.location.href = `/login`;
			}
			return errorJson;
		} catch (e) {}

		return errorMessage;
	});
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './styles.scss';

class InformationPane extends Component {
	returnFallBackMessage() {
		const fallBackMessage = <div></div>;
	}
	returnConfimrationMessage(variableProps) {
		const confirmationMessage = (
			<div className="lit-options-container">
				<div className="lit-option-pane">
					<button
						className="__approve"
						onClick={() => {
							if (variableProps) {
								this.props.onApproval(variableProps);
							} else this.props.onApproval();
						}}
					>
						Ja
					</button>
				</div>

				<div className="lit-option-pane">
					<button
						className="__disprove"
						onClick={() => {
							this.props.onCloseWindow();
						}}
					>
						Nei{' '}
					</button>
				</div>
			</div>
		);

		return confirmationMessage;
	}

	returnAlertMessage(pangeMessage) {
		const alertMessage = (
			<div className="lit-options-container">
				<div className="lit-option-pane">
					<button
						className="__approve"
						onClick={() => {
							this.props.onCloseWindow();
						}}
					>
						Ok{' '}
					</button>
				</div>
			</div>
		);

		return alertMessage;
	}
	render() {
		const message = this.props.paneMessage;
		const variableProps = this.props.variables ? this.props.variables : '';
		return (
			<div
				className={'lit-modal-informationPane'}
				onClick={() => {
					this.props.onCloseWindow();
				}}
			>
				<div
					className="lit-modal-pane"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit-option-pane-container">
						<h2>{'Dysmate sier:'}</h2>

						<p>{message}</p>
						{this.props.category === 'confirmation' && (
							<div>
								{this.returnConfimrationMessage(variableProps)}
							</div>
						)}
						{this.props.category === 'information' && (
							<div>{this.returnAlertMessage(variableProps)}</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default InformationPane;

import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';

const relevantNames = {
	noBackOffice: 'Norsk',
	deBackOffice: 'Tysk',
	seBackOffice: 'Svensk',
	nnBackOffice: 'Nynorsk',
	enBackOffice: 'Engelsk',
	deFileFollowup: 'Tysk',
	noFileFollowup: 'Norsk',
	seFileFollowup: 'Svensk',
	nnFileFollowup: 'Nynorsk',
	enFileFollowup: 'Engelsk',
	noScreening: 'Norsk',
	deScreening: 'Tysk',
	seScreening: 'Svensk',
	nnScreening: 'Nynorsk',
	enScreening: 'Engelsk',
};

const totalLanguages = ['Norsk', 'Tysk', 'Svensk', 'Nynorsk', 'Engelsk'];
let forLoopSuccess = false;
let folrLoopHeaderCount = 0;
let folrLoopBodyCount = 0;
class StringsCatalog extends React.Component {
	constructor(props) {
		super(props);
	}

	reOrderTableHeader(filesForComparison) {
		if (folrLoopHeaderCount === 5) {
			folrLoopBodyCount = 0;
		}

		switch (folrLoopHeaderCount) {
			case 0:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'no') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 1:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'de') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 2:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'se') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 3:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'nn') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 4:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'en') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
		}
	}

	returnLanguageTickAndCross(includedIn, index, mainIndex, stringName) {
		forLoopSuccess = false;
		if (folrLoopBodyCount === 5) {
			folrLoopBodyCount = 0;
		}

		for (let i = 0; i < includedIn.length; i++) {
			if (includedIn[i].includes(totalLanguages[folrLoopBodyCount])) {
				folrLoopBodyCount = folrLoopBodyCount + 1;
				forLoopSuccess = true;
				break;
			}
		}
		if (forLoopSuccess) {
			return (
				<td
					className="lit-list__col"
					key={`main${mainIndex}index${index}`}
				>
					<div className="tickmark-sl large">L</div>
				</td>
			);
		} else {
			folrLoopBodyCount = folrLoopBodyCount + 1;
			return (
				<td
					className="lit-list__col"
					key={`main${mainIndex}index${index}`}
				>
					<div className="crossmark-sl norming large">X</div>
				</td>
			);
		}
	}
	render() {
		const {
			allStringsCatalog,
			filesForComparison,
			currentCodeBase,
		} = this.props;
		return (
			<div className="lit-modal-stringsCatalog">
				<div
					className="lit-modal__ls-continent"
					onClick={(event) => {
						event.stopPropagation();
					}}
				></div>
				<div className="lit-string-catalog">
					<div className="lit-string-catalog-container">
						<div className="lit-strings-info-header">
							<h3>{currentCodeBase}</h3>
						</div>
						<div className="lit-close-icon">
							<span
								className="lit-close-sl-icon"
								onClick={() => {
									folrLoopHeaderCount = 0;
									this.props.closeWindow();
								}}
							>
								X
							</span>
						</div>
						<div className="lit-strings-theader-wrapper">
							<table className="lit-list-strings">
								<thead className="lit-strings-theader">
									<tr>
										<th>{'Key'}</th>
										{filesForComparison.map(
											(file, index) => {
												return this.reOrderTableHeader(
													filesForComparison
												);
											}
										)}
									</tr>
								</thead>

								<tbody className="lit-strings-tbody">
									{allStringsCatalog.map(
										(signleString, mainIndex) => {
											const {
												stringName,
												includedIn,
											} = signleString;

											return (
												<tr
													className="lit-event-log__entry"
													key={mainIndex}
												>
													<td>{stringName}</td>
													{includedIn.map(
														(isIn, index) => {
															const valueReturned = this.returnLanguageTickAndCross(
																includedIn,
																index,
																mainIndex,
																stringName
															);
															return valueReturned;
														}
													)}
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StringsCatalog;

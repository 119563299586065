import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {useSelector, useDispatch} from 'react-redux';

import './styles.scss';
import Logo from '../../assets/dysmate-logo-hvit-small.png';

import {BANK_ID, BANK_ID_MOBILE, FEIDE} from '../../constants';

import {initAuthAction, resetAuthAction} from '../../actions//index';
import strings from '../../utils/strings';
import EmailPasswordLogin from '../../pages//EmailPasswordLogin';
import {LanguageContext} from '../../App';

const parsedSearch = queryString.parse(window.location.search);

const testKey = parsedSearch.key;
const Login = () => {
	useEffect(() => {
		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		return function cleanup() {
			window.removeEventListener('message', receiveMessage);
		};
	});

	const language = useContext(LanguageContext);
	const dispatch = useDispatch();
	const initAuth = (...args) => dispatch(initAuthAction(...args));
	const resetAuth = () => dispatch(resetAuthAction);

	const {error, iframeUrl, isInit, authMethod} = useSelector(
		(state) => state.auth
	);

	const getLoginMethods = () => {
		switch (language) {
			case 'no': {
				return [BANK_ID, BANK_ID_MOBILE, FEIDE, 'Password'];
			}
			case 'en':
			default: {
				return ['Password'];
			}
		}
	};

	const loginMethods = getLoginMethods();

	const loginClass = 'lit-login';

	const methodsMap = {
		[BANK_ID]: {
			label: strings.bankid,
			primary: !Boolean(testKey),
		},
		[BANK_ID_MOBILE]: {
			label: strings.bankidonmobile,
		},
		[FEIDE]: {
			label: strings.feide,
			recommended: Boolean(testKey),
		},
		Password: {
			label: strings.password,
		},
	};
	const sortMethods = (a, b) => {
		const recommended =
			Boolean(methodsMap[b].recommended) -
			Boolean(methodsMap[a].recommended);

		if (recommended !== 0) return recommended;

		return Boolean(methodsMap[b].primary) - Boolean(methodsMap[a].primary);
	};

	const primaryClass = (method) =>
		methodsMap[method].primary || methodsMap[method].recommended
			? ''
			: ' bg-border';

	return (
		<div className="lit-login-page">
			<header className="lit-header">
				<h1
					className="lit-logo clickable"
					onClick={() => {
						resetAuth();
					}}
				>
					<img
						className="lit-logo__img"
						src={Logo}
						alt="Literate logo"
					/>
				</h1>
				{/* <nav className="lit-menu">
					Language
					<select
						value={language}
						onChange={(e) => {
							console.log(e.target.value);
						}}
					>
						<option value="no">Norwegian</option>
						<option value="en">English</option>
					</select>
				</nav> */}
			</header>
			<div className="lit-view__content">
				<p className="lit-thirdPCsupport" id="result"></p>

				<div className="lit-thrid-party-settings">
					<p
						className="candidate-start-fail__instructions"
						style={{
							color: 'Black',
							margin: 'auto',
							textAlign: 'center',
							fontSize: '18px',
						}}
					>
						<span style={{color: 'Coral'}}>
							<b>
								<span id="lit-site-warning">
									{strings.warning1}
								</span>{' '}
							</b>
						</span>
						<em>
							<span id="lit-site-warning_text">
								{strings.warning1text}{' '}
							</span>
						</em>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://telehealth.zendesk.com/hc/en-us/articles/360051124953-Enabling-cross-site-tracking/"
						>
							<u style={{color: 'blue'}}>
								{strings.linkForInstructions}
							</u>
						</a>
					</p>
				</div>
				<div className="lit-login">
					{!isInit && (
						<div className="lit-login__wrapper bg-white">
							<h1 className="lit-login__header">
								{strings.login}
							</h1>
							<p className="lit-login__description">
								{strings.loginwithbankid}
							</p>
							<p className="lit-login__description">
								{strings.message_thisisforcertifieduser}
							</p>
							<div className="lit-login__action-wrapper">
								{loginMethods
									.sort(sortMethods)
									.map((method) => (
										<button
											key={method}
											className={
												'lit-btn bg-primary' +
												primaryClass(method)
											}
											onClick={() => {
												let authToken = localStorage.getItem(
													'authtoken'
												);
												if (authToken)
													window.localStorage.removeItem(
														'authtoken'
													);

												if (testKey) {
													if (method === BANK_ID) {
														window.confirm(
															strings.reminder_bankidmobileregistration
														);
													} else if (
														method ===
														BANK_ID_MOBILE
													) {
														window.confirm(
															strings.reminder_bankidmobileregistration
														);
													}
												}
												initAuth(method, testKey);
											}}
										>
											{methodsMap[method].label +
												(methodsMap[method].recommended
													? ` (${strings.recommended})`
													: '')}
										</button>
									))}
							</div>
						</div>
					)}
					{isInit && (
						<>
							{authMethod === FEIDE && (
								<div className="bank-id-iframe-wrapper">
									<iframe
										title="login-frame"
										id="authFrame"
										scrolling="false"
										frameBorder="false"
										src={iframeUrl}
										style={{
											width: '120%',
											minHeight: '580px',
										}}
									/>
								</div>
							)}
							{[BANK_ID, BANK_ID_MOBILE].includes(authMethod) && (
								<div className="bank-id-iframe-wrapper">
									<iframe
										title="login-frame"
										id="authFrame"
										scrolling="false"
										frameBorder="false"
										src={iframeUrl}
										style={{
											width: '100%',
											minHeight: '200px',
										}}
									/>
								</div>
							)}
							{authMethod === 'Password' && (
								<div className="custom-login-iframe-wrapper">
									<EmailPasswordLogin />
								</div>
							)}
						</>
					)}
					<div>
						<iframe
							src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
							style={{display: 'none'}}
						/>
					</div>
					{error && (
						<p className="error">
							<strong>{error}</strong>
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default Login;

/* eslint-disable camelcase */

export default {
	mytest: `Mine tester`,
	myCandidates: `Mine Kandidater`,
	createnewtest: `Opprett ny test`,
	started: `Påbegynt`,
	starttest: `Start test`,
	all: `Alle`,
	chooseagroup: `Velg en gruppe`,
	distributed: `Distribuert`,
	notdistributed: `Ikke distribuert`,
	notstarted: `Ikke startet`,
	completed: `Fullført`,
	completeplural: `Fullførte:`,
	seeresults: `Se resultater`,
	status: `Status`,
	testkey: `Testnøkkel`,
	type: `Type`,
	recipient: `Mottaker`,
	actions: `Handlinger`,
	send: `Send`,
	save: `Lagre`,
	sendcodeemail: `Send kode på epost`,
	january: `Januar`,
	february: `Februar`,
	march: `Mars`,
	april: `April`,
	may: `Mai`,
	june: `Juni`,
	juli: `Juli`,
	august: `August`,
	september: `September`,
	october: `Oktober`,
	november: `November`,
	december: `Desember`,
	norway: `Norge`,
	sweden: `Sverige`,
	germany: `Tyskland`,
	england: `Storbritannia`,
	nederland: `Nederland`,
	message_testcannotbedeleted: `Testen må være minst 3 måneder gammel for å bli slettet.`,
	warning_deletetest: `Testen vil ikke lenger være tilgjengelig for vurdering. Er du sikker på at du vil fortsette?`,
	headerchildtest: `Testresultater for Literate barnetest`,
	dynamicheaderfortestresult_child: `Detaljert resultater for Literate {0}`,
	dynamicheaderfortestreport_child: `Detaljert rapport for Literate {0}`,
	dynamicheaderfortestcandidate_child: `Detaljert Kandidatopplysninger for Literate {0}`,
	dynamicheaderfortestresult_adult: `Detaljert resultater for Literate {0}`,
	dynamicheaderfortestreport_adult: `Detaljert rapport for Literate {0}`,
	dynamicheaderfortestcandidate_adult: `Detaljert Kandidatopplysninger for Literate {0}`,
	headeradulttest: `Testresultater for Literate screeningtest for voksne`,
	showbackground: `Vis bakgrunnsinformasjon om kandidaten`,
	incompleteMessage: `Denne testen er ikke komplett, og noen deltester mangler eller er ikke gjennomført enda`,
	summary: `Oppsummering`,
	printtestresults: `Print testresultater`,
	zscorered: `Z-skåre er mer enn 1 standardavvik {0} gjennomsnittet`,
	zscoregreen: `Z-skåre er mer enn 1 standardavvik {0} gjennomsnittet`,
	zscoreblue: `Z-skåre er innen gjennomsnittetsområdet`,
	overview: `Oversikt`,
	assessment: `Vurdering:`,
	assessmentspeechok: `{0} har oppnådd resultater som ligger i normalområdet eller bedre for eksperimentell Ett Minutt Test, og ser derfor ut til å ha gode leseferdigheter`,
	assessmentspeechnotok: `{0} har oppnådd resultater som ligger under normalområdet for eksperimentell Ett Minutt Test, og bør derfor utredes videre med tanke på dysleksi og/eller leseforståelsesvansker`,
	asessmenttextok_1: `{0} har oppnådd resultater som ligger i normalområdet eller bedre for {1} av testene, og ser derfor ut til å ha gode lese- og skriveferdigheter`,
	asessmenttextnotok_1: `{0} har oppnådd resultater som ligger under normalområdet på {1} av de {2} testene, og bør derfor utredes videre med tanke på dysleksi og/eller leseforståelsesvansker`,
	assesmenttextok_youthfollowup: `{0} har ingen utslag på de typiske kognitive markørene på dysleksi.`,
	assesmentok_recommendation_youthfollowup: `Dersom {0} likevel har oppnådd skårer under gjennomsnittsområdet på Leseprøven og/eller Ordforståelse, eller eventuelt på noen av de andre av testene i Literate screeningtest, anbefales en videre kartlegging med tanke på mulig leseforståelsessvikt og/eller mulig underliggende utviklingsmessige språkforstyrrelse (DLD).`,
	workingmemorylowassesment: `{0} har utslag på Arbeidsminne, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder på denne testen. Arbeidsminne er i seg selv ikke en kognitiv markør på dysleksi, men er assosiert med både dysleksi og leseforståelsessvikt. Redusert arbeidsminne kan blant annet påvirke hvor mye informasjon man greier å få med seg fra lest tekst. Det anbefales derfor å undersøke i hvor stor grad arbeidsminnet til {2} påvirker tilegnelsen av nytt fagstoff, og om det er behov for skolefaglig tilrettelegging rundt dette.`,
	rapidnominationlowassesment: `{0} har utslag på Hurtigbenevning, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder på denne testen. Hurtigbenevningstesten måler hvor raskt og nøyaktig man greier å hente frem ord fra språkminnet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.`,
	oneminutelowassesment: `{0} har utslag på Ettminuttstesten, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Ettminuttstesten gir et rent mål på antallriktig leste ord per minutt, og gir et godt bilde av avkodingsferdigheter. Svake avkodingsferdigheter er en markør på dysleksi.`,
	oneminutelowassesment2: `NB! Det anbefales å vurdere resultatene på Ettminuttstesten i sammenheng med resultatene på Diktatprøven og Ordkjedetesten på Literate screeningtest.`,
	spoonerismlowassesment: `{0} har utslag på Spoonerism, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Spoonerism-testen måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på disse områdene er en kognitiv markør på dysleksi.`,
	rawscore: `Råskåre`,
	zscore: `Z-Skåre`,
	percentilescore: `Percentilskåre`,
	tscore: `T-Skåre`,
	scaledscore: `Skalert skåre`,
	standardscore: `Standard skåre`,
	normalrange: `Normalområde`,
	assessmentsubtestnormal: `Denne skåren er i normalområdet`,
	assessmentsubtestbelow: `Denne skåren ligger {0} standardavvik {1} gjennomsnittet.`,
	under: `under`,
	assessmentsubtestabove: `Denne skåren ligger {0} standardavvik {1} gjennomsnittet`,
	over: `over`,
	workingmemorytest: `Arbeidsminne`,
	spoonerismtest: `Spoonerism`,
	oneminutetest: `Ettminutttest`,
	letterknowledgetest: `Bokstavkunnskap`,
	phonemisolationtest: `Fonemisolasjon`,
	worddictationtest: `Orddiktat`,
	phonemeomissiontest: `Fonemutelatelse`,
	wordchaintest: `Ordkjedetest`,
	nonwordtest: `Tulleordtesten`,
	typingspeedtest: `Skrivehastighet`,
	readingtest: `Leseprøven`,
	wordcomprehensiontest: `Ordforståelse`,
	loggin: `Logg inn`,
	loggout: `Logg ut`,
	trialperiod: `Prøveperiode`,
	trialuser: `Prøvebruker`,
	basicsupcription: `Basis abonnement`,
	fullsupscription: `Fullt abonnement`,
	childsubscription: `Barnetest abonnement`,
	adultsubscription: `Vokstentest abonnement`,
	youthsubscription: `Ungdomstest abonnment`,
	name: `Navn:`,
	firstname: `Fornavn`,
	lastname: `Etternavn`,
	dateofbirth: `Fødselsdato:`,
	birthDate: `Fødselsdato`,
	paneMessageContinueTest: `Dette vil sende en ny lenke til testkandidaten for å fortsette testen som ikke ble fullført tidligere. Vil du fortsette?`,
	telephone: `Telefon`,
	email: `E-post`,
	edit: `Endre`,
	edituser: `Endre bruker`,
	seesubscription: `Se abonnement`,
	adulttests: `Voksentester`,
	youthtests: `Ungdomstester`,
	confirmationofcost: `Bekreftelse av kostnad`,
	sex: `Kjønn`,
	male: `Mann`,
	female: `Kvinne`,
	education: `Utdanning`,
	cancel: `Avbryt`,
	confirm: `Bekreft`,
	age: `Alder`,
	age_specified: `Alder (år)`,
	testdate: `Testdato`,
	childtest: `Barnetest`,
	experimentalchildtest: `Eksperimentell Barnetest`,
	youthtest: `Youth-screening`,
	adulttest: `Voksen-screening`,
	screeningtest: 'Screeningtest',
	adultscreening: `screeningtest for voksne`,
	youthscreening: `Ungdomstest`,
	childtests: `Barnetester`,
	nousedtests: `Ingen brukte {0} tester`,
	used: `Brukte:`,
	boy: `Gutt`,
	girl: `Jente`,
	logg: `Hendelseslogg`,
	of: `av {0}`,
	wordsperminute: `ord per minutt`,
	deleteoldtests: `Slett gamle tester`,
	addtesttogroup: `Legg til test i en gruppe`,
	message_incompletData: `Ugyldige eller ufullstendige data`,
	newclasscreated: `Ny klasse opprettet`,
	testcandidateremoved: `Testkandidat er fjernet fra Klassen`,
	testcandidateadded: `Testkandidat lagt til klassen`,
	classexistchoosename: `Klassen finnes allerede, vennligst velg et annet navn.`,
	class: `.trinn`,
	subscription: `Abonnement`,
	supersubscription: `sitt abonnement`,
	mysubscription: `Mitt abonnement`,
	seetests: `Se tester`,
	currencysymbol: `NOK`,
	hideWithArrow: `Skjul ▲`,
	yourtrialperiodhasexpired: `Din prøveperiode har utløpt`,
	upgradeyoursubscription: `Oppgrader ditt abonnement`,
	downloadandsendcontract: `For å ta flere tester må du tegne et abonnement. Gå inn på «Min side» på literate.no for å bestille abonnement, eller last ned kontrakt og send til`,
	priceandconditioninfo: `. Prisinformasjon og betingelser finnes på`,
	downloadcontract: `Last Ned Kontrakt`,
	onlytwotestspertrialperiod: `Kun 2 tester er tilgjengelige i prøveperioden`,
	upgradetofullsubscription: `oppgrader til fullt abonnement for å opprette nye tester`,
	fetch: `Gjenopprett`,
	language: `Språk`,
	child: `Barne`,
	youth: `Ungdom`,
	adult: `Voksen`,
	youthfollowup: `Ungdom-Oppfølging`,
	youthfollowuptest: `Ungdom-Oppfølgingstest`,
	adultfollowup: `Voksen-Oppfølging`,
	adultfollowuptest: `Voksen-Oppfølgingstest`,
	childfollowuptest: `Barn-Oppfølgingstest`,
	childnorming: `Barne-Normering`,
	youthnorming: `Ungdom-Normering`,
	adultnorming: `Voksen-Normering`,
	deletedon: `Slettet på`,
	basic: `Basic`,
	deletedtests: `Slettede tester`,
	viewsubscription: `Se abonnement ▼`,
	viewdeleted: `Slettede tester ▼`,
	referalinformation: `sin informasjon`,
	referalgroups: `sine grupper`,
	mygroups: `Mine grupper`,
	myinformation: `Mine opplysninger`,
	message_costsforeachtestthatis_started: `Det vil påløpe kostnader for hver test som blir tatt i bruk. Tester som ikke startes vil heller ikke bli fakturert for.`,
	test_type: `Test type`,
	screeningtest_adult: `Screeningtest (voksen)`,
	numberoftests: `Antall tester (maks 30)`,
	create_tests: `Opprett nye tester`,
	login: `Logg inn`,
	loginwithbankid: `Logg inn med Feide eller BankID ved å trykke på en av knappene under`,
	message_thisisforcertifieduser: `Dette er for Testadministrator (dvs. sertifiserte brukere med abonnement)`,
	bankid: `BankID`,
	bankidonmobile: `BankID på Mobil`,
	edittestadministrator: `Endre testadministrator`,
	personalinformation: `Personalia`,
	shcool_or_company: `Skole/bedrift`,
	addanewtestadministrator: `Legg til en ny testadministrator`,
	addtestadministrator: `Legg til testadministrator`,
	saveuserinformation: `Lagre kandidatopplysninger`,
	delete: `Slett`,
	deleteuser: `Slett bruker`,
	currentstatus: `Nåværende status:`,
	savesubscription: `Lagre abonnement`,
	organization: `Organisasjon`,
	newloginmethod: `Ny Innloggingsmetode`,
	errorhasoccured: `En feil har oppstått`,
	message_singleuser_perks_trial: `Med en bruker kan du prøve tjenesten gratis i 30 dager og får med 2 gratis tester`,
	message_change_loginmethod: `Du er i ferd med å endre påloggingsmetoden. Husk å logge på med samme legitimasjon neste gang`,
	info_for_professional_with_either_completed_screening_test: `Dette er for fagpersoner som enten har gjennomført, eller som har meldt seg på, sertifiseringskurs og som ønsker å prøve Literate screeningtest før man tegner fullt abonnement.`,
	info_prerequisites_to_obtain_license: `For å få prøvelisens må man ha gjennomført eller meldt seg på Literate sertifiseringskurs. Alle registreringer godkjennes manuelt, og ved godkjenning sendes en e-post med informasjon for å fullføre registreringen`,
	removefiltering: `Fjern filtering`,
	printreport: `Print testrapport`,
	hide: `Skjul`,
	show: `Vis`,
	userinformation: `Kandidatopplysninger`,
	localizedwriteuptextforlowresults: `{0} har oppnådd resultater som ligger under normalområdet på {1} av de {2} testene, og bør derfor utredes videre med tanke på dysleksi og/eller leseforståelsesvansker`,
	localizedwriteuptextforNormalresults: `{0} har oppnådd resultater som ligger i normalområdet eller bedre for {1} av testene, og ser derfor ut til å ha gode lese og skriveferdigheter`,
	testreultsforliteratescreeningtest: `Testresultater på Literate screeningtest`,
	info_belowpresentsinformation: `Nedenfor presenteres testresultatene for {0}, ved alder {1} år og {2} måneder.`,
	newinfo_belowpresentsinformation: `Nedenfor presenteres testresultatene for {0}, født {1}, (Alder: {2} år og {3} måneder).`,
	newinfo2_belowpresentsinformation: `Nedenfor presenteres test rapport for {0}, født {1}, (Alder: {2} år og {3} måneder).`,
	newinfo3_belowpresentsinformation: `Nedenfor presenteres kandidatopplysninger for {0}, født {1}, (Alder: {2} år).`,
	speech1_belowpresentsinformation: `Nedenfor presenteres testresultatene for {0}, (Alder: {1}).`,
	speech2_belowpresentsinformation: `Nedenfor presenteres test rapport for {0}, (Alder: {1} år).`,
	speech3_belowpresentsinformation: `Nedenfor presenteres kandidatopplysninger for {0}, (Alder: {1} år).`,
	info_testwascompletedon: `Testen ble gjennomført {0}`,
	info_testwasNotcompletedon: `Denne testen er ikke komplett.`,
	notetsfound: `Ingen gjennomførte tester funnet`,
	background_info_testcandidate: `bakgrunnsinformasjon for testkandidat`,
	candidate_answers: `Kandidatens svar`,
	selectLanguage: `Velg språk`,
	norwegian: `Norsk`,
	german: `Tysk`,
	dutch: `Nederlandsk`,
	english: `Engelsk`,
	swedish: `Svensk`,
	usersupport: `Brukerstøtte`,
	groups: `Grupper`,
	groupname: `Gruppenavn`,
	date: `Dato`,
	insert: `Sett inn`,
	selected_users: `Valgte Brukere`,
	alreadyexistsingroup: `finnes allerede i denne gruppen!`,
	orddiktatheading: `Denne testen kartlegger rettskrivingsferdigheter og ortografisk bevissthet. Her skal testkandidaten skrive ord som blir lest opp. Hvert ord blir først presentert i en setning, for å sikre at riktig betydning av ordet er forstått, også blir mål-ordet gjentatt og testkandidaten skriver dette. Det er ingen tidsbegrensning på denne testen, og hver setning og mål-ord kan gjentas én gang.`,
	orddiktatchildexplanation: `Det gis ett poeng for hvert riktig svar (maks 13 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	orddiktatadultexplanation: `Det gis ett poeng for hvert riktig svar (maks 45 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	fonemisolasjonheading: `Denne testen kartlegger fonembevissthet hos de yngste elevene. På denne testen blir testkandidaten presentert for fire bilder, og blir bedt om å peke på det bildet som enten begynner med, eller slutter på, en bestemt lyd.`,
	fonemisolasjonexplanation: `Det gis ett poeng for hvert riktig svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	fonemisolasjonvariabletext: `{0} oppnådde på denne testen en skår på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	fonemutelatelseheading: `Denne testen kartlegger fonembevissthet hos elever på barnetrinnet. På denne testen blir testkandidaten muntlig presentert for ord, og blir bedt om si det ordet som blir igjen når en bestemt lyd fjernes (f.eks. /b/ i brød => rød).`,
	fonemutelatelseexplanation: `Det gis ett poeng for hvert riktig svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	fonemutelatelsevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	spoonerismheading: `Denne testen kartlegger fonembevissthet. Testkandidaten blir muntlig presentert for 20 ordpar, og blir bedt om å bytte første lyden i de to ordene så raskt og nøyaktig som mulig.`,
	spoonerismexplanation: `Det gis ett poeng for hvert riktig ord kandidaten greier å si med den nye førstelyden (maks 40 poeng). Denne testen går også på tid. Skåren her er antall riktige ord som har byttet første lyd, dividert med den tiden det tar å gjennomføre oppgavene som er helt eller delvis løst. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	spoonerismvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	ettminuttstestheading: `Dette er en høytlesningsprøve som kartlegger avkodingsferdighet. Her blir testkandidaten presentert for en liste med ord og blir bedt om å lese så fort og nøyaktig som mulig innen en tidsramme på 60 sekunder. Resultatet her gir et mål på antall riktig leste ord per minutt.`,
	ettminuttstestexplanation: `Det gis ett poeng for hvert riktig leste ord. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ettminuttstestvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	hurtigbenevningheading: `Denne testen kartlegger hvor raskt testkandidaten greier å benevne kjente objekter, og å gjenhente navn på objekter og farger fra det språklige langtidsminnet.`,
	hurtigbenevningexplanation: `Det gis ett poeng for hver riktig kombinasjon av farge og objekt kandidaten sier. Denne testen går også på tid. Skåren her er antall riktige farge-objekt-kombinasjoner dividert med den totale tiden det tar å benevne alle 25 farge-objekt-kombinasjonene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	hurtigbenevningvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	arbeidsminnenummberheading: `Denne testen kartlegger testkandidatens evne til å lagre og bearbeide informasjon. På denne testen blir kandidaten først presentert for en serie med tall, deretter en serie med ord, som skal gjentas i omvendt rekkefølge enn de blir presentert. Både tallrekkene og ordrekkene øker i lengde dersom kandidaten mestrer den forrige rekken.`,
	arbeidsminneexplanation: `Det gis ett poeng for hvert riktig svar (maks 12 poeng for tallrekkene og maks 12 poeng for ordrekkene, til sammen maks 24 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	arbeidsminnevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	arbeidsminneheading: `Denne testen kartlegger testkandidatens evne til å lagre og bearbeide informasjon. På denne testen blir kandidaten først presentert for en serie med tall, deretter en serie med ord, som skal gjentas i omvendt rekkefølge enn de blir presentert. Både tallrekkene og ordrekkene øker i lengde dersom kandidaten mestrer den forrige rekken.`,
	ordforståelseheading: `Denne testen måler ordforråd ved at testkandidaten må finne et ord som har samme betydning som et gitt mål-ord. På hver oppgave må testkandidaten velge blant fire alternativer. Denne testen gir et mål på bredde og dybde av ordforrådet.`,
	ordforståelseexplanation: `Det gis ett poeng for hvert riktig svar (maks 15 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ordforståelsevariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	ordforståelseyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 20 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	leseprøvenheading: `Denne testen er en stillelesingsprøve som måler leseforståelse ved at testkandidaten må svare på spørsmål direkte fra teksten, sammenstille informasjon fra ulike avsnitt og trekke konklusjoner. Testkandidaten får 10 minutter til å lese teksten og svare på så mange flervalgsspørsmål som mulig. NB! Her er det viktig å registrere hvorvidt testkandidaten har svart feil eller ikke har besvart flervalgsspørsmålene (på grunn av sein lesehastighet).`,
	leseprøvenyouthheading: `Denne testen er en stillelesingsprøve som måler leseforståelse ved at testkandidaten må svare på spørsmål direkte fra teksten, sammenstille informasjon fra ulike avsnitt og trekke konklusjoner. Testkandidaten får 12 minutter til å lese teksten og svare på så mange flervalgsspørsmål som mulig. NB! Her er det viktig å registrere hvorvidt testkandidaten har svart feil eller ikke har besvart flervalgsspørsmålene (på grunn av sein lesehastighet).`,
	leseprøvenexplanation: `Det gis ett poeng for hvert riktig svar (maks 14 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	leseprøvenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	leseprøvenyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 9 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	skrivehastighetheading: `Denne testen måler effektivitet i skriving. Det er en ferdighet som behøves når man for eksempel skal ta notater eller skrive en eksamensbesvarelse. Testkandidaten får se en setning foran seg hele tiden, og skal skrive av denne så mange ganger og så nøyaktig som mulig i løpet 2 minutter.`,
	skrivehastighetexplanation: `Skåren her er antall riktig skrevne ord i snitt per minutt. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	skrivehastighetvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	tulleordtestenheading: `Denne testen er en stillelesingsprøve som måler fonologisk bevissthet og avkodingsferdigheter. Testen går ut på at testkandidaten skal identifisere tulleord som uttales som ordentlige ord, og skjelne fra tulleord som ikke uttales som ordentlige ord. På hver oppgave må testkandidaten velge blant fem alternativer, hvor bare ett er riktig. Testkandidaten får to minutter til å løse så mange oppgaver som mulig.`,
	tulleordtestenexplanation: `Det gis ett poeng for hvert riktig svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	tulleordtestenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	ordkjedetestenheading: `Denne testen er en stillelesingsprøve som måler hurtig ordgjenkjenning og ortografisk kunnskap. Her blir eleven bedt om å lage mellomrom mellom ord som er skrevet sammen til en kjede. Hver kjede består av fire tilfeldige ord. Testkandidaten får 4 minutter til å løse så mange ordkjeder som mulig.`,
	ordkjedetestenexplanation: `Det gis ett poeng for hvert riktig svar (maks 40 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	ordkjedetestenvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	ordkjedetestenyouthexplanation: `Det gis ett poeng for hvert riktig svar (maks 36 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	includeLongIntro: `Inkluder selvrapportering /anamnese`,
	anamneseexplaination: `Dette er en endring innført 22.april. Skal testen brukes som en screening, så er det ikke nødvendig å ha med selvrapportering/anamnese`,
	chooselanguage: `Velg språk`,
	seegrouptests: `Se gruppetester`,
	addtogroup: `Legg til i en gruppe`,
	nogroupsfound: `Ingen grupper funnet`,
	fromgroups: `Fra gruppe`,
	allmytests: `Alle mine tester`,
	groupindicator: `-----Grupper-----`,
	notanswered: `ikke besvart`,
	testnotanswered: `{0} oppgaver ikke besvart`,
	showunanswered: `Vis ubesvarte`,
	hideunanswered: `Skjul ubesvarte`,
	rightanswer: `Rett svar: {0}`,
	action: `Handling`,
	deleteall: `Slett alle`,
	chooseclass: `Velg klasse`,
	confirmationdeleteclass: `Er du sikker på at du vil slette denne {0}`,
	theclass: `klassen`,
	multipleclasses: `klasser`,
	deleteclass: `Slett klasse`,
	message_noclasses: `Du har ingen klasser`,
	showtests: `Se tester ▼`,
	allmycandidates: `Alle mine kandidater`,
	notestsforcandidate: `Denne kandidaten har ingen gjennomførte tester`,
	gender: `Kjønn`,
	nocandidates: `Du har ingen testkandidater`,
	choosecandidate: `Velg kandidat`,
	classindicator: `-----Klasser-----`,
	sendtest: `Send test`,
	createclass: `Opprett Klasse`,
	removefromclass: `Fjern fra Klasse`,
	providevalidname: `Oppgi et gyldig navn`,
	searchhere: `Søk her...`,
	newclass: `Ny Klasse`,
	writeclassname: `Skriv klassenavn...`,
	deleting: `Sletter`,
	mypage: `Min side`,
	notavailable: `ikke tilgjengelig`,
	theagegroup: `aldersgruppen`,
	hiddenname: `<Skjult navn>`,
	wordreadcorrectly: `riktige ord lest`,
	correct: `Riktig`,
	wrong: `Feil`,
	timeused: `Tid brukt`,
	thisagegroup: `aldersgruppen`,
	adminpersonalinformation: `Personopplysninger`,
	assessmentokBHtest: `{0} har oppnådd resultater som ligger i gjennomsnittsområdet eller bedre for testene, og ser ut til å ligge på det nivå som er typisk for sin alder på områder som er viktig for å utvikle gode lese- og skriveferdigheter.`,
	assessmentBHFI: `{0} har utslag på Fonemisolasjon, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn. Fonemisolasjonstesten måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.`,
	bokstavkunnskapheading: `Denne testen kartlegger bokstavkunnskap hos de yngste elevene. På denne testen presenteres 13 av bokstavene i det norske alfabetet i tilfeldig rekkefølge. Testkandidaten blir så bedt om å si både navnet og lyden til bokstavene.`,
	bokstavkunnskapexplanation: `Det gis ett poeng for hvert riktig svar (maks 15 poeng for lyd og maks 15 poeng for navn, til sammen maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	bokstavkunnskapvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavvik fra gjennomsnittet for trinnet. Dette er et resultat som plasserer seg`,
	bokstavkunnskapvariabletextBH: `{0} oppnådde på denne testen en skåre på {1}. Gjennomsnitt og standardavvik for dette trinnet er ikke tilgjengelig.`,
	hurtigbenevningBHexplanation: `Det gis ett poeng for hver riktig kombinasjon av farge og objekt kandidaten sier. Denne testen går også på tid. Skåren her er antall riktige farge-objekt-kombinasjoner dividert med den totale tiden det tar å benevne alle 50 farge-objekt-kombinasjonene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	reminderScoreChange: `Før du endrer statusen til dette bestemte svaret, vil vi minne deg om at dette vil påvirke kandidatens poengsum`,
	correctAnswerConfirmation: `Vennligst bekreft hvorvidt ordet var lest riktig eller feil`,
	cantretrieveaudio: `Vi kunne ikke finne lydfiler for denne testen`,
	continuetest: `Fortsett test`,
	confirmConsent: `Er denne testen med i normeringsstudien? Hvis dette er en øvingstest utenom normeringsstudien, så trykk "Nei". Det betyr at testen ikke blir medregnet i normeringen.`,
	hasChildGrownInMlHome: `Er barnet vokst opp i et flerspråklig hjem?`,
	whichDeviceIsGoingToBeUsed: `Hvilket utstyr skal testkandidaten bruke?`,
	areParentSuspiciousOfdyslexia: `Mistenker foreldre eller skolen at barnet har lese- og skrivevansker?`,
	hasChildBeenDiagnosed: `Har barnet fått påvist lese- og skrivevansker`,
	classGrade: `Klassetrinn`,
	consentText: `Testkandiatens foresatte har gitt samtykke til deltakelse i normeringsstudien.`,
	constTestYouth: `Som Testadministrator så bekrefter du at du har nødvendig samtykke fra Testkandiaten til å innhente personopplysninger og at disse brukes som beskrevet i Literates {0}`,
	privacyPolicy: `personvernerklæring`,
	iConsent: `Jeg bekrefter:`,
	questionFirstLanguage: `Hva er barnet 1. språk?`,
	questionSecondLanguage: `Hva er barnet 2. språk?`,
	otherFirstLanguage: `Annet 1. språk`,
	otherSecondLanguage: `Annet 2. språk`,
	testredistributed: `Testen er sendt til kandidaten. Ny pinkode er {0} (Hvis kandidaten skal logge inn med e-post)`,
	users: `Brukere`,
	userNeverInvoiced: `Denne brukeren har aldri blitt fakturert`,
	needsapproval: `Trenger godkjenning`,
	notcompletedregistration: `Ikke fullført registrering`,
	approveuesr: `Godkjenn bruker`,
	sendnewinvitation: `Send invitasjon på nytt`,
	trialperiodexpired: `Prøveperiode utløpt`,
	trialperiodinprogress: `Prøveperiode pågår`,
	extendtrialperiod: `Utvid prøveperiode`,
	message_notallowedemptysubscription: `Du kan ikke tildele et tomt abonnement! Slett språket i stedet.`,
	savepermission: `save permission`,
	addlanguage: `Legg Til Språk`,
	warning_deleteLanguage: `Er du sikker på at du vil slette dette språken?`,
	fullname: `Fullstendig Navn`,
	needsconfirmation: `Trenger godkjenning`,
	hasnotcompletedregistration: `Ikke fullført registrering`,
	fullsubscriptionadulttests: `Fullt abonnement (voksentester)`,
	fullsubscriptionayouthtests: `Fullt abonnement (ungdomstester)`,
	fullsubscriptionachildrentests: `Fullt abonnement (barnetester)`,
	inactive: `Inaktiv`,
	message_nouserswiththestatus: `Ingen brukere med status {0}`,
	message_confirmation: `Dette vil sende en bekreftelse til brukerens epost, og gi brukeren tilgang til å begynne prøveperiode.`,
	createnewuser: `Lag ny bruker`,
	success: `Suksess!`,
	newuser: `Ny Bruker`,
	select_users: `Velg brukere`,
	exportmailingaddress: `Eksportér adresseliste`,
	copyemails: `Kopier e-post`,
	creategroups: `Lag grupper`,
	createnewgroup: `Opprett ny gruppe`,
	writegroupname: `skriv gruppenavn...`,
	deletegroup: `Slett gruppen`,
	editgroup: `Rediger gruppe`,
	cannotaddexistingmembertogroup: `Kan ikke legge til medlemmer som allerede er i gruppen`,
	newgroup: `Ny gruppe`,
	removefromgroup: `Fjern fra grupper`,
	nonewusersduringtimeperiod: `Ingen nye kandidater i løpet av denne tidsperioden`,
	testdeletedbyuser: `Testen ble slettet av brukeren`,
	testolderthan3years: `testen er eldre enn 3 år`,
	warning_deleteSingleTest: `Dette vil slette testen permanent. Fortsett uansett?`,
	warning_deleteAllTest: `Dette vil slette alle testene permanent. Fortsett uansett?`,
	totalnew: `Nye/Totalt`,
	usedon: `Brukt på`,
	reason: `Årsaken`,
	from: `Fra:`,
	to: `Til:`,
	loggedIn: `Innlogget`,
	blocked: `Blokkert`,
	blockuser: `Blokker bruker`,
	unblockuser: `Ublokker bruker`,
	country: `Land:`,
	allcountries: `Alle land`,
	warning_deletegroup: `Er du sikker på at du vil slette denne gruppen?`,
	warning_deletemember: `Er du sikker på at du vil slette dette medlemmet?`,
	groupalreadyexists: `Gruppen eksisterer allerede`,
	reminder_feideregistration: `Hvis du registrerer deg med FEIDE, må du også bruke FEIDE for å logge inn til tjenesten videre. Vil du fortsette?`,
	reminder_bankidregistration: `Vi anbefaler at du registrer deg selv med Feide. Hvis du registrerer deg med Bank-ID, må du også bruke Bank-ID for å logge inn til tjenesten videre. Vil du fortsette uansett?`,
	reminder_bankidmobileregistration: `Vi anbefaler at du registrer deg selv med Feide. Hvis du registrerer deg med Bank-ID på Mobil, må du også bruke Bank-ID på Mobil for å logge inn til tjenesten videre. Vil du fortsette uansett?`,
	feiderecommended: `Feide (anbefalt)`,
	warning_blockuser: `Er du sikker på at du vil blokkere valgte bruker?`,
	warning_unblockuser: `Er du sikker på at du vil ublokkere valgte bruker?`,
	showearliermonths: `Se tidligere måneder ▼`,
	teststhismonths: `Tester denne måneden`,
	coststhismonth: `Kostnader denne måneden`,
	previousperiods: `Tidligere perioder`,
	period: `Periode`,
	tests: `Tester`,
	statuses: `Statuser`,
	costs: `Konstander`,
	notestsafterdatetoinvoice: `Ingen tester etter valgte dato å fakturere`,
	changeToFeide: `Jeg ønsker å bytte til Feide`,
	chageToPassword: `Jeg ønsker å bytte til Passord `,
	accessMakeTests: `Har tilgang til å lage nye tester og se testresultater`,
	accessNormingMakeTests: `Har tilgang til å lage normeringstester og se testresultater`,
	accessProdMakeTests: `Har tilgang til å lage produksjonstester og se testresultater`,
	active: `Aktiv`,
	activeSubscription: `Aktivt abonnement`,
	alltests: `Alle tester`,
	assessmentBHFU: `{0} har utslag på Fonemutelatelse, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn. Fonemutelatelsestesten måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi.`,
	assessmentBHonlyLK: `{0} har lavere enn forventet bokstavkunnskap for trinnet. Dette kan ha sammenheng med fremgangsmåten for bokstavinnlæringen på skolen. Det anbefales likevel at man følger nøye med utviklingen i elevens bokstav-lyd-innlæring.`,
	assessmentBHRI: `{0} har utslag på Hurtigbenevning, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn på denne testen. Hurtigbenevningstesten måler hvor raskt og nøyaktig man greier å hente frem ord fra språkminnet. Svake ferdigheter på dette området er en kognitiv markør på dysleksi`,
	assessmentBHWM: `{0} har utslag på Arbeidsminne, og oppnår en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn på denne testen. Arbeidsminne er i seg selv ikke en kognitiv markør på dysleksi, men er assosiert med både dysleksi og leseforståelsessvikt. Redusert arbeidsminne kan blant annet påvirke hvor mye informasjon man greier å få med seg fra lest tekst. Det anbefales derfor å undersøke i hvor stor grad arbeidsminnet til {2} påvirker tilegnelsen av nytt fagstoff, og om det er behov for skolefaglig tilrettelegging rundt dette.`,
	averageArea: `gjennomsnittsområdet`,
	averageForGrade: `gjennomsnittsområdet for trinnet.`,
	averagezscore: `Gjennomsnittig Z-skåre`,
	awaitingConfirmationLongText: `Bruker er godkjent og e-post er sendt med invitasjon`,
	byttelydtestexplanation: `Det gis ett poeng for hvert riktig svar (maks 21). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	byttelydtestheading: `Denne testen måler fonembevissthet og fonologisk prosesseringshastighet. Testen går ut på at kandidaten skal bytte ut en gitt lyd i ord med en annen lyd, sånn at det blir et nytt ord. For eksempel blir kandidaten bedt om å bytte ut lyden /b/ i «bil» med lyden /p/. På hver oppgave får kandidaten presentert en illustrasjon av hovedordet (f.eks. bil), som så blir etterfulgt av fire illustrerte svaralternativ. Bare ett av alternativene er riktig (her: pil). Testkandidaten får 10 sekunder til å løse hver oppgave. En ny oppgave dukker automatisk opp på skjermen dersom kandidaten ikke har svart innen tidsfristen.`,
	byttelydtestvariabletext: `{0} oppnådde på denne testen en skåre på {1}. Denne skåren plasserer seg {2} standardavviksenheter fra gjennomsnittet for trinnet. Hen oppnår dermed et resultat som plasserer seg`,
	candidateInfoCommon: `Test kandidaten tar testen på dysmate.no > ta testen.`,
	candidateInfoReminder: `Merk at pinkoden også er tilgjengelig for testadministrator i oversikten på <<Mine tester>>`,
	checked: `checked`,
	childrenAndAdultTests: `Barn og Voksentester`,
	childrenAndYouthTests: `Barn og Ungdomtester`,
	childYouthAndAdultTests: `Barn, ungdom og voksentester`,
	choosegroup: `Velg gruppe`,
	choose: `Velg`,
	classname: `Klassenavn`,
	confirmpassword: `Bekreft Passord`,
	constTestChild: `Som Testadministrator så bekrefter du at du har nødvendig samtykke til å innhente personopplysninger og at disse brukes som beskrevet i Literates {0}`,
	continue: `Fortsett`,
	emailReason: `E-posten brukes til å sende ut pinkoden til pålogging`,
	expired: `Utløpt`,
	expiresIn: `Utløper om {0}`,
	feide: `Feide`,
	fillKandidateInformation1: `Testadministrator (lærer) fyller ut informasjon om kandidaten før eleven logger inn for å ta testen. Kandidaten kommer raskt i gang med testen og kan logge inn med pinkode`,
	fillKandidateInformationSelf1: `Dette gjør det mulig å sende ut testen direkte på e-post til kandidaten. Kandidaten må logge inn med Feide og fylle ut informasjon om seg selv (Navn, kjønn, fødselsdato og klassetrinn)`,
	fillKandidateInformationSelf2: `Dette passer typisk for elever på mellomtrinnet som har tilgang til både egen e-post og Feide,  og som skal ta leseprøven.`,
	fillOutInfo: `Fyll ut Informasjon`,
	fillSelfCandidateInfo: `Fyll ut informasjon om kandidaten selv`,
	followuptest: `Oppfølgingstest`,
	followuptests: `Oppfølgingstester`,
	fonemutelatelselowassesment: `{0} har utslag på Fonemutelatelse, og oppnår på denne testen en skåre som ligger {1} standardavviksenheter under gjennomsnittet for sitt trinn eller sin alder. Fonemutelatelse-testen måler fonembevissthet og fonologisk prosesseringshastighet. Svake ferdigheter på disse områdene er en kognitiv markør på dysleksi.`,
	fonemutelatelsetest: `Fonemutelatelse`,
	full: `Fullt`,
	fullAccessToMakeTests: `Har full tilgang til å lage nye tester og se testresultater`,
	goesToHighSchool: `Går på videregående skole`,
	gotoDysmtateSite: `Gå til dysmate.no`,
	grade: `trinn`,
	highestEducation: `Høyeste fullførte utdanning:`,
	homeLanugage: `Hjemmespråk`,
	info_report_belowpresentsinformation: `Nedenfor presenteres test rapport for {0}{1}.`,
	info_results_belowpresentsinformation: `Nedenfor presenteres testresultatene for {0}{1}.`,
	info_user_belowpresentsinformation: `Nedenfor presenteres kandidatopplysninger for {0}{1}.`,
	infor_scr_ageAtCommencement: `Alder ved testdato var {0} år og {1} måneder.`,
	letCandidateFillInfo: `La kandidaten fylle ut informasjon selv`,
	literatePrivacyPolicy: ``,
	message_invalidsubscription: `Du har ikke alle gyldige abonnementer som kreves for å lage disse tester`,
	myclasses: `Mine Klasser`,
	mytests: `Alle mine tester`,
	needsApprovalLongText: `Må godkjennes av administrator`,
	noAccessToMakeTests: `Har ikke tilgang til å lage nye tester eller se testresultater`,
	norming: `Normering`,
	normingtests: `Normeringstester`,
	numberofcandidates: `Antall Kandidater`,
	oneminutetestExperimental: `1-min test (talegjenkjenning)`,
	onlyReadAccess: `Har kun tilgang til å se testresultater`,
	onlyTrialAccess: `Har kun tilgang til totalt 8 tester`,
	orddiktatChildscrexplanation: `Det gis ett poeng for hvert riktig svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	otherCompetence: `Annen utdanning:`,
	othergroups: `Andre grupper`,
	password: `Passord`,
	passwrodchanged: `Passord endret`,
	printInformation: `Print opplysninger`,
	production: `Produksjon`,
	productionAndNorming: `Production & Norming`,
	productiontests: `Produksjonstester`,
	question_excludeReadingTest: `Vil du utelukke deltesten leseprøve? Denne kan ekskluderes for barn som ikke kan lese`,
	rapidautonamingtest: `Hurtigbenevning`,
	recommended: `anbefalt`,
	resetpswlinkfailed: `Vi kunne ikke sende en lenke fordi det finnes ikke en kontor knyttet med den epostadreesen`,
	resetpswlinksent: `Vi har sendt en ny lenke til dinepostadresse. Vennligst følge den ny linke for å tilbakestille en ny passord.`,
	selectedTestAddedToGroup: `Valgte {0} lagt til i gruppen`,
	selectedTestRemovedFromGroup: `Valgte {0} fjernet fra grupper`,
	singleChild: `Barn`,
	supportUrl: ``,
	testCandidateTakeTestWith: `Testkandidatene/elevene tartesten ved å`,
	testCommenceInstructions1: `Velge «ta testen» og velge «Screeningtest for barn»`,
	testCommenceInstructions2: `Skrive inn testnøkkelen`,
	testCommenceInstructions3: `Logge inn med Feide eller Pinkode.`,
	testCommenceInstructions4: `E-post er valgfritt. Hvis dette feltet er tomt må du selv fortelle testkandidaten/eleven pinkoden.`,
	testCommenceInstructions5: `Hvis du skriver inn en e-post adresse, så sendes både testnøkkelen og pinkode til e-postadressen`,
	testscompleted: `Tester Gjennomført`,
	theAverage: `gjennomsnittet`,
	undistributedTests: `Udistribuerte tester`,
	warning_candidatesfromdifferentcategori: `Noen av kandidatene har tatt tester tidligere som tilhørte en annen kategori. Fortsett uansett?`,
	workingmemorytestNumbers: `Arbeidsminne (Tall)`,
	workingmemorytestWords: `Arbeidsminne (Ord)`,
	youthAndAdultTests: `Ungdom og Voksentester`,
	readingTest_questioncount: 9,
};

import {ALL} from '../constants';

import {updateObject} from './reducerUtils';

export default function getFilteredTestResults(
	batchedTestResults,
	activeFilter
) {
	if (activeFilter === ALL) {
		return batchedTestResults;
	}

	return batchedTestResults.map((monthBatch) => {
		const testResults = monthBatch.testResults.filter(
			(testResult) => testResult.status === activeFilter
		);

		return updateObject(monthBatch, {
			testResults,
		});
	});
}

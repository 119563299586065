import React from 'react';
/* import './styles.scss'; */
import strings from '../../../utils/strings';
import _ from 'lodash';
import getTestAdminActivity from '../../../api/getTestAdminActivity';
import moment from 'moment';
import updateLogEvent from '../../../api/updateLogEvent';
import {Link} from 'react-router-dom';

import {
	USER_STAGES_INFO,
	ALL,
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	TRIAL,
	BASIC,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	ACTIVE,
	INACTIVE,
	TEST_TYPES,
	language,
} from '../../../constants';
const NEEDS_APPROVAL_INFO = USER_STAGES_INFO[NEEDS_APPROVAL];
const AWAITING_CONFIRMATION_INFO = USER_STAGES_INFO[AWAITING_CONFIRMATION];
const ACTIVE_INFO = USER_STAGES_INFO[ACTIVE];
const TRIAL_INFO = USER_STAGES_INFO[TRIAL];
const BASIC_INFO = USER_STAGES_INFO[BASIC];
const NORMING_INFO = USER_STAGES_INFO[NORMING];
const PRODUCTION_INFO = USER_STAGES_INFO[PRODUKSJON];
const PROD_NORMING_INFO = USER_STAGES_INFO[PROD_NORMING];

const INACTIVE_INFO = USER_STAGES_INFO[INACTIVE];

const userStageFilters = [
	ALL,
	NEEDS_APPROVAL_INFO,
	AWAITING_CONFIRMATION_INFO,
	TRIAL_INFO,
	BASIC_INFO,
	ACTIVE_INFO,
	NORMING_INFO,
	PRODUCTION_INFO,
	PROD_NORMING_INFO,
	INACTIVE_INFO,
];

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
class TestAdminActivity extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			/* allAdminUsers: this.props.testAdmins, */
			selectedUser: '',
			testAdminsData: [],
			filteredAdminsData: [],
			allData: null,
			searchFilter: '',
			activeFilter: ALL,
			selectedTestFilter: '',
			testCount: '',
			completedTestsCount: '',
		};
	}
	componentDidMount() {}
	getAndSetActivity = async (subscription) => {
		getTestAdminActivity(subscription).then((results) => {
			return new Promise((resolve) => {
				if (Boolean(results.length)) {
					const values = results.filter((data) => {
						if (data.isCompleted === 1) return data;
					});
					this.setState(
						{
							testAdminsData: results,
							filteredAdminsData: results,
							testCount: results.length,
							completedTestsCount: values.length,
						},
						resolve
					);
				} else {
					this.setState(
						{
							testAdminsData: [],
							filteredAdminsData: [],
							testCount: results.length,
							completedTestsCount: results.length,
						},
						resolve
					);
				}
			});
		});
	};
	renderButton(type, id, testKey) {
		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: `/single-test/${id}`;

		return (
			<div className="lit-rendered_seeTest">
				<Link
					className="lit-btn lit-btn--small bg-secondary"
					to={destination}
					onClick={() => this.handleClick(id, testKey)}
				>
					{strings.seeresults}
				</Link>
			</div>
		);
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}
	renderTableHeader() {
		return (
			<div className="lit-myClass-header_grid">
				<div className="lit-myClass-header-wrapper">
					<div className="myClass_table-header">
						{`${strings.firstname}, ${strings.lastname}`}
					</div>
					<div className="myClass_table-header">
						{strings.organization}
					</div>
					<div className="myClass_table-email-header">
						{strings.email}
					</div>
					<div className="myClass_averagescore_table-header">
						{strings.telephone}
					</div>
				</div>
			</div>
		);
	}
	renderTableRows(testAdmin) {
		testAdmin.expanded = testAdmin.expanded ? true : false;
		return (
			<div>
				<div
					className={
						testAdmin.expanded &&
						this.state.selectedUser === testAdmin.subscription.id
							? 'lit-activity_grid-active'
							: 'lit-activity_grid'
					}
					id={testAdmin.id}
					onClick={() => {
						if (!testAdmin.expanded) {
							testAdmin.expanded = true;

							this.setState({
								selectedUser: testAdmin.subscription.id,
								selectedTestFilter: '',
								/* allAdminUsers: this.props.testAdmins, */
							});
							this.getAndSetActivity(testAdmin.subscription.id);
						} else if (testAdmin.expanded) {
							testAdmin.expanded = false;

							this.setState({
								selectedUser: testAdmin.subscription.id,
								/* 	allAdminUsers: this.props.testAdmins, */
							});
						}
					}}
				>
					<div className="myClass_title_table-cell">
						{testAdmin.firstName &&
							`${testAdmin.firstName}, ${testAdmin.lastName}`}
					</div>
					<div className="myClass_table-organization-cell">
						{testAdmin.organization}
					</div>
					<div className="myClass_table-cell">{testAdmin.email}</div>
					<div className="myClass_averagescore_table-cell">
						{testAdmin.phone}
					</div>
					<div className="myClass_btn-cell">
						<div className="lit-list__text">
							<button className="lit-btn lit-btn--small bg-primary6">
								{testAdmin.expanded === true &&
								this.state.selectedUser ===
									testAdmin.subscription.id ? (
									<span>{'▲'}</span>
								) : (
									<span>{'▼'}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{testAdmin.expanded === true &&
					this.state.selectedUser === testAdmin.subscription.id &&
					this.renderSubTable()}
			</div>
		);
	}
	renderSubTable() {
		const stateData = _.cloneDeep(this.state.filteredAdminsData);
		return (
			<div className="lit-activity-table-container">
				<div className="lit-input-testType">
					{strings.type}
					<select
						className="lit-input__field3"
						defaultValue={strings.all}
						onChange={(e) => {
							if (e.target.value !== strings.all)
								this.setState({
									selectedTestFilter: e.target.value,
								});
							this.getFilteredTests(e.target.value);
						}}
					>
						<option key={0} value={strings.all}>
							{strings.all}
						</option>
						<option key={1} value={'child'}>
							{'Child'}
						</option>
						<option key={2} value={'youth-screening'}>
							{'Youth-screening'}
						</option>
						<option key={3} value={'adult-screening'}>
							{'Adult-screening'}
						</option>
						<option key={4} value={'youth-followup'}>
							{'Youth-followup'}
						</option>
					</select>
				</div>
				<div className="lit-subTable_identifiers">
					<div className="lit-input-testCount-heading">
						<label>{strings.used}</label>
					</div>
					<div className="lit-input-testCount-info">
						<label>{this.state.testCount}</label>
					</div>
					<div className="lit-input-testCount-heading">
						<label>{strings.completeplural} </label>
					</div>
					<div className="lit-input-testCount-info">
						<label>{this.state.completedTestsCount}</label>
					</div>
				</div>
				<div className="lit-individual-test">
					{Boolean(stateData.length) && (
						<table className="lit-list user-list">
							<thead>
								<tr>
									<th className="lit-test__header">
										{strings.testkey}
									</th>
									<th className="lit-test__header">
										{strings.type}
									</th>
									<th className="lit-test__header">
										{strings.completed}
									</th>
									<th className="lit-test__header">
										{strings.date}
									</th>
									<th className="lit-test__header">
										{strings.action}
									</th>
								</tr>
							</thead>
							<tbody>
								{stateData.map((candidate) => {
									const {
										id,
										testKey,
										isCompleted,
										type,
										completedAt,
										usedAt,
									} = candidate;
									return (
										<tr className="lit-list__item" key={id}>
											<td className="lit-list__col large">
												{testKey}
											</td>

											<td className="lit-list__col large">
												{type}
											</td>
											{isCompleted ? (
												<td className="lit-list__col">
													<div className="tickmark large">
														L
													</div>
												</td>
											) : (
												<td className="lit-list__col">
													<div className="crossmark large">
														X
													</div>
												</td>
											)}
											<td className="lit-list__col large">
												{completedAt
													? moment(
															completedAt
													  ).format('LL')
													: moment(usedAt).format(
															'LL'
													  )}
											</td>
											<td className="lit-list__col large">
												<div className="lit-activity__field">
													{this.renderButton(
														type,
														id,
														testKey
													)}
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
					{!Boolean(stateData.length) && (
						<p>
							{strings.formatString(
								strings.nousedtests,
								`${this.state.selectedTestFilter}`
							)}
						</p>
					)}
				</div>
			</div>
		);
	}
	getFiltereTypes = () => {
		const values = this.state.testAdminsData.filter((data) => {
			if (data.isCompleted) return data;
		});
		if (values.length) {
			this.setState({
				completedTestsCount: values.length,
			});
		} else {
			this.setState({
				completedTestsCount: 0,
			});
		}
	};
	getFilteredTests = (filter) => {
		if (filter !== 'Alle') {
			const values = this.state.testAdminsData.filter((data) => {
				if (data.type === filter) return data;
			});
			const completedCount = values.filter((data) => {
				if (data.isCompleted) return data;
			});
			if (values.length) {
				this.setState({
					filteredAdminsData: values,
					testCount: values.length,
					completedTestsCount: completedCount.length,
				});
			} else {
				this.setState({
					filteredAdminsData: [],
					testCount: values.length,
					completedTestsCount: completedCount.length,
				});
			}
		} else {
			const completedCount = this.state.testAdminsData.filter((data) => {
				if (data.isCompleted) return data;
			});
			this.setState({
				filteredAdminsData: this.state.testAdminsData,
				testCount: this.state.testAdminsData.length,
				completedTestsCount: completedCount.length,
			});
		}
	};
	getFilteredTestAdmins = () => {
		const {activeFilter} = this.state;
		const {testAdmins} = this.props;

		let filteredTestAdmins = this.props.selectedUsers.length
			? this.props.selectedUsers
			: testAdmins;

		if (activeFilter !== ALL) {
			filteredTestAdmins = testAdmins.filter(
				(testAdmin) => testAdmin.userStage === activeFilter.id
			);
		}

		filteredTestAdmins = filteredTestAdmins.filter((testAdmin) => {
			const searchableFields = [
				'firstName',
				'lastName',
				'phone',
				'email',
				'organization',
			];

			for (const field of searchableFields) {
				if (
					testAdmin[field] &&
					testAdmin[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});

		return filteredTestAdmins;
	};
	render() {
		const filteredTestAdmins = this.getFilteredTestAdmins();
		return (
			<div className="lit-tests-view__header">
				<div className="lit-new-test__wrapper">
					<div className="lit_input-filters-wrapper-activity">
						<div className="lit-input-testActivity_status">
							{strings.status}
							<select
								className="lit-input__field2"
								defaultValue={ALL}
								onChange={(e) => {
									this.setState({
										activeFilter: userStageFilters.filter(
											(filter) =>
												filter.id === e.target.value
										)[0],
									});
								}}
							>
								{userStageFilters.map((userStageFilter) => (
									<option
										key={userStageFilter.id}
										value={userStageFilter.id}
									>
										{userStageFilter.label ||
											userStageFilter.text}
									</option>
								))}
							</select>
						</div>
						<div className="lit-testActivity_searchUser">
							<input
								className="lit-input-userActivity_searchUser"
								type="text"
								placeholder={strings.searchhere}
								id="testkey"
								onChange={(e) => {
									this.setState({
										searchFilter: e.target.value.toLowerCase(),
									});
								}}
							/>
						</div>
					</div>
					<div className="lit-btn_grid">
						{!filteredTestAdmins.length && (
							<p>{strings.message_noclasses}</p>
						)}
						{Boolean(filteredTestAdmins.length) && (
							<div className="lit-main-candidates-contianer">
								<div className="lit_myClass_table-wrapper">
									{this.renderTableHeader()}
									<div className="lit-tableRows-scroll">
										{filteredTestAdmins.map(
											(data, index) => {
												return (
													<div key={index}>
														{this.renderTableRows(
															data
														)}
													</div>
												);
											}
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default TestAdminActivity;

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import getEventLog from '../../api/getEventLog';

import {} from '../../constants';

import './styles.scss';
import strings from '../../utils/strings';

class EventLog extends React.Component {
	constructor() {
		super();

		this.state = {
			events: [],
		};
	}

	componentDidMount() {
		getEventLog().then((events) => {
			this.setState({
				events,
			});
		});
	}

	render() {
		const {events} = this.state;

		return (
			<div className="lit-event-log">
				<h1>{strings.logg}</h1>
				<table>
					<tbody>
						{events.map((event, index) => (
							<tr className="lit-event-log__entry" key={index}>
								<td>{moment(event.time).format('LLL')}</td>
								<td>{event.eventName}</td>
								<td>{event.identity}</td>
								<td>{event.testKey}</td>
								<td>{event.IPaddress}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventLog);

import LocalizedStrings from 'react-localization';
import no from '../languages/no';
import en from '../languages/en';
import de from '../languages/de';
import se from '../languages/se';

const strings = new LocalizedStrings({
	no: no,
	en: en,
	de: de,
	se: se,
});

export default strings;

import {TRIAL} from '../constants';

export const isTrialActive = (user) => {
	const {userStage, subscription} = user;
	if (userStage === TRIAL) {
		if (
			new Date(subscription.trialValidUntil).valueOf() >
			new Date().valueOf()
		) {
			return true;
		}
	}
	return false;
};

export default isTrialActive;

/* eslint-disable camelcase */

export default {
	mytest: `Mina tester`,
	myCandidates: `Mina Kandidater`,
	createnewtest: `Skapa nytt test`,
	started: `Påbörjat`,
	starttest: `Starta Test`,
	all: `Alla`,
	chooseagroup: `Välj en grupp`,
	distributed: `Distribuerad`,
	notdistributed: `Inte distribuerad`,
	notstarted: `Inte startad`,
	completed: `Genomförd`,
	completeplural: `Genomförda:`,
	seeresults: `Se resultat`,
	status: `Status`,
	testkey: `Testnyckel`,
	type: `Typ`,
	recipient: `Mottagare`,
	actions: `Åtgärder`,
	send: `Skicka`,
	save: `Spara`,
	sendcodeemail: `Skicka kod via email`,
	january: `januari`,
	february: `februari`,
	march: `mars`,
	april: `april`,
	may: `maj`,
	june: `juni`,
	juli: `juli`,
	august: `augusti`,
	september: `september`,
	october: `oktober`,
	november: `november`,
	december: `december`,
	norway: `Norge`,
	sweden: `Sverige`,
	germany: `Tyskland`,
	england: `Storbritannien`,
	nederland: `Nederländerna`,
	message_testcannotbedeleted: `Testet måste vara minst 3 månader gammalt för att raderas.`,
	warning_deletetest: `Testet kommer inte längre vara tillgängligt för bedömning. Vill du fortsätta?`,
	headerchildtest: `Testresultat för Dysmate – C (Barntest)`,
	dynamicheaderfortestresult_child: `Detaljerade resultat för Dysmate {0}`,
	dynamicheaderfortestreport_child: `Detaljerad rapport för Dysmate {0}`,
	dynamicheaderfortestcandidate_child: `Detaljerad information för Dysmatekandidat {0}`,
	dynamicheaderfortestresult_adult: `Detaljerade resultat för Dysmate {0}`,
	dynamicheaderfortestreport_adult: `Detaljerad rapport för Dysmate {0}`,
	dynamicheaderfortestcandidate_adult: `Detaljerad information för Dysmatekandidat {0}`,
	headeradulttest: `Resultat för Dysmate A (Screeningtest för vuxna)`,
	showbackground: `Visa kandidats bakgrundsinformation`,
	incompleteMessage: `Testet är inte genomfört. Delprov saknas eller har ej påbörjats.`,
	summary: `Sammanfattning`,
	printtestresults: `Skriv ut resultat`,
	zscorered: `Z-score är mer än 1 standardavvikelse {0} genomsnittet`,
	zscoregreen: `Z-score är mer än 1 standardavvikelse {0} genomsnittet`,
	zscoreblue: `Z-score är inom genomsnittsområdet`,
	overview: `Överblick`,
	assessment: `Bedömning:`,
	assessmentspeechok: `{0} har uppnått resultat inom normalområdet eller bättre för det experimentella enminutstestet och verkar ha goda läsförmågor.`,
	assessmentspeechnotok: `{0} har uppnått resultat under normalområdet för det experimentella enminutstestet och bör genomgå vidare utredning för dyslexi och/eller läsförståelsesvårigheter`,
	asessmenttextok_1: `{0} har uppnått resultat inom normalområdet eller bättre för {1} av delproven och verkar ha god läs- och skrivförmåga`,
	asessmenttextnotok_1: `{0} har uppnått resultat under normalområdet för {1} av de {2} delproven, och bör genomgå vidare utredning för dyslexi och/eller läsförståelsesvårigheter`,
	assesmenttextok_youthfollowup: `{0} har inga utslag på typiska kognitiva markörer för dyslexi.`,
	assesmentok_recommendation_youthfollowup: `Om {0} fortfarande har uppnått resultat under det normala området för Läsförståelse och/eller Ordförståelse, eller andra delar av screeningtestet, rekommenderas ytterligare bedömning av läsförståelsebrister och/eller störning av tal- och språkutveckling (DLD).`,
	workingmemorylowassesment: `{0} har utslag på Arbetsminne och uppnår ett resultat som är {1} standardavvikelseenheter under genomsnittet för sin klass eller ålder på detta test. Arbetsminnet är inte i sig självt en kognitiv markör för dyslexi, men är förknippat med både dyslexi och bristande läsförståelse. Ett nedsatt arbetsminne kan bland annat påverka hur mycket information man lyckas få ut av en läst text. Det rekommenderas därför att undersöka i vilken utsträckning arbetsminnet hos {2} påverkar inhämtandet av nytt stoff och om det finns ett behov av studiehandledning kring detta.`,
	rapidnominationlowassesment: `{0} har utslag på Snabb Namngivning (RAN) och uppnår ett resultat som är {1} standardavvikelseenheter under genomsnittet för sin klass eller ålder på detta test. Snabb namngivning mäter hur snabbt och exakt du lyckas hämta ord från språkminnet. Svaga färdigheter inom detta område är en kognitiv markör för dyslexi.`,
	oneminutelowassesment: `{0} har utslag på enminutstestet och uppnår ett resultat som är {1} standardavvikelseenheter under genomsnittet för sin klass eller ålder på detta test. Enminutstestet ger ett tydligt mått på antalet ord som läses korrekt per minut och ger en bra bild av avkodningsförmågan. Svag avkodningsförmåga är en markör för dyslexi.`,
	oneminutelowassesment2: `NB! Det rekommenderas att utvärdera resultaten av enminutstestet i samband med resultaten av dikteringstestet och ordkedjetestet i Dysmate Screening Test.`,
	spoonerismlowassesment: `{0} har indikation för Spoonerism-testet och uppnår på detta test ett resultat som är {1} standardavvikelseenheter under genomsnittet för sin klass eller ålder. Spoonerism-testet mäter fonemkännedom och fonologisk bearbetningshastighet. Svaga färdigheter inom dessa områden är en kognitiv markör för dyslexi.`,
	rawscore: `Rå poäng`,
	zscore: `Z-poäng`,
	percentilescore: `Percentilpoäng`,
	tscore: `T-poäng`,
	scaledscore: `Skalad poäng`,
	normalrange: `Normalområde`,
	assessmentsubtestnormal: `Detta resultat ligger inom normalområdet`,
	assessmentsubtestbelow: `Detta resultat ligger {0} standardavvikelser {1} genomsnittet`,
	under: `under`,
	assessmentsubtestabove: `Detta resultat ligger {0} standardavvikelser {1} genomsnittet`,
	over: `över`,
	workingmemorytest: `Arbetsminne`,
	spoonerismtest: `Spoonerism`,
	oneminutetest: `Enminutstest`,
	letterknowledgetest: `Bokstavskunskap`,
	phonemisolationtest: `Fonemisolation`,
	worddictationtest: `Diktion`,
	phonemeomissiontest: `Fonemutelämnande`,
	wordchaintest: `Ordkedjetest`,
	nonwordtest: `Låtsasordstest`,
	typingspeedtest: `Skrivhastighetstest`,
	readingtest: `Läsförståelse`,
	wordcomprehensiontest: `Ordförståelse`,
	loggin: `Logga in`,
	loggout: `Logga ut`,
	trialperiod: `Provperiod`,
	trialuser: `Provanvändare`,
	basicsupcription: `Grundläggande prenumeration`,
	fullsupscription: `Full prenumeration`,
	childsubscription: `Dysmate-C - prenumeration`,
	adultsubscription: `Dysmate-A - prenumeration`,
	youthsubscription: `Dysmate-Y - prenumeration`,
	name: `Namn:`,
	firstname: `Förnamn`,
	lastname: `Efternamn`,
	dateofbirth: `Födelsedatum:`,
	birthDate: `Födelsedatum`,
	paneMessageContinueTest: `Detta kommer skicka ut en ny länk till testet så att kandidaterna kan fortsätta ett ofullständig test. Fortsätt?`,
	telephone: `Telefon`,
	email: `Email`,
	edit: `Redigera`,
	edituser: `Redigera användare`,
	seesubscription: `Se prenumeration`,
	adulttests: `Dysmate-A (Vuxentest)`,
	youthtests: `Dysmate-Y (Ungdomstest)`,
	confirmationofcost: `Prisbekräftelse`,
	sex: `Kön`,
	male: `Man`,
	female: `Kvinna`,
	education: `Utbildning`,
	cancel: `Avbryt`,
	confirm: `Bekräfta`,
	age: `Ålder`,
	age_specified: `Ålder (år)`,
	testdate: `Testdatum`,
	childtest: `Dysmate-C (Barntest)`,
	experimentalchildtest: `Experimentellt barntest`,
	youthtest: `Dysmate-Y (Screening)`,
	adulttest: `Dysmate-A (Screening)`,
	adultscreening: `Dysmate A – (Screeningtest för vuxna)`,
	youthscreening: `Dysmate-Y – (Screeningtest for ungdomar)`,
	childtests: `Dysmate-C (barntest)`,
	nousedtests: `Ingen använde {0} test`,
	used: `Använda:`,
	boy: `Pojke`,
	girl: `Flicka`,
	logg: `Händelselogg`,
	of: `av {0}`,
	wordsperminute: `ord per minut`,
	deleteoldtests: `Radera gamla test`,
	addtesttogroup: `Lägg till test till en grupp`,
	message_incompletData: `Ogiltig eller ofullständig uppgift`,
	newclasscreated: `Ny klass skapad`,
	testcandidateremoved: `Testkandidaten har raderats från klasslistan`,
	testcandidateadded: `Testkandidaten har lagts till I klasslistan`,
	classexistchoosename: `Denna klass finns redan, vänligen välj ett annat namn.`,
	class: `.klass`,
	subscription: `Prenumeration`,
	supersubscription: `Har prenumeration`,
	mysubscription: `Min prenumeration`,
	seetests: `Se test`,
	currencysymbol: `SEK`,
	hideWithArrow: `Dölj ▲`,
	yourtrialperiodhasexpired: `Din provperiod har tagit slut`,
	upgradeyoursubscription: `Uppgradera din prenumeration`,
	downloadandsendcontract: `För att utfärda fler test måste du ha en prenumeration. Gå till “Min sida” på webbplatsen för att teckna abonnemang, eller ladda ner kontraktet och skicka det till`,
	priceandconditioninfo: `. Prisinformation och köpevillkor finns på`,
	downloadcontract: `Ladda ner kontrakt`,
	onlytwotestspertrialperiod: `Endast 2 test är tillgängliga under provperioden`,
	upgradetofullsubscription: `uppgradera till full prenumeration för att utfärda nya test.`,
	fetch: `Återställ`,
	language: `Språk`,
	child: `Barn`,
	youth: `Ungdom`,
	adult: `Vuxen`,
	youthfollowup: `Dysmate-Y II (Uppföljning ungdom)`,
	youthfollowuptest: `Dysmate-Y II (Uppföljningstest ungdom)`,
	adultfollowup: `Dysmate-A II (Uppföljning vuxen)`,
	adultfollowuptest: `Dysmate-A II (Uppföljningstest vuxen)`,
	childfollowuptest: `Dysmate-C II (Uppföljning barn)`,
	childnorming: `Normering barn`,
	youthnorming: `Normering ungdom`,
	adultnorming: `Normering vuxen`,
	deletedon: `Raderade`,
	basic: `Grundläggande`,
	deletedtests: `Raderade test`,
	viewsubscription: `Se prenumeration ▼`,
	viewdeleted: `Raderade test ▼`,
	referalinformation: `personuppgifter`,
	referalgroups: `gruppmedlemskap`,
	mygroups: `Mina grupper`,
	myinformation: `Mina personuppgifter`,
	message_costsforeachtestthatis_started: `Observera att det, beroende på din prenumeration, kan finnas kostnader för att utfärda nya tester. Tester som inte används kommer inte att debiteras.`,
	test_type: `Testtyp`,
	screeningtest_adult: `Dysmate-A (screeningtest vuxen)`,
	numberoftests: `Antal test (max 30)`,
	create_tests: `Skapa nya test`,
	login: `Logga in`,
	loginwithbankid: `Logga in med följande metoder`,
	message_thisisforcertifieduser: `Detta är för certifierade testadministratörer med prenumeration.`,
	bankid: `BankID`,
	bankidonmobile: `Mobilt BankID`,
	edittestadministrator: `Ändra testadministratör`,
	personalinformation: `Personuppgifter`,
	shcool_or_company: `Skola/företag`,
	addanewtestadministrator: `Lägg till ny testadministratör`,
	addtestadministrator: `Lägg till testadministratör`,
	saveuserinformation: `Spara information om testkandidaten`,
	delete: `Radera`,
	deleteuser: `Radera användare`,
	currentstatus: `Nuvarande status:`,
	savesubscription: `Spara prenumeration`,
	organization: `Organisation`,
	newloginmethod: `Ny inloggningsmetod`,
	errorhasoccured: `Ett fel har inträffat`,
	message_singleuser_perks_trial: `Med en användare kan du få en gratis provperiod på 30 dagar och 2 gratis test av varje typ`,
	message_change_loginmethod: `Du är på väg att ändra din inloggningsmetod. Du kommer fortsätta behöva logga in med denna metod.`,
	info_for_professional_with_either_completed_screening_test: `Detta är för yrkesverksamma som slutfört eller är anmälda till en certifieringskurs och som vill prova några tester innan de tecknar abonnemang.`,
	info_prerequisites_to_obtain_license: `För att få tillgång till provperioden måste du anmäla dig till eller ha slutfört dysmate-certifieringskursen. Alla användare kommer att godkännas manuellt av Dysmate, varefter de kommer att få instruktioner i ett e-postmeddelande för att slutföra sin registrering för att få tillgång.`,
	removefiltering: `Ta bort filter`,
	printreport: `Skriv ut testrapport`,
	hide: `Dölj`,
	show: `Visa`,
	userinformation: `Kandidatinformation`,
	localizedwriteuptextforlowresults: `{0} har uppnått resultat som är under normalområdet för {1} av de {2} delproven, och bör genomgå vidare utredning för dyslexi och/eller läsförståelsesvårigheter`,
	localizedwriteuptextforNormalresults: `{0} har uppnått resultat som är inom normalområdet eller över för {1} av delproven och verkar ha god läs- och skrivförmåga.`,
	testreultsforliteratescreeningtest: `Testresultat för Dysmate screeningtest`,
	info_belowpresentsinformation: `Testresultaten för {0}, ålder {1} år och {2} månad presenteras nedan.`,
	newinfo_belowpresentsinformation: `Testresultaten för {0}, född {1}, (Ålder: {2} år eller {3} månader) presenteras nedan.`,
	newinfo2_belowpresentsinformation: `Testrapport för {0}, född {1}, (Ålder: {2} år eller {3} månader) presenteras nedan.`,
	newinfo3_belowpresentsinformation: `Kandidatinformation för {0}, född {1}, (Ålder: {2} år eller {3} månader) presenteras nedan.`,
	speech1_belowpresentsinformation: `Testresultat för {0}, (Ålder: {1}) presenteras nedan.`,
	speech2_belowpresentsinformation: `Testrapport för {0}, (Ålder: {1}) presenteras nedan.`,
	speech3_belowpresentsinformation: `Kandidatinformation för {0}, (Ålder: {1} år) presenteras nedan.`,
	info_testwascompletedon: `Testet slutfördes den {0}`,
	info_testwasNotcompletedon: `Detta test är ej slutfört.`,
	notetsfound: `Inga slutförda test hittades`,
	background_info_testcandidate: `bakgrundsinformation för testkandidaten`,
	candidate_answers: `Svar från kandidaten`,
	selectLanguage: `Välj språk`,
	norwegian: `Norska`,
	german: `Tyska`,
	dutch: `Nederländska`,
	english: `Engelska`,
	swedish: `Svenska`,
	zscore: `Z-poäng`,
	usersupport: `Användarstöd`,
	groups: `Grupper`,
	groupname: `Gruppnamn`,
	date: `Datum`,
	insert: `Infoga`,
	selected_users: `Välj användare`,
	alreadyexistsingroup: `finns redan I gruppen!`,
	orddiktatheading: `Detta test mäter stavningsförmåga och ortografisk medvetenhet. Här skriver testkandidaten ord som läses högt. Varje ord presenteras först i en mening för att försäkra att den korrekta meningen av ordet förstås, och så repeteras målordet, varefter testkandidaten skriver det. Det finns ingen tidsbegränsning på detta test, och varje mening och målord kan repeteras en gång.`,
	orddiktatchildexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 13 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2} .`,
	orddiktatvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från detta stegs medelvärde. Detta resultat placeras`,
	orddiktatadultexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 45 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2} .`,
	orddiktatyouthexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 30 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2} .`,
	fonemisolasjonheading: `Detta test identifierar fonemmedvetenhet hos de yngsta testkandidaterna. I detta test visas fyra bilder för testkandidaten och hen ombeds peka på den bild som antingen börjar eller slutar med ett visst ljud.`,
	fonemisolasjonexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 16 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	fonemisolasjonvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från detta stegs medelvärde. Detta resultat placeras`,
	fonemutelatelseheading: `Detta test identifierar fonemmedvetenhet hos elever I grundskolan. I detta test presenteras ord verbalt för testkandidaten och denne ombeds att säga det ord som återstår när ett visst ljud tas bort (t.ex. / b / i bröd => röd)`,
	fonemutelatelseexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 16 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	fonemutelatelsevariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från detta stegs medelvärde. Detta resultat placeras`,
	spoonerismheading: `Detta test identifierar fonemmedvetenhet. I detta test presenteras 20 ordpar verbalt för testkandidaten och denne ombeds byta de första ljuden så snabbt och korrekt som möjligt.`,
	spoonerismexplanation: `Ett poäng tilldelas för varje korrekt ord kandidaten lyckas säga med det nya första ljudet (maximalt 40 poäng). Testet är också tidsbestämt. Poängen är antalet korrekt identifierade nya ord, delat med tiden det tog att slutföra uppgifterna. Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	spoonerismvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	ettminuttstestheading: `Detta är ett högläsningstest som identifierar avkodningsförmågor. Här presenteras en ordlista för testkandidaten och denne ombeds läsa så snabbt och korrekt som möjligt inom en tidsram på 60 sekunder. Resultatet här ger ett mått på antalet korrekt lästa ord per minut`,
	ettminuttstestexplanation: `Ett poäng tilldelas för varje korrekt läst ord. Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	ettminuttstestvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	hurtigbenevningheading: `Detta test identifierar hur snabbt testkandidaten klarar av att uppge namnet på kända föremål och hämta föremålsnamn och färger från det lingvistiska långtidsminnet.`,
	hurtigbenevningexplanation: `Ett poäng tilldelas för varje korrekt kombination av färg och objekt som kandidaten säger. Testet är tidsbestämt. Poängen här är antalet korrekta färg-föremålskombinationer delat med den sammanlagda tiden det tog att namnge samtliga 50 färg-föremålskombinationer. Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	hurtigbenevningvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	arbeidsminnenummberheading: `Detta test identifierar testkandidatens förmåga att lagra och bearbeta information. I detta test presenteras för testkandidaten en serie siffror, sedan en serie ord, vilka ska upprepas i motsatt ordning. Både sifferraderna och ordraderna ökar I längd om testkandidaten klarar den föregående raden.`,
	arbeidsminneexplanation: `Ett poäng tillskrivs för varje korrekt svar (maximalt 12 poäng för sifferraderna och maximalt 12 poäng för ordraderna, för en sammanlagt högsta poängsumma på 24). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	arbeidsminnevariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	arbeidsminneheading: `Detta test identifierar testkandidatens förmåga att lagra och bearbeta information. I detta test presenteras för testkandidaten en serie siffror, sedan en serie ord, vilka ska upprepas i motsatt ordning. Både sifferraderna och ordraderna ökar I längd om testkandidaten klarar den föregående raden.`,
	ordforståelseheading: `Detta test mäter ordförrådet genom att testkandidaten måste hitta ett ord som har samma betydelse som ett givet målord. För varje fråga måste testkandidaten välja mellan fyra svarsalternativ. Detta test ger ett mått på bredden och djupet av testkandidatens ordförråd.`,
	ordforståelseexplanation: `Ett poäng tillskrivs för varje korrekt svar (maximalt 15 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	ordforståelsevariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	ordforståelseyouthexplanation: `Ett poäng tilldelas varje korrekt svar (maximalt 20 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	leseprøvenheading: `Detta test är ett tyst läsprov som mäter läsförståelse genom att testkandidaten måste svara på frågor direkt från texten, sammanställa information från olika avsnitt och dra slutsatser. Testkandidaten har 10 minuter på sig att läsa texten och svara på så många flervalsfrågor som möjligt. N.B. Här är det viktigt att registrera om testkandidaten har svarat fel eller lämnat flervalsfrågorna obesvarade (på grund av lågläshastighet).`,
	leseprøvenyouthheading: `Detta test är ett tyst lästest som mäter läsförståelse genom att testkandidaten måste svara på frågor direkt från texten, sammanställa information från olika avsnitt och dra slutsatser. Testkandidaten har 12 minuter på sig att läsa texten och svara på så många flervalsfrågor som möjligt. N.B. Här är det viktigt att registrera om testkandidaten har svarat fel eller lämnat flervalsfrågorna obesvarade (på grund av lågläshastighet).`,
	leseprøvenexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 14 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}`,
	leseprøvenvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	leseprøvenyouthexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 9 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	skrivehastighetheading: `Detta test mäter effektiviteten I skrivandet. Det är en förmåga som behövs när man till exempel för anteckningar eller skriver ett prov. Testkandidaten har en mening synlig framför sig under hela testet och har I uppgift att skriva av meningen så många gånger och så korrekt som möjligt inom 2 minuter.`,
	skrivehastighetexplanation: `Poängen här är antalet korrekt skrivna ord I genomsnitt per minut. Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	skrivehastighetvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	tulleordtestenheading: `Detta test är ett tyst lästest som mäter fonologisk medvetenhet och avkodningsfärdigheter. Testet är byggt på att testkandidaten identifierar nonsensord som uttalas som riktiga ord och skiljer dem från nonsensord som inte uttalas som riktiga ord. För varje fråga måste provdeltagaren välja mellan fem svarsalternativ, av vilka endast ett är korrekt. Testkandidaten har två minuter på sig att lösa så många uppgifter som möjligt.`,
	tulleordtestenexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 25 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	tulleordtestenvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	ordkjedetestenheading: `Detta test är ett tyst lästest som mäter snabb ordigenkänning och ortografisk kunskap. Här ombeds testkandidaten att göra mellanrum mellan ord som skrivs ihop till en kedja. Varje kedja består av fyra slumpmässiga ord. Testkandidaten har fyra minuter på sig att lösa så många ordkedjor som möjligt.`,
	ordkjedetestenexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 40 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	ordkjedetestenvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från årskursens medelvärde. Detta resultat placeras`,
	ordkjedetestenyouthexplanation: `Ett poäng tillskrivs för varje korrekt svar (maximalt 36 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	chooseagroup: `Välj en grupp`,
	includeLongIntro: `Inkludera självbedömning / anamnese`,
	anamneseexplaination: `Om testet enbart används för screening är det inte nödvändigt att inkludera självbedömning / anamnese`,
	chooselanguage: `Välj språk`,
	seegrouptests: `Se tester i grupper`,
	addtogroup: `Lägg till i gruppen`,
	nogroupsfound: `Inga grupper funna`,
	fromgroups: `Från grupp`,
	allmytests: `Alla mina tester`,
	mytests: `Mina tester`,
	groupindicator: `-----Grupper-----`,
	notanswered: `ej besvarad`,
	testnotanswered: `{0} frågor ej besvarade`,
	showunanswered: `Visa obesvarade`,
	hideunanswered: `Dölj obesvarade`,
	rightanswer: `Korrekt besvarade: {0}`,
	action: `Åtgärd`,
	deleteall: `Radera alla`,
	chooseclass: `Välj klass`,
	confirmationdeleteclass: `Är du säker på att du vill radera detta {0}`,
	theclass: `Klass`,
	multipleclasses: `klasser`,
	myclasses: `Mina klasser`,
	deleteclass: `Radera klass`,
	message_noclasses: `Du har inte skapat några klasser`,
	showtests: `Visa tester ▼`,
	allmycandidates: `Alla mina kandidater`,
	notestsforcandidate: `Denna kandidat har inte slutfört några tester`,
	gender: `Kön`,
	nocandidates: `du har ingen kandidat`,
	choosecandidate: `Välj en kandidat`,
	classindicator: `-----Klasser-----`,
	sendtest: `Skicka ut test`,
	createclass: `Skapa Klass`,
	removefromclass: `Ta bort från klassen`,
	providevalidname: `Ange ett giltigt namn`,
	searchhere: `Sök här...`,
	newclass: `Ny Klass`,
	writeclassname: `Ge klassen ett namn…`,
	deleting: `Raderar`,
	mypage: `Min sida`,
	notavailable: `ej tillgänglig`,
	theagegroup: `åldersgrupp`,
	hiddenname: `<Dolt namn>`,
	wordreadcorrectly: `Korrekt lästa ord`,
	correct: `Korrekt`,
	wrong: `Fel`,
	timeused: `Tid använd`,
	thisagegroup: `åldersgrupp`,
	adminpersonalinformation: `Personuppgifter`,
	readingTest_questioncount: 9,
};

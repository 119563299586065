import {updateObject} from './reducerUtils';
import moment from 'moment';
import strings from './strings';

export const getBestTestResultMoment = (testResult) => {
	const {createdAt, distributedAt, usedAt, completedAt} = testResult;
	if (completedAt) {
		return completedAt;
	}

	if (usedAt) {
		return usedAt;
	}

	if (distributedAt) {
		return distributedAt;
	}

	if (createdAt) {
		return createdAt;
	}

	throw new Error(`No valid moment for testResult with id ${testResult.id}`);
};

const compareMoments = (currentValue, compareValue) => {
	const currentMoment = getBestTestResultMoment(currentValue);
	const compareMoment = getBestTestResultMoment(compareValue);

	if (currentMoment.isAfter(compareMoment)) {
		return -1;
	} else if (currentMoment.isBefore(compareMoment)) {
		return 1;
	}
	return 0;
};

const parseValuesAndCompare = (
	[currentKey, currentValue],
	[compareKey, compareValue]
) => {
	if (currentKey === 'invalid' || compareKey === 'undistributed') {
		return 1;
	}
	if (compareKey === 'invalid' || currentKey === 'undistributed') {
		return -1;
	}

	if (currentValue.date.isAfter(compareValue.date)) {
		return -1;
	} else if (currentValue.date.isBefore(compareValue.date)) {
		return 1;
	}
	return 0;
};

export default function getBatchedTestResults(testResults) {
	const monthBatches = {};

	testResults.forEach((testResult) => {
		const testResultMoment = getBestTestResultMoment(testResult);
		let name;
		let monthBatchKey;
		if (testResult.status && testResult.status.id === 'not_started') {
			monthBatchKey = 'undistributed';
			name = strings.undistributedTests;
		} else {
			monthBatchKey = `${testResultMoment.year()}${testResultMoment.month()}`;

			name = testResultMoment.format('MMMM YYYY');
			if (testResultMoment.isAfter(moment().subtract(0.5, 'second'))) {
				monthBatchKey = 'invalid';
				name = 'Invalid date';
			}
		}

		if (!monthBatches.hasOwnProperty(monthBatchKey)) {
			monthBatches[monthBatchKey] = {
				key: monthBatchKey,
				name: name,
				date: testResultMoment,
				testResults: [],
			};
		}

		monthBatches[monthBatchKey].testResults.push(testResult);
	});

	const sortedMonthBatches = Object.entries(monthBatches)
		.sort(parseValuesAndCompare)
		.map(([monthBatchKey, monthBatchValue]) => {
			const monthBatch = monthBatches[monthBatchKey];

			const testResultsCopy = monthBatch.testResults.slice();
			const sortedTestResults = testResultsCopy.sort(compareMoments);

			return updateObject(monthBatch, {
				testResults: sortedTestResults,
			});
		});

	return sortedMonthBatches;
}

import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import NewClassModal from './NewClassModal';
import NewCandidateModal from './NewCandidateModal';
import TestDispatchModal from './TestDispatchModal';

import strings from '../../../utils/strings';
import create from '../../../assets/create-24px.svg';
import updateLogEvent from '../../../api/updateLogEvent';

import {
	COMPLETED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
} from '../../../constants';

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
const defaultSelectedTestCandidates = [];
const allMyCandidates = strings.allmycandidates;
let sortOrder = true;
class MyCandidates extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMainChecked: false,
			singleChecks: false,
			selectedTestCandidates: defaultSelectedTestCandidates,
			searchFilter: '',
			isCreatingClass: false,
			individualCandidates: [],
			showIndividualTests: false,
			isCreatingCandidate: false,
			isSendingTest: false,
			selectedIndividual: null,
			isClassPanelActive: false,
			newClassName: '',
			selectedClass: '',
			selectedClassId: null,
			isEditingClass: false,
			allCandidates: this.props.testUsers,
			isRenamingClass: false,
		};
		this.handleClick = this.handleClick.bind(this);
	}
	componentDidMount() {
		this.setState({selectedClass: strings.allmycandidates});
	}

	async excludeMembersIfExists(
		value,
		classMembers,
		selectedTestUsers,
		currentUser
	) {
		const {subscription} = currentUser;

		const selectedTestUserIds = selectedTestUsers.map((TestUser) => {
			return {
				testUser: TestUser.id,
				firstName: TestUser.firstName,
				lastName: TestUser.lastName,
			};
		});
		var getrelevantData = classMembers.filter(
			(userClass) => userClass.className === value
		);

		const candidates = getrelevantData[0].classInfo;
		let flag = null;
		let currentClassId = null;
		const temObj = [];
		const permObj = [];
		for (let i = 0; i < selectedTestUserIds.length; i++) {
			flag = 0;
			for (let j = 0; j < candidates.length; j++) {
				if (
					selectedTestUserIds[i].testUser ===
					candidates[j].candidateId
				) {
					flag = 1;
					const matched = {
						Name:
							selectedTestUserIds[i].firstName +
							' ' +
							selectedTestUserIds[i].lastName,
					};

					temObj.push(matched);
				} else {
					currentClassId = candidates[j].classId;
				}
			}
			if (flag !== 1) {
				const unMatched = {
					testUser: selectedTestUserIds[i].testUser,
					class: currentClassId,
					subscription: subscription.id,
					adminid: currentUser.id,
				};
				permObj.push(unMatched);
			}
		}
		this.setState({
			exclusiveMembers: permObj,
			targetClass: currentClassId,
			inclusiveMember: temObj,
		});
	}

	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}
	handleSingleCheck(target, testUser, filteredTestUsers) {
		if (target.checked) {
			testUser.checked = true;
			this.setState({
				selectedTestCandidates: filteredTestUsers,
				allCandidates: filteredTestUsers,
			});
		} else {
			testUser.checked = false;
			this.setState({
				selectedTestCandidates: filteredTestUsers,
				allCandidates: filteredTestUsers,
			});
		}
	}
	determineGender(currentTestUser, allTestUsers) {
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId ===
							currentTestUser &&
						testsResults[j].info
					) {
						const information = JSON.parse(testsResults[j].info);
						if (testsResults[j].type !== 'child') {
							if (information.gender === 'm') {
								return (
									<div className="custom_table-cell">
										{strings.male}
									</div>
								);
							} else if (information.gender === 'f') {
								return (
									<div className="custom_table-cell">
										{strings.female}
									</div>
								);
							}
						} else if (information.gender === 'm') {
							return (
								<div className="custom_table-cell">
									{strings.boy}
								</div>
							);
						} else if (information.gender === 'f') {
							return (
								<div className="custom_table-cell">
									{strings.girl}
								</div>
							);
						}
					}
				}
			}
		}

		return <div className="custom_table-cell">{'---'}</div>;
	}
	getFilteredTestUsers(currentTestUser, allTestUsers) {
		const tempObj = [];
		for (let i = 0; i < allTestUsers.length; i++) {
			const testsResults = allTestUsers[i].testResults;
			for (let j = 0; j < testsResults.length; j++) {
				if (testsResults[j].testUser) {
					if (
						testsResults[j].testUser.testUserId === currentTestUser
					) {
						tempObj.push(testsResults[j]);
					}
				}
			}
		}
		if (tempObj.length) {
			return tempObj;
		}
		return null;
	}
	sortTableBy(identifier) {
		const {allCandidates} = this.state;
		sortOrder = !sortOrder;

		switch (identifier) {
			case 'firstname':
				allCandidates.sort(this.compareByfirstName);
				break;
			case 'lastname':
				allCandidates.sort(this.compareByLastName);
				break;

			case 'date':
				allCandidates.sort(this.compareByDate);
				break;
		}
		this.setState({allCandidates});
	}
	compareByfirstName(a, b) {
		if (!sortOrder) {
			if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
				return 1;
			}
			if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
				return -1;
			}
			return 0;
		} else {
			if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
				return 1;
			}
			if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
				return -1;
			}
			return 0;
		}
	}
	compareByLastName(a, b) {
		if (!sortOrder) {
			if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
				return 1;
			}
			if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
				return -1;
			}
			return 0;
		} else {
			if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
				return 1;
			}
			if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
				return -1;
			}
			return 0;
		}
	}
	compareByDate(a, b) {
		if (!sortOrder) {
			if (a.createdAt < b.createdAt) {
				return 1;
			}
			if (a.createdAt > b.createdAt) {
				return -1;
			}
			return 0;
		} else {
			if (a.createdAt > b.createdAt) {
				return 1;
			}
			if (a.createdAt < b.createdAt) {
				return -1;
			}
			return 0;
		}
	}
	renderCustomTableHeader(filteredTestUsers) {
		return (
			<div className="lit-custom-header_grid">
				<div className="lit-custom-header-wrapper">
					<div className="custom_checkBox-cell">
						<input
							type="checkbox"
							className="user_checkbox2"
							defaultChecked={this.state.isMainChecked}
							id="main_checkbox"
							onChange={(e) => {
								if (e.target.checked) {
									const checkedClassData = filteredTestUsers.map(
										(data) => {
											data.checked = true;
											return data;
										}
									);

									this.setState({
										isMainChecked: true,
										selectedTestCandidates: checkedClassData,
									});
								} else {
									const checkedClassData = filteredTestUsers.map(
										(data) => {
											data.checked = false;
											return data;
										}
									);

									this.setState({
										isMainChecked: false,

										selectedTestCandidates: checkedClassData,
									});
								}
							}}
						/>
					</div>
					<div className="custom_table-header-cell">
						{' '}
						<span
							className="className-title"
							onClick={() => {
								this.sortTableBy('firstname');
							}}
						>
							{' '}
							{strings.firstname}
						</span>
						<span
							className="className-title"
							onClick={() => {
								this.sortTableBy('lastname');
							}}
						>
							{', '}
							{strings.lastname}
						</span>
					</div>
					<div className="custom_table-header-cell">
						{strings.email}
					</div>
					<div className="custom_table-header-cell">
						{strings.telephone}
					</div>
					<div className="custom_table-header-cell">
						{strings.gender}
					</div>
					<div className="custom_table-header-cell">
						<span
							className="className-title"
							onClick={() => {
								this.sortTableBy('date');
							}}
						>
							{' '}
							{strings.date}
						</span>
					</div>
					<div className="custom_table-header-cell">&nbsp;</div>
				</div>
			</div>
		);
	}
	renderCustomTable(testUser, filteredTestUsers) {
		return (
			<div>
				<div
					className={
						testUser.active
							? 'lit-custom_grid-active'
							: 'lit-custom_grid'
					}
				>
					{!this.state.isMainChecked && !this.state.singleChecks && (
						<div className="custom_checkBox-cell">
							<input
								type="checkbox"
								className="user_checkbox2"
								defaultChecked={testUser.checked}
								id={testUser.id}
								key={testUser.id}
								onChange={(e) => {
									this.handleSingleCheck(
										e.target,
										testUser,
										filteredTestUsers
									);
								}}
							/>
						</div>
					)}
					{this.state.isMainChecked && (
						<div className="custom_checkBox-cell">
							<input
								type="checkbox"
								className="user_checkbox2"
								defaultChecked={Boolean(
									this.state.isMainChecked
								)}
								id={testUser.id}
								key={testUser.id}
								onChange={(e) => {
									this.handleSingleCheck(
										e.target,
										testUser,
										this.state.selectedTestCandidates
									);
								}}
							/>
						</div>
					)}

					<div className="custom_table-cell">
						{testUser.firstName &&
							`${testUser.firstName}, ${testUser.lastName}`}
					</div>

					<div className="custom_table-cell">{testUser.email}</div>

					<div className="custom_table-cell">{testUser.phone}</div>
					{this.determineGender(
						testUser.id,
						this.props.batchedFilteredTestResults
					)}

					<div className="custom_table-cell">
						{moment(testUser.createdAt).format('LL')}
					</div>

					<div className="custom_btn-cell">
						<div className="lit-list__text">
							<button
								className={
									testUser.active
										? 'lit-btn lit-btn--small bg-primary5-active'
										: 'lit-btn lit-btn--small bg-primary5'
								}
								onClick={() => {
									if (!testUser.active) {
										testUser.active = true;
										this.setState({
											showIndividualTests: true,
											selectedIndividual: testUser.id,
										});
									} else {
										testUser.active = false;
										this.setState({
											showIndividualTests: false,
											selectedIndividual: testUser.id,
										});
									}
								}}
							>
								{testUser.active ? (
									<span>{strings.hideWithArrow}</span>
								) : (
									<span>{strings.showtests}</span>
								)}
							</button>
						</div>
					</div>
				</div>
				{testUser.active === true &&
					this.renderSubTable(
						this.getFilteredTestUsers(
							testUser.id,
							this.props.batchedFilteredTestResults
						)
					)}
			</div>
		);
	}
	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}
	renderButton(type, id, status, key) {
		const completed = status === COMPLETED;
		const started = status === STARTED;

		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: type === 'child-experimental'
			? `/single-speech-test/${id}`
			: `/single-test/${id}`;

		if (completed || started) {
			return (
				<div>
					<Link
						className="lit-btn lit-btn--small bg-secondary"
						to={destination}
						onClick={() => this.handleClick(id, key)}
					>
						{strings.seeresults}
					</Link>
				</div>
			);
		}
		return null;
	}
	getTestDateInfo(completedAt, usedAt, distributedAt) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.completed}
						<br />
						{moment(completedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (usedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.started}
						<br />
						{moment(usedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (distributedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.distributed}
						<br />
						{moment(distributedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		}

		return testDateInfo;
	}

	renderSubTable(singleCandidate) {
		if (singleCandidate) {
			return (
				<div className="lit-individual-test">
					<table className="lit-tests">
						<thead>
							<tr>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td className="lit-test__header">
									{strings.testkey}
								</td>
								<td className="lit-test__header">
									{strings.type}
								</td>
								<td className="lit-test__header">
									{strings.recipient}
								</td>
								<td className="lit-test__header">
									{strings.status}
								</td>
								<td colSpan="2" className="lit-test__header">
									{strings.actions}
								</td>
							</tr>
						</thead>
						<tbody>
							{singleCandidate.map((candidate) => {
								const {
									id,
									key,
									testUser,
									status,
									type,
								} = candidate;
								const firstName = testUser.firstName;
								const lastName = testUser.lastName;
								const fullName = `${firstName} ${lastName}`;
								return (
									<tr className="lit-test" key={key}>
										<td className={'lit-test__col'}>
											<div className={'lit-test-status2'}>
												<div
													className={classNames({
														'lit-test-status__indicator': true,
														[status.id]: true,
													})}
												/>
												<p className="lit-test-status__label">
													{status.label}
												</p>
											</div>
										</td>
										<td className="lit-test__col">{key}</td>
										<td className="lit-test__col">
											{TEST_TYPE_NAMES[type]}
										</td>
										<td className="lit-test__col">
											{fullName || (
												<b>{strings.notdistributed}</b>
											)}
											&nbsp;
										</td>
										{this.getTestDateInfo(
											candidate.completedAt,
											candidate.usedAt,
											candidate.distributedAt
										)}
										<td className="lit-test__col">
											<div className="lit-test__field">
												{this.renderButton(
													type,
													id,
													status,
													key
												)}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}
		return (
			<div>
				<p style={{color: 'white'}}>{strings.notestsforcandidate}</p>
			</div>
		);
	}
	evaluateCheckStatus(allCandidates, classInfo, filteredUsers) {
		for (let i = 0; i < allCandidates.length; i++) {
			for (let j = 0; j < classInfo.length; j++) {
				if (allCandidates[i].id === classInfo[j].id) {
					if (allCandidates[i].checked === true) {
						classInfo[j].checked = true;
					}
				}
			}
		}
		return classInfo;
	}
	findTestUserNotInClass(allCandidates, classInfo, filteredUsers) {
		const arrayOfCandidates = [];
		let existsInclasses = false;
		for (let i = 0; i < allCandidates.length; i++) {
			let candidateId = allCandidates[i].id;
			for (let j = 0; j < classInfo.length; j++) {
				let classWIthCandidates = classInfo[j].classInfo;
				for (let k = 0; k < classWIthCandidates.length; k++) {
					if (candidateId === classWIthCandidates[k].id) {
						existsInclasses = true;
						break;
					}
				}
				if (existsInclasses) break;
			}
			if (!existsInclasses) {
				arrayOfCandidates.push(allCandidates[i]);
			} else {
				existsInclasses = false;
			}
		}
		return arrayOfCandidates;
	}
	render() {
		const {
			hasInValidSubscription,
			testUsers,
			isBasicUser,
			isTrialUser,
			batchedFilteredTestResults,
		} = this.props;
		const {
			selectedTestCandidates,
			isCreatingClass,
			isCreatingCandidate,
			isSendingTest,
		} = this.state;
		let filteredTestUsers = this.state.allCandidates;

		filteredTestUsers = this.state.allCandidates.filter((testUser) => {
			const searchableFields = [
				'firstName',
				'lastName',
				'phone',
				'email',
			];
			for (const field of searchableFields) {
				if (
					testUser[field] &&
					testUser[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});

		return (
			<div className="lit-tests-view__header">
				<div className="lit-new-test__wrapper">
					<div className="lit-btn_grid">
						{Boolean(testUsers.length) && (
							<div className="lit-input_searchUser_main">
								<input
									className="lit-tf_search"
									type="text"
									placeholder={strings.searchhere}
									id="testkey"
									onChange={(e) => {
										this.setState({
											searchFilter: e.target.value.toLowerCase(),
										});
									}}
								/>
							</div>
						)}

						{this.state.isClassPanelActive &&
							this.state.isRenamingClass && (
								<div className="lit-input_searchUser_main">
									<input
										className="lit-tf_search"
										autoFocus
										type="text"
										placeholder={`${this.state.selectedClass}`}
										id="newName"
										onChange={(e) => {
											this.setState({
												newClassName: e.target.value,
											});
										}}
									/>
								</div>
							)}

						{Boolean(this.props.classData.length) &&
							!this.state.isRenamingClass && (
								<div className="Select_class-function">
									<select
										className="lit-tf_search"
										defaultValue={this.state.selectedClass}
										onChange={(e) => {
											if (
												e.target.value ===
												allMyCandidates
											) {
												const checkedClassData = this.props.testUsers.map(
													(data) => {
														data.checked = false;
														return data;
													}
												);

												this.setState({
													isMainChecked: false,
													selectedTestCandidates: checkedClassData,
													allCandidates: this.props
														.testUsers,
													filteredTestUsers: this
														.props.testUsers,
													isClassPanelActive: false,
												});
											} else if (
												e.target.value ===
												'outsideClass'
											) {
												const checkStatus = this.findTestUserNotInClass(
													this.props.testUsers,
													this.props.classData,
													filteredTestUsers
												);

												this.setState({
													allCandidates: checkStatus,

													isClassPanelActive: true,
													selectedClass:
														e.target.value,
													selectedClassId:
														'outSideClass',
												});
											} else {
												const chosenClass = this.props.classData.filter(
													(className) =>
														className.className ===
														e.target.value
												);

												const checkStatus = this.evaluateCheckStatus(
													this.state.allCandidates,
													chosenClass[0].classInfo,
													filteredTestUsers
												);

												this.setState({
													allCandidates: checkStatus,

													isClassPanelActive: true,
													selectedClass:
														e.target.value,
													selectedClassId:
														chosenClass[0].classId,
												});
											}
										}}
									>
										<option
											key={1}
											value={allMyCandidates}
											selected
										>
											{allMyCandidates}
										</option>
										<option
											className="label_grupper-option"
											disabled="disabled"
										>
											{strings.classindicator}
										</option>
										{this.props.classData.map(
											(userClass) => (
												<option
													id={userClass.classId}
													key={userClass.classId}
													value={userClass.className}
												>
													{userClass.className}
												</option>
											)
										)}
										<option
											className="label_grupper-option"
											disabled="disabled"
										>
											{'---ikke lagt i klasse---'}
										</option>
										<option
											key={'last1'}
											value={'outsideClass'}
											selected
										>
											{'kandidater'}
										</option>
									</select>
								</div>
							)}
						{this.state.isClassPanelActive &&
							this.state.selectedClassId !== 'outSideClass' && (
								<div>
									<button
										className="lit-btn bg-primary6"
										onClick={() => {
											this.setState({
												isEditingClass: !this.state
													.isEditingClass,
												isRenamingClass: !this.state
													.isRenamingClass,
											});
										}}
									>
										{<img src={create} alt="" />}
									</button>
								</div>
							)}
						{this.state.isClassPanelActive &&
							this.state.isRenamingClass && (
								<div>
									<button
										className="lit-btn bg-primary5"
										onClick={() => {
											if (!this.state.newClassName) {
												alert(strings.providevalidname);
											} else {
												const newTitleAndId = {
													className: this.state
														.newClassName,
													adminUser: this.props
														.currentUser.id,
													targetClass: this.state
														.selectedClass,
												};

												this.props.onRenamingClass(
													newTitleAndId
												);
											}
										}}
									>
										{strings.save}
									</button>
									<button
										className="lit-btn bg-primary4"
										onClick={() => {
											this.setState(
												{
													allCandidates: this.props
														.testUsers,

													isClassPanelActive: true,
													selectedTestCandidates: defaultSelectedTestCandidates,
													selectedClass:
														strings.allmycandidates,
												},
												() => {
													let extractedCandidates;
													this.state.allCandidates.forEach(
														() => {
															extractedCandidates = this.state.allCandidates.filter(
																(candidate) =>
																	candidate.checked ===
																	true
															);
														}
													);

													this.setState({
														selectedTestCandidates: extractedCandidates,
														isClassPanelActive: false,
														isRenamingClass: false,
														isEditingClass: false,
													});
												}
											);
										}}
									>
										{strings.cancel}
									</button>
								</div>
							)}
					</div>

					{!testUsers.length && <p>{strings.nocandidates}</p>}
					{Boolean(testUsers.length) && (
						<div className="lit-main-candidates-contianer">
							<div className="lit_table-wrapper">
								{this.renderCustomTableHeader(
									filteredTestUsers
								)}
								{filteredTestUsers.map((testUser, index) => {
									return (
										<div key={index}>
											{this.renderCustomTable(
												testUser,
												filteredTestUsers
											)}
										</div>
									);
								})}
							</div>
						</div>
					)}

					{isCreatingClass && (
						<NewClassModal
							closeWindow={() => {
								this.setState({
									isCreatingClass: false,
								});
							}}
							selectedTestCandidates={selectedTestCandidates}
							currentUser={this.props.currentUser}
							batchedFilteredTestResults={
								batchedFilteredTestResults
							}
							classes={this.props.classes}
							classData={this.props.classData}
							onCreateClass={this.props.onCreateClass}
							adminClasses={this.props.adminClasses}
							onAddingCandidates={this.props.onAddingCandidates}
						/>
					)}

					{isCreatingCandidate && (
						<NewCandidateModal
							closeWindow={() => {
								this.setState({
									isCreatingCandidate: false,
								});
							}}
							testUsers={selectedTestCandidates}
						/>
					)}

					{isSendingTest && (
						<TestDispatchModal
							closeWindow={() => {
								this.setState({
									isSendingTest: false,
								});
							}}
							testUsers={selectedTestCandidates}
							onSaveBulk={this.props.onSaveBulk}
							userStage={this.props.userStage}
							userGroups={this.props.userGroups}
							userLanguages={this.props.userLanguages}
							currentUser={this.props.currentUser}
							usersGroups={this.props.usersGroups}
							batchedFilteredTestResults={
								batchedFilteredTestResults
							}
							hasInValidSubscription={
								this.props.hasInValidSubscription
							}
						/>
					)}
					<div className="lit-btn_grid">
						<div>
							<button
								className="lit-btn bg-primary3"
								disabled={
									hasInValidSubscription ||
									isTrialUser ||
									isBasicUser ||
									!testUsers.length
								}
								onClick={() => {
									let extractedSelectedCandidates = null;
									if (!testUsers.length) {
										alert(strings.nocandidates);
									}
									extractedSelectedCandidates = this.state.allCandidates.filter(
										(data) => {
											if (data.checked) {
												return data;
											}
										}
									);

									if (
										!Boolean(
											extractedSelectedCandidates.length
										)
									) {
										alert(strings.choosecandidate);
									} else {
										this.setState({
											selectedTestCandidates: extractedSelectedCandidates,
											isSendingTest: true,
										});
									}
								}}
							>
								{strings.sendtest}
							</button>
						</div>
						<div>
							<button
								className="lit-btn bg-primary3"
								disabled={!testUsers.length}
								onClick={() => {
									let extractedSelectedCandidates = null;
									if (!testUsers.length) {
										alert(strings.nocandidates);
									}

									extractedSelectedCandidates = this.state.allCandidates.filter(
										(data) => {
											if (data.checked) {
												return data;
											}
										}
									);

									if (
										!Boolean(
											extractedSelectedCandidates.length
										)
									) {
										alert(strings.choosecandidate);
									} else {
										this.setState({
											selectedTestCandidates: extractedSelectedCandidates,
											isCreatingClass: true,
										});
									}
								}}
							>
								{strings.createclass}
							</button>
						</div>
						{this.state.isClassPanelActive &&
							this.state.isEditingClass && (
								<div>
									<button
										className="lit-btn bg-primary4"
										onClick={() => {
											let extractSelectedCandidates;
											if (!testUsers.length) {
												alert(strings.nocandidates);
											} else {
												extractSelectedCandidates = this.state.allCandidates.filter(
													(data) => {
														if (data.checked) {
															return data;
														}
													}
												);
												const isolatedIds = extractSelectedCandidates.map(
													(userids) => {
														return userids.id;
													}
												);
												if (
													!Boolean(isolatedIds.length)
												) {
													alert(
														strings.choosecandidate
													);
												} else if (
													this.state
														.selectedClassId ===
													'outSideClass'
												) {
													alert(
														'You can not change anything here'
													);
												} else {
													this.props.onDeletingCandidates(
														isolatedIds,
														this.state
															.selectedClassId,
														this.props.currentUser
															.subscription.id
													);
												}
											}
										}}
									>
										{strings.removefromclass}
									</button>
								</div>
							)}
					</div>
				</div>
			</div>
		);
	}
}
export default MyCandidates;

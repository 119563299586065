import {ENDPOINT_URL} from '../constants';
const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function initResetPassword(email) {
	return fetch(`${ENDPOINT_URL}/initresetpassword`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body: JSON.stringify({email}),
	})
		.then((response) => {
			if (response.ok) {
				return response;
			}

			return Promise.reject(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
}

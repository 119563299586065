const testPeriodsCompareFn = (currentPeriod, comparePeriod) => {
	if (currentPeriod.year > comparePeriod.year) {
		return -1;
	} else if (currentPeriod.year < comparePeriod.year) {
		return 1;
	}

	if (currentPeriod.month > comparePeriod.month) {
		return -1;
	} else if (currentPeriod.month < comparePeriod.month) {
		return 1;
	}

	return 0;
};

export default function getSortedSubscriptionTestPeriods(testPeriods) {
	const testPeriodsCopy = testPeriods.slice();

	testPeriodsCopy.sort(testPeriodsCompareFn);

	return testPeriodsCopy;
}

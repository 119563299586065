import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faSignIn} from '@fortawesome/free-solid-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';

import './styles.scss';
import {updateObject} from '../../utils/reducerUtils';
import Logo from '../../assets/dysmate-logo-hvit-small.png';

import addAdminCustom from '../../api/addAdminCustom';
import verifyAdminCustom from '../../api/verifyAdminCustom';
import initResetPassword from '../../api/initResetPassword';
import resetPassword from '../../api/resetPassword';
import checktokenValidity from '../../api/checktokenValidity';

import changeMethodCustom from '../../api/changeMethodCustom';
import strings from '../../utils/strings';

import queryString from 'query-string';
const parsedSearch = queryString.parse(window.location.search);
const testKey = parsedSearch.key;
const resetFlag = parsedSearch.passwordResetToken
	? parsedSearch.passwordResetToken
	: false;

class EmailPasswordLogin extends React.Component {
	constructor(props) {
		super(props);

		const regTokenChange =
			props.regTokenChange ?? parsedSearch.regTokenChange;

		this.state = {
			invalidEmail: false,
			strengthExhibitor: null,
			unmatchedPassword: false,
			hasSendVerification: false,
			isResetingPsw: false,
			resendEmail: null,
			emailError: null,
			hasResetToken: resetFlag ? resetFlag : null,
			flowStatus: null,
			pswError: null,
			showPassword: false,
			isTokenValid: true,
			linkTimedOut: false,
			regTokenChange: regTokenChange ?? null,
			testAdmin: {
				firstName: '',
				lastName: '',
				email: '',
				regId: testKey ?? null,
				regTokenChange: regTokenChange ?? null,
				phone: '',
				psw: '',
				pin: '',
			},
		};
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleUpdateTestAdminEvent = this.handleUpdateTestAdminEvent.bind(
			this
		);
		this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
	}

	componentDidMount() {
		if (resetFlag) {
			checktokenValidity(resetFlag).then((res) => {
				console.log('response after api call', res);
				if (res.error === 'Not found') {
					this.setState({
						flowStatus: 'link does not exit',
						linkTimedOut: true,
						isTokenValid: false,
					});
					setTimeout(() => {
						window.top.location.href = `/login`;
					}, 5000);
				} else if (res.status === 408) {
					this.setState({
						flowStatus: 'link expired',
						linkTimedOut: true,
						isTokenValid: false,
					});
					setTimeout(() => {
						window.top.location.href = `/login`;
					}, 5000);
				}
			});
		}
	}

	handleClickShowPassword() {
		let {showPassword} = this.state;
		const newValue = !showPassword;
		this.setState({
			showPassword: newValue,
		});
	}

	handleMouseDownPassword(event) {
		event.preventDefault();
	}

	validateEmail(event) {
		this.handleUpdateTestAdminEvent(event);
		return;
		const target = event.target;

		const value = target.value;
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			this.setState({
				invalidEmail: false,
			});
		} else {
			this.setState({
				invalidEmail: true,
			});
		}
	}
	validatePassword(event) {
		const target = event.target;

		const value = target.value;
		if (testKey || this.state.hasResetToken || this.state.regTokenChange) {
			var strongPsw = new RegExp(
				'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
			);
			var mediumPsw = new RegExp(
				'^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
			);
			if (!value.length) {
				this.setState({
					strengthExhibitor: null,
				});
			} else if (strongPsw.test(value)) {
				this.setState({
					strengthExhibitor: 'lit-strength-Exhibitor-green',
				});
				this.handleUpdateTestAdminEvent(event);
			} else if (mediumPsw.test(value)) {
				this.setState({
					strengthExhibitor: 'lit-strength-Exhibitor-orange',
				});
			} else {
				this.setState({
					strengthExhibitor: 'lit-strength-Exhibitor-red',
				});
			}
		} else {
			this.handleUpdateTestAdminEvent(event);
		}
	}
	validateMatchingPasswords() {
		var password = document.getElementById('user-psw').value;
		var confirmPassword = document.getElementById('txtConfirmPassword')
			.value;
		if (password !== confirmPassword) {
			document
				.getElementById('txtConfirmPassword')
				.setCustomValidity('Passwords do not match');
			this.setState({
				unmatchedPassword: true,
			});
		} else {
			this.setState({
				unmatchedPassword: false,
			});
			document.getElementById('txtConfirmPassword').setCustomValidity('');
		}
	}
	handleUpdateTestAdminEvent(event) {
		const target = event.target;
		const key = target.name;
		const value = target.value;
		this.handleUpdate(key, value);
	}

	handleUpdate(key, value) {
		const {testAdmin} = this.state;
		this.setState({
			testAdmin: updateObject(testAdmin, {
				[key]: value,
			}),
		});
	}
	addAdminViaCustomMethod() {
		addAdminCustom(testKey).then((res) => {
			if (res.ok) {
				this.setState({
					hasSendVerification: true,
				});
			}
		});
	}
	verifyAdminRegistration() {
		verifyAdminCustom(this.state.testAdmin).then((response) => {
			if (!response.error) {
				if (response.action === 'auth' && response.result === 'OK') {
					localStorage.setItem('authtoken', response.authToken);
					window.top.location.href = `/my-tests`;

					return;
				}
			} else {
				if (response.error && response.error === 'Invalid email') {
					this.setState({emailError: true});
				} else if (
					response.error &&
					response.error === 'Invalid password'
				) {
					this.setState({pswError: true});
				}
			}
		});
	}
	sendResetLink(email) {
		initResetPassword(email).then((res) => {
			if (res.ok) {
				this.setState({
					isResetingPsw: true,
					flowStatus: strings.resetpswlinksent,
					resendEmail: this.state.testAdmin.email,
				});
			} else {
			}
		});
	}
	allowOperation() {
		if (!testKey && !resetFlag) {
			verifyAdminCustom(this.state.testAdmin).then((response) => {
				if (!response.error) {
					if (
						response.action === 'auth' &&
						response.result === 'OK'
					) {
						localStorage.setItem('authtoken', response.authToken);
						window.top.location.href = `/my-tests`;

						return;
					}
				} else {
					if (response.error && response.error === 'Invalid email') {
						this.setState({emailError: true});
					} else if (
						response.error &&
						response.error === 'Invalid password'
					) {
						this.setState({pswError: true});
					}
				}
			});
		}
		if (!testKey && resetFlag) {
			resetPassword(resetFlag, this.state.testAdmin.psw).then((res) => {
				if (res.ok) {
					this.setState({
						flowStatus: strings.passwrodchanged,
					});
					/* setTimeout(() => {
						window.top.location.href = `/login`;
					}, 12000); */
				} else if (res.status === 408) {
					this.setState({
						flowStatus: 'link expired',
						linkTimedOut: true,
					});
					setTimeout(() => {
						window.top.location.href = `/login`;
					}, 5000);
				}
			});
		}
	}
	changeAuthMethodToEmail() {
		if (this.state.regTokenChange) {
			changeMethodCustom(this.state.testAdmin).then((res) => {
				if (res.ok) {
				} else if (res.status === 401) {
					console.log('Error:401');
				}
			});
		}
	}

	render() {
		return (
			<div className="lit-customLogin">
				<div>
					<div className="lit-customLogin__wrapper">
						{!this.state.hasSendVerification && (
							<>
								<h1 className="lit-customLogin-title">
									{testKey
										? 'Registrer deg'
										: resetFlag
										? 'Nytt Passord'
										: 'Logg Inn'}
								</h1>

								{this.state.flowStatus && !resetFlag && (
									<div className="lit-reset-info-container">
										<p className="lit-reset-info">
											{strings.resetpswlinksent}
										</p>
									</div>
								)}

								{this.state.flowStatus &&
									this.state.linkTimedOut && (
										<div className="lit-invlaid-status-container">
											<p className="lit-invlaid-note">
												{'Link expired'}
											</p>
										</div>
									)}
								<div className="lit-screen__content">
									<form
										className="customregister-form-login"
										onSubmit={(event) => {
											event.preventDefault();
											if (
												testKey &&
												!resetFlag &&
												this.state.strengthExhibitor ===
													'lit-strength-Exhibitor-green' &&
												!this.state.unmatchedPassword
											) {
												this.addAdminViaCustomMethod(
													testKey
												);
											} else if (
												!testKey &&
												!this.state.regTokenChange
											) {
												this.allowOperation();
											} else if (
												!testKey &&
												resetFlag &&
												this.state.strengthExhibitor ==
													'lit-strength-Exhibitor-green' &&
												!this.state.unmatchedPassword &&
												!this.state.regTokenChange
											) {
												this.allowOperation();
											} else if (
												!testKey &&
												!resetFlag &&
												this.state.strengthExhibitor ==
													'lit-strength-Exhibitor-green' &&
												!this.state.unmatchedPassword &&
												this.state.regTokenChange
											) {
												this.changeAuthMethodToEmail();
											}
										}}
									>
										<div className="lit-customLogin-form-wrapper">
											{testKey && (
												<div className="lit-compound-fields">
													<label className="lit-label lit-label-usr">
														{strings.firstname}
														<FontAwesomeIcon
															className="lit-login__icon"
															icon={faUser}
														></FontAwesomeIcon>
														<input
															required
															name="firstName"
															id="user-firstName"
															className="customLogin-user-tf"
															onChange={
																this
																	.handleUpdateTestAdminEvent
															}
															type="text"
														></input>
													</label>
													<div className="lit-label lit-label-psw">
														<label>
															{strings.lastname}
															<FontAwesomeIcon
																className="lit-lastName__icon"
																icon={faUser}
															></FontAwesomeIcon>
															<input
																required
																name="lastName"
																id="user-lastName"
																className="customLogin-psw-tf"
																onChange={
																	this
																		.handleUpdateTestAdminEvent
																}
																type="text"
															></input>
														</label>
													</div>

													<div className="lit-label lit-label-usr">
														<label className="customLogin-user-lbl">
															{strings.telephone}
															<FontAwesomeIcon
																className="lit-phone__icon"
																icon={faPhone}
															></FontAwesomeIcon>
															<input
																id="user-telephone"
																required
																onChange={
																	this
																		.handleUpdateTestAdminEvent
																}
																name="phone"
																className="customLogin-user-tf"
																type="tel"
															></input>
														</label>
													</div>
												</div>
											)}
											<div className="lit-compound-fields">
												{!testKey &&
													!this.state.isResetingPsw &&
													!this.state
														.hasResetToken && (
														<div>
															<i class="lit-login__icon fas fa-lock"></i>
															<label className="lit-label lit-label-usr">
																<FontAwesomeIcon
																	className="lit-login__icon"
																	icon={
																		faUser
																	}
																></FontAwesomeIcon>
																{strings.email}
																<input
																	required
																	name="email"
																	className="customLogin-user-tf"
																	type="email"
																	id="user-email"
																	onChange={(
																		event
																	) => {
																		const value =
																			event
																				.target
																				.value;

																		this.validateEmail(
																			event
																		);
																	}}
																></input>
															</label>
															{this.state
																.invalidEmail && (
																<div className="lit-invlaid-email-note-container">
																	<p className="lit-invalid-email-note">
																		The
																		email is
																		incorrect.
																		Please
																		provide
																		a valid
																		email.
																	</p>
																</div>
															)}
														</div>
													)}
												{!testKey &&
													!this.state.isResetingPsw &&
													this.state.hasResetToken &&
													this.state.isTokenValid && (
														<div>
															<label className="lit-label lit-label-psw">
																{
																	strings.password
																}
																<FontAwesomeIcon
																	className="lit-psw-new__icon"
																	icon={
																		faLock
																	}
																></FontAwesomeIcon>
																<Input
																	required
																	name="psw"
																	disableUnderline={
																		true
																	}
																	id="user-psw"
																	className="customLogin-psw-tf"
																	type={
																		this
																			.state
																			.showPassword
																			? 'text'
																			: 'password'
																	}
																	pattern="(?=.*\d)(?=.*[\W_]).{7,}"
																	onChange={(
																		event
																	) => {
																		this.validatePassword(
																			event
																		);
																	}}
																	endAdornment={
																		<InputAdornment
																			position="end"
																			disablePointerEvents={
																				false
																			}
																			style={{
																				borderBottom:
																					'none',
																			}}
																		>
																			<IconButton
																				onClick={
																					this
																						.handleClickShowPassword
																				}
																			>
																				{this
																					.state
																					.showPassword ? (
																					<Visibility />
																				) : (
																					<VisibilityOff />
																				)}
																			</IconButton>
																		</InputAdornment>
																	}
																	title={
																		'Minimum of 7 characters. Should have at least One Capital letter, one small letter, one special character and one number.'
																	}
																/>
															</label>

															{this.state
																.strengthExhibitor && (
																<div className="lit-psw-strength-container">
																	<div
																		className={
																			this
																				.state
																				.strengthExhibitor
																		}
																	></div>
																	<div className="lit-str-text">
																		Strength
																	</div>
																</div>
															)}
														</div>
													)}
												{!testKey &&
													!this.state.isResetingPsw &&
													this.state.hasResetToken &&
													this.state.isTokenValid && (
														<div className="lit-label lit-label-psw">
															<label className="lit-label lit-label-psw">
																{
																	strings.confirmpassword
																}
																<FontAwesomeIcon
																	className={
																		this
																			.state
																			.strengthExhibitor
																			? 'lit-psw-new-confirm-str__icon'
																			: 'lit-psw-new-confirm__icon'
																	}
																	icon={
																		faLock
																	}
																></FontAwesomeIcon>
																<input
																	required
																	name="confirm-psw"
																	id="txtConfirmPassword"
																	className="customLogin-psw-tf"
																	type="password"
																	pattern="(?=.*\d)(?=.*[\W_]).{7,}"
																	onChange={(
																		event
																	) => {
																		this.validateMatchingPasswords(
																			event
																		);
																	}}
																	title="Minimum of 7 characters. One Capital and one small. Should have at least one special character and one number."
																/>
															</label>
														</div>
													)}

												{(testKey ||
													this.state
														.regTokenChange) && (
													<div>
														<label className="lit-label lit-label-usr">
															{strings.password}
															<FontAwesomeIcon
																className="lit-psw-reg__icon"
																icon={faLock}
															></FontAwesomeIcon>

															<Input
																required
																name="psw"
																id="user-psw"
																disableUnderline={
																	true
																}
																className="customLogin-user-tf"
																type={
																	this.state
																		.showPassword
																		? 'text'
																		: 'password'
																}
																pattern="(?=.*\d)(?=.*[\W_]).{7,}"
																onChange={(
																	event
																) => {
																	this.validatePassword(
																		event
																	);
																}}
																endAdornment={
																	<InputAdornment
																		position="end"
																		disablePointerEvents={
																			false
																		}
																		style={{
																			borderBottom:
																				'none',
																		}}
																	>
																		<IconButton
																			onClick={
																				this
																					.handleClickShowPassword
																			}
																		>
																			{this
																				.state
																				.showPassword ? (
																				<Visibility />
																			) : (
																				<VisibilityOff />
																			)}
																		</IconButton>
																	</InputAdornment>
																}
																title={
																	'Minimum of 7 characters. Should have at least One Capital letter, one small letter, one special character and one number.'
																}
															/>
														</label>
														{this.state
															.strengthExhibitor && (
															<div className="lit-psw-strength-container">
																<div
																	className={
																		this
																			.state
																			.strengthExhibitor
																	}
																></div>
																<div className="lit-str-text">
																	Strength
																</div>
															</div>
														)}
													</div>
												)}

												{!testKey &&
													!this.state.isResetingPsw &&
													!this.state.hasResetToken &&
													!this.state
														.regTokenChange && (
														<label className="lit-label lit-label-psw">
															{strings.password}
															<FontAwesomeIcon
																className="lit-psw__icon"
																icon={faLock}
															></FontAwesomeIcon>
															<Input
																required
																name="psw"
																id="user-psw"
																disableUnderline={
																	true
																}
																className="customLogin-psw-tf"
																type={
																	this.state
																		.showPassword
																		? 'text'
																		: 'password'
																}
																onChange={(
																	event
																) => {
																	this.handleUpdateTestAdminEvent(
																		event
																	);
																}}
																endAdornment={
																	<InputAdornment
																		position="end"
																		disablePointerEvents={
																			false
																		}
																		style={{
																			borderBottom:
																				'none',
																		}}
																	>
																		<IconButton
																			onClick={
																				this
																					.handleClickShowPassword
																			}
																		>
																			{this
																				.state
																				.showPassword ? (
																				<Visibility />
																			) : (
																				<VisibilityOff />
																			)}
																		</IconButton>
																	</InputAdornment>
																}
															/>
														</label>
													)}
												{(testKey ||
													this.state
														.regTokenChange) && (
													<div className="">
														<label className="lit-label lit-label-psw">
															{
																strings.confirmpassword
															}
															<FontAwesomeIcon
																className={
																	this.state
																		.strengthExhibitor
																		? 'lit-psw-confirm-low__icon'
																		: 'lit-psw-confirm__icon'
																}
																icon={faLock}
															></FontAwesomeIcon>
															<input
																required
																name="confirm-psw"
																id="txtConfirmPassword"
																className="customLogin-psw-tf"
																type="password"
																pattern="(?=.*\d)(?=.*[\W_]).{7,}"
																onChange={(
																	event
																) => {
																	this.validateMatchingPasswords(
																		event
																	);
																}}
																title="Minimum of 7 characters. One Capital and one small. Should have at least one special character and one number."
															/>
														</label>
													</div>
												)}
											</div>
										</div>

										{(this.state.pswError ||
											this.state.emailError) && (
											<div className="lit-invlaid-status-container">
												<p className="lit-invlaid-note">
													Feil epost eller passord
												</p>
											</div>
										)}
										<br></br>

										{this.state.flowStatus &&
											!this.state.linkTimedOut &&
											resetFlag && (
												<div className="lit-valid-change-container">
													<p className="lit-valid-change">
														{this.state.flowStatus}
													</p>
												</div>
											)}
										{this.state.pswError &&
											!this.state.isResetingPsw && (
												<div className="lit-reset-psw-container">
													<p className="lit-reset-psw">
														Glemt passord ?{' '}
														<a
															className="lit-reset"
															onClick={() => {
																this.sendResetLink(
																	this.state
																		.testAdmin
																		.email
																);
															}}
														>
															Trykk her
														</a>
													</p>
												</div>
											)}

										{!this.state.isResetingPsw && (
											<div>
												<div className="lit-btn-submit-wrapper">
													<button className="lit-btn-submit">
														{testKey
															? 'Register'
															: resetFlag
															? 'Opprett'
															: 'Logg Inn'}
														<FontAwesomeIcon
															className="lit-signin__icon"
															icon={faSignIn}
														></FontAwesomeIcon>
													</button>
												</div>
											</div>
										)}
									</form>
								</div>
							</>
						)}
						{this.state.hasSendVerification && (
							<div className="lit-customLogin-wrapper">
								<div className="lit-customPin-form-wrapper">
									<h1 className="lit-customLogin-title">
										{testKey ? 'Registrer deg' : 'Logg Inn'}
									</h1>
									<div className="lit-pin-instrucitons">
										<p>
											Vi har sendt en verifiseringskode
											til din epostadresse. Vennligst tast
											inn koden i feltet og trykk “Send”
										</p>
										<br></br>
										<br></br>
									</div>

									<div className="content-wrapper">
										<form
											className="register-form-login"
											onSubmit={(event) => {
												event.preventDefault();

												this.verifyAdminRegistration(
													testKey
												);
											}}
										>
											<div className="lit-customPin-form-wrapper">
												<div className="lit-customPIn-inner-content">
													<div className="lit-compound-pin-fields">
														<div className="">
															<label className="lit-label lit-label-pin">
																PINKODE:
																<input
																	required
																	name="pin"
																	id="pin-kode"
																	className="customLogin-pin-tf"
																	onChange={
																		this
																			.handleUpdateTestAdminEvent
																	}
																	type="text"
																/>
															</label>
														</div>
													</div>
												</div>
												<div className="lit-btn-submit-wrapper">
													<button className="lit-btn-submit">
														{strings.send}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						)}
						<div className="lit-log-gram-wrapper">
							<img
								className="lit-fav-logo__img"
								src={Logo}
								alt="Literate logo"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default EmailPasswordLogin;

import {updateObject} from './reducerUtils';

const authorizationToken = localStorage.getItem('authtoken');

const headers = new Headers();
headers.append('Content-Type', 'application/json');
if (authorizationToken) {
	headers.append('Authorization', 'Bearer ' + authorizationToken);
}

const defaultOptions = {
	method: 'GET',
	mode: 'cors',
	headers,
	credentials: 'include',
};

export default function getFetchOptions(options) {
	return updateObject(defaultOptions, options);
}

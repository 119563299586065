/* eslint-disable camelcase */

export default {
	mytest: `My tests`,
	myCandidates: `My Candidates`,
	createnewtest: `Create new test`,
	started: `Started`,
	starttest: `Start test`,
	all: `All`,
	chooseagroup: `Choose a group`,
	distributed: `Distributed`,
	notdistributed: `Not distibuted`,
	notstarted: `Not startet`,
	completed: `Completed`,
	completeplural: `Completed:`,
	seeresults: `Se results`,
	status: `Status`,
	testkey: `Testkey`,
	type: `Type`,
	recipient: `Received`,
	actions: `Actions`,
	send: `Send`,
	save: `Save`,
	sendcodeemail: `Send code by email`,
	january: `january`,
	february: `february`,
	march: `march`,
	april: `april`,
	may: `may`,
	june: `june`,
	juli: `julu`,
	august: `august`,
	september: `september`,
	october: `october`,
	november: `november`,
	december: `december`,
	norway: `Norway`,
	sweden: `Sveden`,
	germany: `Germany`,
	england: `Great Britain`,
	nederland: `The netherlands`,
	message_testcannotbedeleted: `The test must be at least 3 months old to be deleted.`,
	warning_deletetest: `The test will no longer be availble for assessment. Do you want to continue?`,
	headerchildtest: `Testresults for Dysmate – C (Childrentest)`,
	dynamicheaderfortestresult_child: `Detailed results for Dysmate {0}`,
	dynamicheaderfortestreport_child: `Detailed report for Dysmate {0}`,
	dynamicheaderfortestcandidate_child: `Detailed information for Dysmate candidate {0}`,
	dynamicheaderfortestresult_adult: `Detailed results for Dysmate {0}`,
	dynamicheaderfortestreport_adult: `Detailed report for Dysmate {0}`,
	dynamicheaderfortestcandidate_adult: `Detailed information for Dysmate candidate {0}`,
	headeradulttest: `Result for Dysmate A (Screeningtest for adult)`,
	showbackground: `Show candidates background information`,
	incompleteMessage: `This test is not complete. Subtests are missing or have not been started.`,
	summary: `Summary`,
	printtestresults: `Print results`,
	zscorered: `Z-score is more than 1 standard deviation {0} average`,
	zscoregreen: `Z-score is more than 1 standard deviation {0} average`,
	zscoreblue: `Z-score is in the normal area`,
	overview: `Overvied`,
	assessment: `Assessment:`,
	assessmentspeechok: `{0} has achieved results in the normal area or above for experimental one-minute test, and seems to have good reading abilities.`,
	assessmentspeechnotok: `{0} has achieved results below the normal area for experimental one minute test and should be further investigated for dyslexia and/or reading comprehension difficulties`,
	asessmenttextok_1: `{0} has achieved results in the normal area or better for {1} of the subtests and seems to have good reading and writing skills`,
	asessmenttextnotok_1: `{0} has achieved results under the normal area for {1} of the {2} subtests, and should be further investigated for dyslexia and/or reading comprehension difficulties`,
	assesmenttextok_youthfollowup: `{0} has no statistical indications on typical cognitive markers of dyslexia.`,
	assesmentok_recommendation_youthfollowup: `If {0} still has achieved scores below the normal area for Reading comprehension and/or Word comprehension, or other parts of the screeningtest, it is recommended to do further assessment of reading comprehension failure and/or developmental language disorder (DLD).`,
	workingmemorylowassesment: `{0} has indication for Working memory and achieves a score that is {1} standard deviation units below average for their grade or age on this test. Working memory is not in itself a cognitive marker of dyslexia but is associated with both dyslexia and reading comprehension failure. Reduced working memory can, among other things, affect how much information you manage to get from read text. It is therefore recommended to investigate to what extent the working memory of {2} affects the acquisition of new subject matter, and whether there is a need for academic facilitation around this.`,
	rapidnominationlowassesment: `{0} has indication for rapid autonaming test and achieves a score that is {1} standard deviation units below average for their grade or age on this test. Rapid autonaming measures how quickly and accurately you manage to retrieve words from the language memory. Weak skills in this area are a cognitive marker of dyslexia.`,
	oneminutelowassesment: `{0} has indication on the One-minute test and achieves a score that is {1} standard deviation units below the average for their grade or age on this test. The one-minute test gives a clear measure of the number of words read correctly per minute and gives a good picture of decoding skills. Weak decoding skills are a marker of dyslexia.`,
	oneminutelowassesment2: `NB! It is recommended to evaluate the results of the One-Minute Test in connection with the results of the Dictation Test and the Word Chain Test of the Dysmate Screening Test.`,
	spoonerismlowassesment: `{0} has indication for the Spoonerism test and on this test achieves a score that is {1} standard deviation units below the average for their grade or age. The spoonerism test measures phoneme awareness and phonological processing speed. Weak skills in these areas are a cognitive marker of dyslexia.`,
	rawscore: `Raw score`,
	zscore: `Z-score`,
	percentilescore: `Percentile score`,
	tscore: `T-score`,
	scaledscore: `Scaled score`,
	normalrange: `Normal area`,
	assessmentsubtestnormal: `This score is in the normal area`,
	assessmentsubtestbelow: `This score is {0} standard deviations {1} average`,
	under: `under`,
	assessmentsubtestabove: `This score is {0} standard deviations {1} average`,
	over: `over`,
	workingmemorytest: `Working memory`,
	spoonerismtest: `Spoonerism`,
	oneminutetest: `One minute test`,
	letterknowledgetest: `Letter knowledge`,
	phonemisolationtest: `Phoneme Isolation`,
	worddictationtest: `Dictation`,
	phonemeomissiontest: `Phoneme omission test`,
	wordchaintest: `Word chain test`,
	nonwordtest: `Nonword test`,
	typingspeedtest: `Writing speed test`,
	readingtest: `Reading comphension`,
	wordcomprehensiontest: `Word comprehension`,
	loggin: `Log in`,
	loggout: `Log out`,
	trialperiod: `Trial period`,
	trialuser: `Trialuser`,
	basicsupcription: `Basic subscription`,
	fullsupscription: `Full subscription`,
	childsubscription: `Dysmate-C - subscription`,
	adultsubscription: `Dysmate-A - subscription`,
	youthsubscription: `Dysmate-Y - subscription`,
	name: `Name:`,
	firstname: `First name`,
	lastname: `Surname`,
	dateofbirth: `Date of birth:`,
	birthDate: `Date of birth`,
	paneMessageContinueTest: `This will send a new test link so that the candidate can continue a not completed test. Continue?`,
	telephone: `Telephone`,
	email: `Email`,
	edit: `Edit`,
	edituser: `Edit user`,
	seesubscription: `See subscription`,
	adulttests: `Dysmate-A (Adult tests)`,
	youthtests: `Dysmate-Y (Youth tests)`,
	confirmationofcost: `Confirmation of costs`,
	sex: `Sex`,
	male: `Man`,
	female: `Woman`,
	education: `Education`,
	cancel: `Cancel`,
	confirm: `Confirm`,
	age: `Age`,
	age_specified: `Age (year)`,
	testdate: `Test date`,
	childtest: `Dysmate-C (Childtest)`,
	experimentalchildtest: `Experimental childtest`,
	youthtest: `Dysmate-Y (Screening)`,
	adulttest: `Dysmate-A (Screening)`,
	adultscreening: `Dysmate A – (Screeningtest for adults)`,
	youthscreening: `Dysmate-Y – (Screeningtest for youths)`,
	childtests: `Dysmate-C (childtests)`,
	nousedtests: `No used {0} tests`,
	used: `Used:`,
	boy: `Boy`,
	girl: `Girl`,
	logg: `Eventlog`,
	of: `of {0}`,
	wordsperminute: `words per minute`,
	deleteoldtests: `Delete old tests`,
	addtesttogroup: `Add a test to a group`,
	message_incompletData: `Unvalid of incomplete data`,
	newclasscreated: `New class created`,
	testcandidateremoved: `The testcandidate is removed from the class`,
	testcandidateadded: `The testcandidate has been added to the class`,
	classexistchoosename: `This class already exists, please choose another name.`,
	class: `.grade`,
	subscription: `Subscription`,
	supersubscription: `has subscription`,
	mysubscription: `My subscription`,
	seetests: `See tests`,
	currencysymbol: `NOK`,
	hideWithArrow: `Hide ▲`,
	yourtrialperiodhasexpired: `Your trialperiod has expired`,
	upgradeyoursubscription: `Upgrade your subscription`,
	downloadandsendcontract: `To issue more tests you must have a subscription. Go to “My page” on the website to order a subscription, or download the contract and send it to`,
	priceandconditioninfo: `. Price information and conditions could be found on`,
	downloadcontract: `Download contract`,
	onlytwotestspertrialperiod: `Only 2 tests are available during the trial period`,
	upgradetofullsubscription: `upgrade to full subscription to issue new tests.`,
	fetch: `Restore`,
	language: `Language`,
	child: `Child`,
	youth: `Youth`,
	adult: `Adult`,
	youthfollowup: `Dysmate-Y II (Followup youth)`,
	youthfollowuptest: `Dysmate-Y II (Followuptest youth)`,
	adultfollowup: `Dysmate-A II (Followup adult)`,
	adultfollowuptest: `Dysmate-A II (Followuptest adult)`,
	childfollowuptest: `Dysmate-C II (Followup child)`,
	childnorming: `Norming children`,
	youthnorming: `Norming youth`,
	adultnorming: `Norming adult`,
	deletedon: `Deleted the`,
	basic: `Basic`,
	deletedtests: `Deleted tests`,
	viewsubscription: `See subscription ▼`,
	viewdeleted: `Deleted tests ▼`,
	referalinformation: `personal information`,
	referalgroups: `membership to groups`,
	mygroups: `My groups`,
	myinformation: `My personal information`,
	message_costsforeachtestthatis_started: `Note that, depending on your subscription, there may be costs associated with issuing new tests. Tests that are not used will not be charged.`,
	test_type: `Test type`,
	screeningtest_adult: `Dysmate-A (screeningtest adult)`,
	numberoftests: `Number of tests (max 30)`,
	create_tests: `Create new tests`,
	login: 'Log in',
	loginwithbankid: `Login with methods below`,
	message_thisisforcertifieduser: `This is for certified test administrators with subscription`,
	bankid: `BankID`,
	bankidonmobile: `BankID for phones`,
	edittestadministrator: `Edit test administrator`,
	personalinformation: `Personal information`,
	shcool_or_company: `School/company`,
	addanewtestadministrator: `Add new test administrator`,
	addtestadministrator: `Add a test administrator`,
	saveuserinformation: `Save information about the test candidate`,
	delete: `Delete`,
	deleteuser: `Delete user`,
	currentstatus: `Current status:`,
	savesubscription: `Save subscription`,
	organization: `Organization`,
	newloginmethod: `New login method`,
	errorhasoccured: `An error has occured`,
	message_singleuser_perks_trial: `With a user you can get a free trial period of 30 days and 2 free tests of each type`,
	message_change_loginmethod: `You are in the process of changing your login method. You need to use this method to login in the future`,
	info_for_professional_with_either_completed_screening_test: `This is for professionals that have completed or is signed up for a certification course, and wants to try a few tests before subscribing to the service.`,
	info_prerequisites_to_obtain_license: `To get access to the trial period, you need to be sign up for, or you need to have completed the dysmate certification course. All users will be approved manually by Dysmate, upon which they will receive instructions in an email to complete their registration to uptain access`,
	removefiltering: `Remove filters`,
	printreport: `Print test report`,
	hide: `Hide`,
	show: `Show`,
	userinformation: `Candidate information`,
	localizedwriteuptextforlowresults: `{0} has achieved results that are below the normal area for {1} of the {2} subtests, and should be further investigated for dyslexia and/or reading comprehension difficulties`,
	localizedwriteuptextforNormalresults: `{0} has achieved results in the normal area or better for {1} of the subtests and seems to have good reading and writing skills`,
	testreultsforliteratescreeningtest: `Test results for Dysmate screeningtest`,
	info_belowpresentsinformation: `The test results for {0}, age {1} year and {2} month are presented below.`,
	newinfo_belowpresentsinformation: `Test results for {0}, born {1}, (Age: {2} year og {3} months) are presented below.`,
	newinfo2_belowpresentsinformation: `Test report for {0}, born {1}, (Age: {2} year og {3} months) are presented below.`,
	newinfo3_belowpresentsinformation: `Candidate information for {0}, born {1}, (Age: {2} year og {3} months) is presented below.`,
	speech1_belowpresentsinformation: `Test results for {0}, (Age: {1}) are presented below.`,
	speech2_belowpresentsinformation: `Test report for {0}, (Age: {1}) are presented below.`,
	speech3_belowpresentsinformation: `Candidate information for {0}, (Age: {1} år) is presented below.`,
	info_testwascompletedon: `The test was completed on the {0}`,
	info_testwasNotcompletedon: `This test is not complete.`,
	notetsfound: `No completed tests found`,
	background_info_testcandidate: `background information for test candidate`,
	candidate_answers: `Answers from candidate`,
	selectLanguage: `Select language`,
	norwegian: `Norwegian`,
	german: `German`,
	dutch: `Dutsch`,
	english: `English`,
	swedish: `Swedish`,
	usersupport: `User support`,
	groups: `Groups`,
	groupname: `Group name`,
	date: `Date`,
	insert: `Insert`,
	selected_users: `Selected users`,
	alreadyexistsingroup: `already exists in this group!`,
	orddiktatheading: `This test measures spelling skills and orthographic awareness. Here, the test candidate must write words that are read aloud. Each word is first presented in a sentence, to ensure that the correct meaning of the word is understood, also the target word is repeated, and the test candidate writes this. There is no time limit on this test, and each sentence and target word can be repeated once.`,
	orddiktatchildexplanation: `One point is awarded for each correct answer (maximum 13 points). The average score for {0} is {1} and the standard deviation is {2} .`,
	orddiktatvariabletext: `{0} obtained a score of {1} on this test. This score places {2} standard deviation from the mean of the step. This is a result that places itself`,
	orddiktatadultexplanation: `One point is awarded for each correct answer (maximum 45 points). The average score for {0} is {1} and the standard deviation is {2} .`,
	orddiktatyouthexplanation: `One point is awarded for each correct answer (maximum 30 points). The average score for {0} is {1} and the standard deviation is {2} .`,
	fonemisolasjonheading: `This test identifies phoneme awareness of the youngest test candidates. In this test, the test candidate is presented with four pictures, and is asked to point to the picture that either begins with, or ends with, a specific sound.`,
	fonemisolasjonexplanation: `One point is awarded for each correct answer (maximum 16 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	fonemisolasjonvariabletext: `{0} obtained a score of {1} on this test. This score places {2} standard deviation from the average for the grade. This is a result that places itself`,
	fonemutelatelseheading: `This test identifies phoneme awareness in primary school pupils. In this test, the test candidate is verbally presented with words, and is asked to say the word that is left when a particular sound is removed (ie / p / in plane => lane)`,
	fonemutelatelseexplanation: `One point is awarded for each correct answer (maximum 16 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	fonemutelatelsevariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	spoonerismheading: `This test identifies phoneme awareness. The test candidate is verbally presented with 20-word pairs and is asked to change the first sound of the two words as quickly and accurately as possible.`,
	spoonerismexplanation: `One point is awarded for each correct word the candidate manages to say with the new first sound (maximum 40 points). This test is also timed. The score is the number of correct new words identifies, divided by the time it takes to complete the tasks. The average score for {0} is {1} and the standard deviation is {2}.`,
	spoonerismvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	ettminuttstestheading: `This is a reading aloud test that identifies decoding skills. Here, the test candidate is presented with a list of words and is asked to read as quickly and accurately as possible within a time frame of 60 seconds. The result here gives a measure of the number of words read correctly per minute.`,
	ettminuttstestexplanation: `One point is awarded for each word read correctly. The average score for {0} is {1} and the standard deviation is {2}.`,
	ettminuttstestvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	hurtigbenevningheading: `This test identifies how quickly the test candidate manages to name known objects, and to retrieve names of objects and colors from the linguistic long-term memory.`,
	hurtigbenevningexplanation: `One point is awarded for each correct combination of color and object the candidate says. This test is timed. The score here is the number of correct color-object combinations identified divided by the total time it takes to name all 50 color-object combinations. The average score for {0} is {1} and the standard deviation is {2}.`,
	hurtigbenevningvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	arbeidsminnenummberheading: `This test identifies the ability of the test candidate to store and process information. In this test, the candidate is first presented with a series of numbers, then a series of words, which are to be repeated in reverse order. Both the number rows and the word rows increase in length if the candidate masters the previous row.`,
	arbeidsminneexplanation: `One point is awarded for each correct answer (maximum 12 points for the number rows and a maximum of 12 points for the word rows, a total of a maximum of 24 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	arbeidsminnevariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	arbeidsminneheading: `This test identifies the ability of the test candidate to store and process information. In this test, the candidate is first presented with a series of numbers, then a series of words, which are to be repeated in reverse order. Both the number rows and the word rows increase in length if the candidate masters the previous row.`,
	ordforståelseheading: `This test measures vocabulary in that the test candidate must find a word that has the same meaning as a given target word. For each item, the test candidate must choose from four options. This test provides a measure of the breadth and depth of the vocabulary of the candidate.`,
	ordforståelseexplanation: `One point is awarded for each correct answer (maximum 15 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	ordforståelsevariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	ordforståelseyouthexplanation: `One point is awarded for each correct answer (maximum 20 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	leseprøvenheading: `This test is a silent reading test that measures reading comprehension in that the test candidate must answer questions directly from the text, compile information from different sections and draw conclusions. The test candidate is given 10 minutes to read the text and answer as many multiple-choice questions as possible. N.B. Here it is important to register whether the test candidate has answered incorrectly or has not answered the multiple-choice questions (due to low reading speed).`,
	leseprøvenyouthheading: `This test is a silent reading test that measures reading comprehension in that the test candidate must answer questions directly from the text, compile information from different sections and draw conclusions. The test candidate is given 12 minutes to read the text and answer as many multiple-choice questions as possible. N.B. Here it is important to register whether the test candidate has answered incorrectly or has not answered the multiple-choice questions (due to low reading speed).`,
	leseprøvenexplanation: `One point is awarded for each correct answer (maximum 14 points). The average score for {0} is {1} and the standard deviation is {2}`,
	leseprøvenvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	leseprøvenyouthexplanation: `One point is awarded for each correct answer (maximum 9 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	skrivehastighetheading: `This test measures the effectiveness of writing. It is a skill that is needed when, for example, taking notes or writing an exam. The test candidate will have a visible sentence during the all the time of the test and must write it as many times and as accurately as possible within 2 minutes.`,
	skrivehastighetexplanation: `The score here is the number of correctly written words on average per minute. The average score for {0} is {1} and the standard deviation is {2}.`,
	skrivehastighetvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	tulleordtestenheading: `This test is a silent reading test that measures phonological awareness and decoding skills. The test is based on the test candidate identifying nonsense words that are pronounced as proper words and distinguishing them from nonsense words that are not pronounced as proper words. For each item, the test candidate must choose from five options, of which only one is correct. The test candidate is given two minutes to solve as many tasks as possible.`,
	tulleordtestenexplanation: `One point is awarded for each correct answer (maximum 25 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	tulleordtestenvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	ordkjedetestenheading: `This test is a silent reading test that measures rapid word recognition and orthographic knowledge. Here the test candidate is asked to make spaces between words that are written together into a chain. Each chain consists of four random words. The test candidate is given 4 minutes to solve as many word chains as possible.`,
	ordkjedetestenexplanation: `One point is awarded for each correct answer (maximum 40 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	ordkjedetestenvariabletext: `{0} obtained a score of {1} on this test. This score is placed {2} standard deviation from the average for the grade. This is a result that places itself`,
	ordkjedetestenyouthexplanation: `One point is awarded for each correct answer (maximum 36 points). The average score for {0} is {1} and the standard deviation is {2}.`,
	includeLongIntro: `Include self-assessment / anamnese`,
	anamneseexplaination: `If the test is only used for screening, it is not necessary to include self-assessment / anamnese`,
	chooselanguage: `Choose language`,
	seegrouptests: `See tests in groups`,
	addtogroup: `Add to group`,
	nogroupsfound: `No groups found`,
	fromgroups: `From group`,
	allmytests: `All my tests`,
	mytests: `My tests`,
	groupindicator: `-----Groups-----`,
	notanswered: `not answered`,
	testnotanswered: `{0} items not answered`,
	showunanswered: `Show unanswered`,
	hideunanswered: `Hide unanswered`,
	rightanswer: `Correct answered: {0}`,
	action: `Action`,
	deleteall: `Delete all`,
	chooseclass: `Choose class`,
	confirmationdeleteclass: `Are you sure you want to delete this {0}`,
	theclass: `Class`,
	multipleclasses: `classes`,
	deleteclass: `Delete class`,
	message_noclasses: `You have not created any classes`,
	showtests: `See tests ▼`,
	allmycandidates: `All my candidates`,
	notestsforcandidate: `This candidate has not completed any tests`,
	gender: `Sex`,
	nocandidates: `you have no candidates`,
	choosecandidate: `Choose a candidate`,
	classindicator: `-----Classes-----`,
	sendtest: `Send test`,
	createclass: `Create Class`,
	removefromclass: `Remove from class`,
	providevalidname: `Provide a valid name`,
	searchhere: `Search here...`,
	newclass: `New Class`,
	writeclassname: `Give the class a name…`,
	deleting: `Deleting`,
	mypage: `My page`,
	notavailable: `not available`,
	theagegroup: `age group`,
	hiddenname: `<Hidden name>`,
	wordreadcorrectly: `Correct words read`,
	correct: `Correct`,
	wrong: `Wrong`,
	timeused: `Time used`,
	thisagegroup: `age group`,
	adminpersonalinformation: `Personal information`,
	readingTest_questioncount: 9,
};

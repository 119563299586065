import {ENDPOINT_URL} from '../constants';
import getFetchOptions from '../utils/getFetchOptions';
import {apiErrorHandling} from '../utils/apiUtils';

const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function verifyAdminCustom(testAdmin) {
	return fetch(
		`${ENDPOINT_URL}/customverification`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(testAdmin),
		})
	)
		.then((response) => {
			if (!response.ok) {
				return response.json();
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}

import {authCallbackCreator, initAuth} from '../utils/bankIdLogin';
import {FEIDE} from '../constants';

export const RESET_AUTH = 'RESET_AUTH';
export const INIT_AUTH = 'INIT_AUTH';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_IFRAME_URL = 'SET_AUTH_IFRAME_URL';

export const resetAuthAction = (dispatch) => dispatch({type: RESET_AUTH});
export const initAuthAction = (
	authMethod,
	key,
	regToken,
	chosenMethod,
	changeIntent
) => {
	return (dispatch) => {
		dispatch({
			type: INIT_AUTH,
		});

		if (authMethod === 'Password') {
			dispatch({
				type: SET_AUTH_IFRAME_URL,
				iframeUrl: null,
				authMethod: authMethod,
			});
			return;
		}

		initAuth(authMethod, key, regToken, chosenMethod, changeIntent)
			.then((response) => {
				dispatch({
					type: SET_AUTH_IFRAME_URL,
					iframeUrl: response.redirect,
					authMethod: authMethod,
				});
				return new Promise((resolve, reject) => {
					const authCallback = authCallbackCreator(
						key,
						regToken,
						chosenMethod,
						changeIntent,
						resolve,
						reject
					);
					window.addEventListener('message', authCallback);
				});
			})
			.catch((response) => {
				const error = response.message || response.statusText;
				dispatch({
					type: SET_AUTH_ERROR,
					error,
				});
			});
	};
};

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = (user) => {
	return {
		type: SET_CURRENT_USER,
		user,
	};
};

import React from 'react';
import strings from '../../utils/strings';
class SubscriptionTable extends React.Component {
	constructor(props) {
		super(props);
	}

	convertID(id) {
		if (id === 'english') {
			return 'Engelsk';
		}
		if (id === 'swedish') {
			return 'Svensk';
		}
		if (id === 'norwegian') {
			return 'Norsk';
		}
		if (id === 'german') {
			return 'Tysk';
		}
		if (id === 'dutch') {
			return 'Dutch';
		}

		return 'Engelsk'; //default
	}

	render() {
		const isSuperAdmin = this.props.isSuperAdmin;
		const languages = this.props.languages;
		return (
			<div className="detailed-subscription">
				<div className="lit-subscription-table-container">
					<h3 className="detailed-subscription__heading">
						{strings.subscription}
					</h3>
					<table
						cellSpacing="0"
						className="lit-list__header lit-main-table"
					>
						<tbody>
							<tr className="lit-main-master-header">
								<th className="lit-main-language-header"></th>
								<th
									className="lit-main-production-header"
									colSpan={6}
								>
									{strings.production}
								</th>
								<th
									className="lit-main-norming-header"
									colSpan={3}
								>
									{strings.norming}
								</th>
								<th className="lit-main-norming-header">
									{strings.basic}
								</th>
								{isSuperAdmin && (
									<th className="lit-main-final-header"></th>
								)}
							</tr>
							<tr className="lit-main-secondary-header">
								<th></th>
								<th className="lit-main-subHeaders production_child">
									{strings.singleChild}
								</th>
								<th className="lit-main-subHeaders production_youth_screening">
									{strings.youth}
								</th>
								<th className="lit-main-subHeaders production_child">
									{strings.singleChild}
								</th>
								<th className="lit-main-subHeaders production_adult_screening">
									{strings.adult}
								</th>
								<th className="lit-main-subHeaders  production_youth_followup">
									{strings.youth}
								</th>
								<th className="lit-main-subHeaders  production_adult_followup">
									{strings.adult}
								</th>
								<th className="lit-main-subHeaders norming_child">
									{strings.singleChild}
								</th>
								<th className="lit-main-subHeaders norming_youth">
									{strings.youth}
								</th>
								<th className="lit-main-subHeaders norming_adult">
									{strings.adult}
								</th>
								<th
									className={
										isSuperAdmin
											? 'lit-main-subHeaders basic'
											: 'lit-admin-subHeaders basic'
									}
								></th>
								{isSuperAdmin && (
									<th className="lit-main-subHeaders fnal"></th>
								)}
							</tr>

							<tr className="lit-main-tertiary-header">
								<th className="lit-main-subHeaders language">
									{strings.language}
								</th>
								<td className="lit-main-subHeaders production_child">
									{strings.screening}
								</td>
								<td className="lit-main-subHeaders production_youth_screening">
									{strings.screening}
								</td>
								<td className="lit-main-subHeaders production_adult_screening">
									{strings.followup}
								</td>
								<td className="lit-main-subHeaders production_adult_screening">
									{strings.screening}
								</td>

								<td className="lit-main-subHeaders  production_youth_followup">
									{strings.followup}
								</td>
								<td className="lit-main-subHeaders  production_adult_followup">
									{strings.followup}
								</td>
								<td className="lit-main-subHeaders norming_child">
									---
								</td>
								<td className="lit-main-subHeaders norming_youth">
									---
								</td>
								<td className="lit-main-subHeaders norming_adult">
									---
								</td>
								<td
									className={
										isSuperAdmin
											? 'lit-main-subHeaders basic'
											: 'lit-admin-subHeaders basic'
									}
								>
									---
								</td>
								{isSuperAdmin && (
									<td className="lit-main-subHeaders final"></td>
								)}
							</tr>

							{languages.map((language) => {
								const {
									child,
									youth,
									adult,
									child_followup,
									youth_followup,
									adult_followup,
									norming_child,
									norming_youth,
									norming_adult,
									basic,
								} = language;
								return (
									<tr
										className="lit-list__item"
										key={language.id}
									>
										<td className="lit-list__col ">
											<div className="lit-list__text language">
												<b>
													{strings[language.language]}
												</b>
											</div>
										</td>
										{[
											// Unduplicates the code to render all the cells. To add more tests, simply add the condition to this list.
											child,
											youth,
											child_followup,
											adult,
											youth_followup,
											adult_followup,
											norming_child,
											norming_youth,
											norming_adult,
											basic,
										].map((value) => (
											<td className="lit-list__col">
												{value ? (
													<div className="tickmark large">
														L
													</div>
												) : (
													<div className="crossmark large">
														X
													</div>
												)}
											</td>
										))}
										{isSuperAdmin && (
											<td className="lit-list__col">
												<div className="lit-list__text final">
													<button
														className="lit-btn lit-btn--small bg-secondary"
														onClick={() => {
															this.props.onShowSubEditPanel(
																language
															);
														}}
													>
														{strings.edit}
													</button>
												</div>
											</td>
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default SubscriptionTable;

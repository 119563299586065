import React, {Component} from 'react';
import distributeKey from '../../api/distributeKey';
import classNames from 'classnames';
import exchangeToken from '../../api/exchangeToken';
import updateLogEvent from '../../api/updateLogEvent';
import deleteTest from '../../api/deleteTest';
import {updateObject, updateItemInArray} from '../../utils/reducerUtils';
import moment from 'moment';
import {Link} from 'react-router-dom';
import strings from '../../utils/strings';

import {
	NOT_STARTED,
	COMPLETED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
	CHILD_TEST_URL,
	getTestTypeName,
} from '../../constants';

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];
const disabledAccess = ['child-followup'];

const screeningTypes = [TEST_TYPES.ADULT_SCREENING, TEST_TYPES.YOUTH_SCREENING];

class TestRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			testResults: this.props.testResults,
			testResult: this.props.testResult,
			processTestResults: this.props.processTestResults,
		};
	}
	deleteTest = (e, id) => {
		e.preventDefault();

		deleteTest(id).then(() => {
			if (this.props.onDelete && !this.props.mainPanel) {
				this.props.onDelete();
			}
		});
	};

	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}

	sendCodeUsingEmail = (event, emailAddress) => {
		event.preventDefault();
		const {processTestResults, testResults} = this.props;
		const {id, key: testKey} = this.state.testResult;

		this.props.onDistributeTest(id, testKey, emailAddress);
	};

	getTestDateInfo(
		completedAt,
		usedAt,
		distributedAt,
		childSelfStarting,
		pinCode,
		type
	) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<p className="lit-test-no-margin">
						{strings.completed}
						<br />
						{moment(completedAt).format('LLL')}
						&nbsp;
					</p>
				</td>
			);
		} else if (usedAt) {
			if (pinCode && type === 'child-screening') {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							<br />
							<span>Pinkode er </span>
							<b>{pinCode}</b>{' '}
							<span className="lit-test-no-margin">
								{strings.started}
								<br />
								{moment(usedAt).format('LLL')}
								&nbsp;
							</span>
							{/* {moment(distributedAt).format('LLL')}
							&nbsp; */}
						</p>
					</td>
				);
			} else {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							{strings.started}
							<br />
							{moment(usedAt).format('LLL')}
							&nbsp;
						</p>
					</td>
				);
			}
		} else if (distributedAt) {
			if (
				!Boolean(childSelfStarting) &&
				type === 'child-screening' &&
				pinCode
			) {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							<span>{`Testen er klar, men ikke startet.`}</span>
							<br></br>
							<span>Pinkode er </span>
							<b>{pinCode}</b>
							<br />
							{/* {moment(distributedAt).format('LLL')}
							&nbsp; */}
						</p>
					</td>
				);
			} else {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							{strings.distributed}
							<br />
							{moment(distributedAt).format('LLL')}
							&nbsp;
						</p>
					</td>
				);
			}
		}

		return testDateInfo;
	}
	renderDeleteButton(usedAt, completedAt, testKey, id, status, groupStatus) {
		const completed = status === COMPLETED;
		const started = status === STARTED;
		let testDate = null;
		if ((completed || started) && !groupStatus) {
			return (
				<td className="lit-test__col">
					<div className="lit-test__field">
						<button
							className="lit-btn lit-btn--small bg-negative"
							onClick={(e) => {
								if (completedAt) {
									testDate = moment(
										completedAt,
										'YYYY-MM-DD'
									);
								} else {
									testDate = moment(usedAt, 'YYYY-MM-DD');
								}

								const timeDifference = moment().diff(
									testDate,
									'days'
								);

								if (timeDifference > 90) {
									const key = {testKey};
									this.props.onRenderDynamicMessagePane(
										strings.warning_deletetest,
										id,
										key
									);
								} else {
									this.props.onRenderStaticMessagePane(
										strings.message_testcannotbedeleted
									);
								}
							}}
						>
							{strings.delete}
						</button>
					</div>
				</td>
			);
		}
		if (status === NOT_STARTED && !groupStatus) {
			return null;
		}
		return (
			<td className="lit-test__col">
				<div className="lit-test__field"> </div>{' '}
			</td>
		);
	}
	renderButton(type, id, status, testKey, groupStatus) {
		const completed = status === COMPLETED;
		const started = status === STARTED;

		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: type === 'child-experimental'
			? `/single-speech-test/${id}`
			: `/single-test/${id}`;

		if (completed || started /* && !disabledAccess.includes(type) */) {
			return (
				<div className="lit-see-result-fixed-width">
					<Link
						className="lit-btn lit-btn--small bg-secondary"
						to={destination}
						onClick={() => this.handleClick(id, testKey)}
					>
						{strings.seeresults}
					</Link>
				</div>
			);
		} else if (completed && type === 'child-screening') {
			//Made dormant for now
			if (this.props.isSuperAdmin) {
				return (
					<div className="lit-see-result-fixed-width">
						<Link
							className="lit-btn lit-btn--small bg-secondary"
							to={destination}
							onClick={() => this.handleClick(id, testKey)}
						>
							{strings.seeresults}
						</Link>
					</div>
				);
			} else {
				return (
					<button
						className="lit-btn lit-btn--small bg-secondary"
						disabled
						onClick={(e) => {
							/* this.props.onContinueTest(e, id, testKey); */
						}}
					>
						{strings.seeresults}
					</button>
				);
			}
		} else if (completed && type === 'child-followup') {
			if (this.props.isSuperAdmin) {
				return (
					<div className="lit-see-result-fixed-width">
						<Link
							className="lit-btn lit-btn--small bg-secondary"
							to={destination}
							onClick={() => this.handleClick(id, testKey)}
						>
							{strings.seeresults}
						</Link>
					</div>
				);
			} else {
				return (
					<button
						className="lit-btn lit-btn--small bg-secondary"
						disabled
						onClick={(e) => {
							/* this.props.onContinueTest(e, id, testKey); */
						}}
					>
						{strings.seeresults}
					</button>
				);
			}
		} else if (started && !groupStatus && type === 'child-screening') {
			//Dormant for Now
			return (
				<button
					className="lit-btn lit-btn--small bg-secondary-green"
					disabled={groupStatus}
					onClick={(e) => {
						this.props.onContinueTest(e, id, testKey);
					}}
				>
					{strings.continue}
				</button>
			);
		} else if (status === NOT_STARTED && !groupStatus) {
			return (
				<button
					className="lit-btn lit-btn--small bg-negative"
					disabled={groupStatus}
					onClick={(e) => {
						this.deleteTest(e, id);
					}}
				>
					{strings.delete}
				</button>
			);
		}
		return null;
	}

	render() {
		const {testResult} = this.props;
		const groupStatus = this.props.groupPanel;

		const {
			id,
			key: testKey,
			testUser,
			status,
			usedAt,
			completedAt,
			distributedAt,
			recipient,
			type,
			pinCode,
			childSelfStarting,
		} = testResult;

		let email;
		let firstName;
		let lastName;
		let fullName;
		if (testUser && testUser.firstName) {
			email = testUser.email;
			firstName = testUser.firstName;
			lastName = testUser.lastName;
			fullName = `${firstName} ${lastName}`;
		} else {
			email = recipient;
		}
		if (
			!fullName &&
			testResult.info &&
			type === 'child-screening' &&
			!childSelfStarting &&
			distributedAt
		) {
			const unParsedinfo = testResult.info;
			const info = JSON.parse(unParsedinfo);
			fullName = `${info.firstName} ${info.lastName}`;
		}
		if (
			!fullName &&
			type === 'child-screening' &&
			childSelfStarting &&
			distributedAt
		) {
			fullName = email || recipient;
		}
		let emailInviteRef;

		return (
			<tr className="lit-test" key={testKey}>
				{!this.props.groupPanel && (
					<td className="lit-test__col">
						<div>
							<input
								type="checkbox"
								className="user_checkbox2"
								defaultChecked={false}
								key={testKey}
								onChange={(e) => {
									if (e.target.checked) {
										this.props.onGettingTestKey(testKey);
									} else {
										this.props.onRemovingTestKey(testKey);
									}
								}}
							/>
						</div>
					</td>
				)}
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<p className="lit-test-status__label">{status.label}</p>
					</div>
				</td>
				<td className="lit-test__col">{`${testKey}`}</td>
				<td className="lit-test__col">{getTestTypeName(type)}</td>
				<td className="lit-test__col">
					{fullName || email || <b>{strings.notdistributed}</b>}
					&nbsp;
				</td>
				{status === NOT_STARTED &&
					Boolean(childSelfStarting) &&
					type === 'child-screening' && (
						<td className="lit-test__col">
							<div className="lit-test__field">
								<label
									className="lit-test__heading"
									htmlFor={`email-invite-${id}`}
								>
									{strings.sendcodeemail}
								</label>
								<form
									className="lit-input inline"
									onSubmit={(e) => {
										this.sendCodeUsingEmail(
											e,
											emailInviteRef.value
										);
									}}
								>
									<input
										className="lit-input__field"
										id={`email-invite-${id}`}
										name={`emailAddress-${id}`}
										placeholder="ola@epost.no"
										ref={(ref) => {
											emailInviteRef = ref;
										}}
										required
										type="email"
									/>
									<div className="lit-input inline">
										<button className="lit-btn lit-btn--tiny bg-primary">
											{strings.send}
										</button>
									</div>
								</form>
							</div>
						</td>
					)}
				{this.getTestDateInfo(
					completedAt,
					usedAt,
					distributedAt,
					childSelfStarting,
					pinCode,
					type
				)}
				{status === NOT_STARTED && screeningTypes.includes(type) && (
					<td className="lit-test__col">
						<div className="lit-test__field">
							<label
								className="lit-test__heading"
								htmlFor={`email-invite-${id}`}
							>
								{strings.sendcodeemail}
							</label>
							<form
								className="lit-input inline"
								onSubmit={(e) => {
									this.sendCodeUsingEmail(
										e,
										emailInviteRef.value
									);
								}}
							>
								<input
									className="lit-input__field"
									id={`email-invite-${id}`}
									name={`emailAddress-${id}`}
									placeholder="ola@epost.no"
									ref={(ref) => {
										emailInviteRef = ref;
									}}
									required
									type="email"
								/>
								<div className="lit-input inline">
									<button className="lit-btn lit-btn--tiny bg-primary">
										{strings.send}
									</button>
								</div>
							</form>
						</div>
					</td>
				)}
				{status === NOT_STARTED && type === 'child-experimental' && (
					<td className="lit-test__col">
						<div className="lit-test__field">
							<label
								className="lit-test__heading"
								htmlFor={`email-invite-${id}`}
							>
								{strings.sendcodeemail}
							</label>
							<form
								className="lit-input inline"
								onSubmit={(e) =>
									this.sendCodeUsingEmail(
										e,
										emailInviteRef.value
									)
								}
							>
								<input
									className="lit-input__field"
									id={`email-invite-${id}`}
									name={`emailAddress-${id}`}
									placeholder="ola@epost.no"
									ref={(ref) => {
										emailInviteRef = ref;
									}}
									required
									type="email"
								/>
								<div className="lit-input inline">
									<button className="lit-btn lit-btn--tiny bg-primary">
										{strings.send}
									</button>
								</div>
							</form>
						</div>
					</td>
				)}
				{status === NOT_STARTED && childTypes.includes(type) && (
					<td className="lit-test__col">
						<div className="lit-test__field">
							<button
								className="lit-btn lit-btn--tiny bg-primary"
								onClick={() => {
									exchangeToken(testKey).then((token) => {
										window.open(
											`${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${token}&type=${type}`,
											'_blank'
										);
										if (this.props.onStartTest) {
											this.props.onStartTest();
										}
									});
								}}
							>
								{strings.starttest}
							</button>
						</div>
					</td>
				)}
				{status === NOT_STARTED &&
					!Boolean(childSelfStarting) &&
					type === 'child-screening' && (
						<td className="lit-test__col">
							<div className="lit-test__field">
								<button
									className="lit-btn lit-btn--tiny bg-primary"
									onClick={() => {
										this.props.onshowInfoPanel(
											testKey,
											id,
											pinCode
										);
									}}
								>
									{strings.fillOutInfo}
								</button>
							</div>
						</td>
					)}

				<td className="lit-test__col">
					<div className="lit-test__field">
						{this.renderButton(
							type,
							id,
							status,
							testKey,
							groupStatus
						)}
					</div>
				</td>

				{this.renderDeleteButton(
					usedAt,
					completedAt,
					testKey,
					id,
					status,
					groupStatus
				)}
			</tr>
		);
	}
}

export default TestRow;

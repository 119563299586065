const TEN = 10;
export default function roundWithDecimals(number, decimals) {
	if (!Number.isFinite(number)) {
		throw new Error(`Number ${number} is not a finite number`);
	}

	if (!Number.isFinite(decimals)) {
		throw new Error(`Decimals ${decimals} is not a finite number`);
	}

	if (decimals < 0) {
		throw new Error(`Decimals cannot be less than 0`);
	}

	const firstMultiplier = Math.pow(TEN, decimals);
	const secondMultiplier = Math.pow(TEN, decimals * -1);
	// const result = Math.round(number * firstMultiplier) * secondMultiplier;

	const result = Math.round(number * firstMultiplier) * secondMultiplier;
	const fixed = result.toFixed(decimals);

	const resultToString = fixed.toString();

	// const pointIndex = resultToString.indexOf('.');
	// if (pointIndex !== -1) {
	// 	const slicedResultString = resultToString.slice(0, pointIndex + 3);
	// 	return parseFloat(slicedResultString).toFixed(decimals);
	// }

	return resultToString;
}

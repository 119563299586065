import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import createAuthToken from '../../api/createAuthToken';

import './styles.scss';

import {BANK_ID, BANK_ID_MOBILE, FEIDE} from '../../constants';

import {initAuthAction} from '../../actions//index';
import strings from '../../utils/strings';

const testKey = null;
let feidSelection = false;
class VerifyCurrentLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			authToken: null,
			authError: null,
		};

		this.createdAndFetchRegistrationToken = this.createdAndFetchRegistrationToken.bind(
			this
		);
	}

	componentWillMount() {
		this.createdAndFetchRegistrationToken();
	}
	async createdAndFetchRegistrationToken() {
		const adminId = this.props.testAdmin.id;
		const email = this.props.testAdmin.email;

		createAuthToken(adminId, email).then((res) => {
			this.setState({
				authToken: res[0].UUID,
				authError: this.props.error,
			});
		});
	}

	render() {
		const {error, iframeUrl, initAuth, isInit} = this.props;
		const {authToken} = this.state;
		const availableMethods = this.props.availableMethods;
		const showIframe = isInit && iframeUrl;
		return (
			<div
				className="lit-modal verifyLogin"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-verifyLogin"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					{!isInit && (
						<div className="lit-login__wrapper bg-white">
							<h2 className="lit-login__header">
								Bytt innlogging
							</h2>
							<p className="lit-login__description">
								Du har nå valgt å bytte til en annet
								identifiseringsmetode. Vennligst start med å
								identifisere deg selv.
							</p>

							<div className="lit-login__action-wrapper">
								<button
									className="lit-btn bg-primary"
									onClick={() =>
										initAuth(
											BANK_ID,
											null,
											authToken,
											this.props.chosenMethod,
											'verify'
										)
									}
								>
									{strings.bankid}
								</button>
								<button
									className="lit-btn bg-primary bg-border"
									onClick={() =>
										initAuth(
											BANK_ID_MOBILE,
											testKey,
											authToken,
											this.props.chosenMethod,
											'verify'
										)
									}
								>
									{strings.bankidonmobile}
								</button>
							</div>
						</div>
					)}
					{error && (
						<div className="lit-change-login-error">
							<p className="find-me">
								Unable to change login Method. <br />
								Error:&nbsp;
								<strong>{error}.</strong>
								<br />
								Please try again later
							</p>
						</div>
					)}

					{showIframe && !feidSelection && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									width: '100%',
									minHeight: '200px',
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

VerifyCurrentLogin.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCurrentLogin);

import React from 'react';
import './styles.scss';
import getLangagueTexts from '../../api/getLangagueTexts';
import editLocalStrings from '../../api/editLocalStrings';
import updateLanguageStrings from '../../api/updateLanguageStrings';
import StringsCatalog from './StringsCatalog';
import EditStrings from './EditStrings';
import ChangeRequests from './ChangeRequests';

import strings from '../../utils/strings';

const languageNameArray = ['Norsk', 'Svensk', 'Tysk', 'Nynorsk', 'Engelsk'];
const moduleNameArray = ['Backoffice', 'Followup', 'Screening'];

const relevantFiles = {
	TyskFollowup: 'deFileFollowup',
	NorskFollowup: 'noFileFollowup',
	SvenskFollowup: 'seFileFollowup',
	NynorskFollowup: 'nnFileFollowup',
	EngelskFollowup: 'enFileFollowup',

	NorskBackoffice: 'noBackOffice',
	TyskBackoffice: 'deBackOffice',
	SvenskBackoffice: 'seBackOffice',
	NynorskBackoffice: 'nnBackOffice',
	EngelskBackoffice: 'enBackOffice',

	NorskScreening: 'noScreening',
	TyskScreening: 'deScreening',
	SvenskScreening: 'seScreening',
	NynorskScreening: 'nnScreening',
	EngelskScreening: 'enScreening',
};

const defaultFilesForComparison = [
	'noBackOffice',
	'deBackOffice',
	'seBackOffice',
	'nnBackOffice',

	'noFileFollowup',
	'deFileFollowup',
	'seFileFollowup',
	'nnFileFollowup',
	,
	'noScreening',
	'deScreening',
	'seScreening',
	'nnScreening',
];

let defaultCheckedStringsIndexes = [];

class LanguageStrings extends React.Component {
	constructor() {
		super();
		this.state = {
			activeLanguageFile: [], //Stores all the groups related data fetched from the back-end
			focusedGroup: '', //Points to the group that is in focus when user clicks 'Endre-gruppen' button
			newName: '',
			deFileFollowup: null,
			noFileFollowup: null,
			seFileFollowup: null,
			noBackOffice: null,
			deBackOffice: null,
			seBackOffice: null,
			deScreening: null,
			noScreening: null,
			enScreening: null,
			seScreening: null,
			nnScreening: null,
			checkedStringsIndexes: [],
			selectedLanguage: '',
			selectedCodeBase: 'Backoffice',
			hasSelectedLanguage: false,
			selectedLanguage: 'Norsk',
			searchFilter: null,
			filesForComparison: null,
			isEditingStrings: false,
			selectedStringForEditing: null,
			stringToChange: null,
			newStrings: {},
			changeRequests: null,
			showChangeRequests: false,
		};
	}
	componentDidMount() {
		this.getAndSetLanguages();
	}

	getAndSetLanguages() {
		return getLangagueTexts().then((res) => {
			const {
				deFileFollowup,
				noFileFollowup,
				seFileFollowup,
				noBackOffice,
				deBackOffice,
				seBackOffice,
				deScreening,
				noScreening,
				seScreening,
				nnScreening,
				newStrings,
			} = res;
			const languageArray = this.showLanguageStrings(noBackOffice);
			this.setState({
				activeLanguageFile: languageArray,
				deFileFollowup,
				noFileFollowup,
				seFileFollowup,
				noBackOffice,
				deBackOffice,
				seBackOffice,
				deScreening,
				seScreening,
				noScreening,
				nnScreening,
				newStrings,
			});
		});
	}

	showLanguageStrings(stringDatga) {
		const languageArray = [];
		var obj = {};
		let i = 0;
		for (const property in stringDatga) {
			let objKey = property;
			let objValue = stringDatga[property];
			obj['name'] = objKey;
			obj['data'] = objValue;
			obj['isChecked'] = false;
			obj['id'] = i;
			languageArray[i] = obj;
			i = i + 1;
			obj = {};
		}

		for (let i = 0; i < languageArray.length; i++) {
			languageArray[i].id = i;
		}

		return languageArray;
	}

	renderRelevantStringsFiles(langVal, codeBaseVal) {
		const {selectedCodeBase, selectedLanguage} = this.state;
		const codeBase = selectedLanguage || codeBaseVal;
		const moduleToRender = `${selectedLanguage || langVal}${
			selectedCodeBase || codeBase
		}`;
		const fileName = relevantFiles[moduleToRender];
		let relevantFile = this.state[fileName];
		const languageArray = this.showLanguageStrings(relevantFile);

		defaultCheckedStringsIndexes = [];

		this.setState({
			activeLanguageFile: languageArray,
			checkedStringsIndexes: defaultCheckedStringsIndexes,
		});
	}

	getFilteredLanguageFiles() {
		let filteredActiveLanguageFile = this.state.activeLanguageFile.length
			? this.state.activeLanguageFile
			: [];
		if (!this.state.searchFilter) {
			return filteredActiveLanguageFile;
		} else if (isNaN(this.state.searchFilter)) {
			filteredActiveLanguageFile = filteredActiveLanguageFile.filter(
				(languageFile) => {
					const searchableFields = ['name', 'data'];

					for (const field of searchableFields) {
						if (
							!languageFile[field]
								.toLowerCase()
								.indexOf(this.state.searchFilter)
						) {
						}
						if (
							languageFile[field] &&
							languageFile[field]
								.toLowerCase()
								.indexOf(this.state.searchFilter) !== -1
						) {
							return true;
						}
					}

					return false;
				}
			);
			return filteredActiveLanguageFile;
		} else {
			filteredActiveLanguageFile = filteredActiveLanguageFile.filter(
				(languageFile) => {
					const searchableFields = ['id'];

					for (const field of searchableFields) {
						if (
							languageFile[field] &&
							languageFile[field] == this.state.searchFilter
						) {
							return true;
						}
					}
					return false;
				}
			);
			return filteredActiveLanguageFile;
		}
	}

	findMissingStringAcrossLanguages() {
		const {selectedCodeBase} = this.state;
		const fileModuleNamesArray = [];
		const keyHolderGloabl = [];
		const filesForComparison = [];
		const commonStringskeyHolder = [];

		const moduleNames = [];
		for (let i = 0; i < languageNameArray.length; i++) {
			let name = `${languageNameArray[i]}${selectedCodeBase}`;
			moduleNames.push(name);
		}

		for (const property in relevantFiles) {
			let objKey = property;
			let objValue = relevantFiles[property];
			if (moduleNames.includes(objKey)) {
				filesForComparison.push(objValue);
				fileModuleNamesArray.push({
					fileName: objKey,
					fileValue: this.state[objValue],
				});
			}
		}
		for (let i = 0; i < fileModuleNamesArray.length; i++) {
			let includedIn = ['void1', 'void2', 'void3', 'void4', 'void5'];
			let fileName = fileModuleNamesArray[i].fileName;
			let fileContents = fileModuleNamesArray[i].fileValue;
			let makeUpObject = {instance: 0};
			for (const property in fileContents) {
				let objKey = property;
				if (!keyHolderGloabl.includes(objKey)) {
					makeUpObject.instance = makeUpObject.instance + 1;
					includedIn[i] = fileName;
					makeUpObject.includedIn = includedIn;
					makeUpObject.stringName = objKey;
					keyHolderGloabl.push(objKey);
					commonStringskeyHolder.push(makeUpObject);

					makeUpObject = {instance: 0};
					includedIn = ['void1', 'void2', 'void3', 'void4', 'void5'];
				} else {
					for (let k = 0; k < commonStringskeyHolder.length; k++) {
						if (commonStringskeyHolder[k].stringName === objKey) {
							let newInstance =
								commonStringskeyHolder[k].instance;
							commonStringskeyHolder[k].instance =
								newInstance + 1;

							includedIn = commonStringskeyHolder[k].includedIn;
							includedIn[i] = fileName;

							commonStringskeyHolder[k].includedIn = [
								...new Set(includedIn),
							];
						}
					}
				}
			}
		}
		this.setState({
			stringsCatalog: commonStringskeyHolder,
			filesForComparison,
		});
	}
	returnIndexToSplice(defaultCheckedStringsIndexes, keyName) {
		let indexVal = null;

		for (let i = 0; i < defaultCheckedStringsIndexes.length; i++) {
			if (defaultCheckedStringsIndexes[i] === keyName) {
				indexVal = i;
				break;
			}
		}
		return indexVal;
	}

	prepareStringsForEditing() {
		const selectedCodeBase = this.state.selectedCodeBase;

		const fileModuleNamesArray = [];
		const keyHolderGloabl = [];
		const filesForComparison = [];
		const commonStringskeyHolder = [];

		const moduleNames = [];
		for (let i = 0; i < languageNameArray.length; i++) {
			let name = `${languageNameArray[i]}${selectedCodeBase}`;
			moduleNames.push(name);
		}
		for (const property in relevantFiles) {
			let objKey = property;
			let objValue = relevantFiles[property];
			if (moduleNames.includes(objKey)) {
				filesForComparison.push(objValue);
				fileModuleNamesArray.push({
					fileName: objKey,
					fileValue: this.state[objValue]
						? this.state[objValue]
						: null,
				});
			}
		}

		for (let i = 0; i < this.state.checkedStringsIndexes.length; i++) {
			for (let j = 0; j < fileModuleNamesArray.length; j++) {
				let fileName = fileModuleNamesArray[j].fileName;
				let fileContents = fileModuleNamesArray[j].fileValue;
				let makeUpObject = {};

				if (
					fileContents &&
					fileContents.hasOwnProperty(
						this.state.checkedStringsIndexes[i]
					)
				) {
					for (const property in fileContents) {
						let objKey = property;
						if (this.state.checkedStringsIndexes.includes(objKey)) {
							let objData = fileContents[property];

							makeUpObject.name = objKey;
							makeUpObject.lang = fileName;
						}
					}
				}
			}
		}

		for (let i = 0; i < fileModuleNamesArray.length; i++) {
			let includedIn = ['void1', 'void2', 'void3', 'void4', 'void5'];
			let languageTexts;
			let fileName = fileModuleNamesArray[i].fileName;
			let fileContents = fileModuleNamesArray[i].fileValue;
			let makeUpObject = {instance: 0};
			for (const property in fileContents) {
				let objKey = property;
				if (this.state.checkedStringsIndexes.includes(objKey)) {
					if (!keyHolderGloabl.includes(objKey)) {
						makeUpObject.instance = makeUpObject.instance + 1;
						includedIn[i] = fileName;
						makeUpObject.includedIn = includedIn;
						makeUpObject.stringName = objKey;
						keyHolderGloabl.push(objKey);
						commonStringskeyHolder.push(makeUpObject);

						makeUpObject = {instance: 0};
						includedIn = [
							'void1',
							'void2',
							'void3',
							'void4',
							'void5',
						];
					} else {
						for (
							let k = 0;
							k < commonStringskeyHolder.length;
							k++
						) {
							if (
								commonStringskeyHolder[k].stringName === objKey
							) {
								let newInstance =
									commonStringskeyHolder[k].instance;
								commonStringskeyHolder[k].instance =
									newInstance + 1;

								includedIn =
									commonStringskeyHolder[k].includedIn;
								includedIn[i] = fileName;

								commonStringskeyHolder[k].includedIn = [
									...new Set(includedIn),
								];
							}
						}
					}
				}
			}
		}

		this.setState({
			selectedStringForEditing: commonStringskeyHolder,
			isEditingStrings: true,
			filesForComparison,
		});
	}

	getAndSetLanguageFile(keyName, codeBase, language, scenario) {
		let languageFile;
		let relevantFile;
		let langObj = {};
		switch (scenario) {
			case 'edit':
				languageFile = `${language}${codeBase}`;

				relevantFile = this.state[relevantFiles[languageFile]];

				langObj['keyName'] = keyName;
				langObj['keyValue'] = relevantFile[keyName];
				langObj['codeBase'] = codeBase;
				langObj['fileName'] = relevantFiles[languageFile];
				langObj['fileContent'] = relevantFile;
				langObj['language'] = language;
				break;
			case 'add':
				languageFile = `${language}${codeBase}`;
				relevantFile = this.state[relevantFiles[languageFile]];

				langObj['keyName'] = keyName;
				langObj['keyValue'] = '';
				langObj['codeBase'] = codeBase;
				langObj['fileName'] = relevantFiles[languageFile];
				langObj['language'] = language;
				if (relevantFile && relevantFile.hasOwnProperty(keyName)) {
					langObj['fileContent'] = relevantFile;
				} else {
					langObj['fileContent'] = '';
				}

				break;
		}
		this.setState({
			stringToChange: langObj,
		});
	}

	sendChangedRequests(changeRequests) {
		if (changeRequests) {
			return editLocalStrings(changeRequests).then((res) => {
				this.setState({
					isEditingStrings: false,
					stringToChange: null,
				});
			});
		}
	}
	organizeChangeRequests() {
		let temArray = [];
		const permArray = [];
		let outerObj = {};
		let innerObj = {};
		const {newStrings} = this.state;

		for (const property in newStrings) {
			outerObj['fileName'] = property;
			for (const innerPropery in newStrings[property]) {
				innerObj['name'] = innerPropery;
				innerObj['data'] = newStrings[property][innerPropery];

				temArray.push(innerObj);

				innerObj = {};
			}
			outerObj['newStrings'] = temArray;
			temArray = [];
			permArray.push(outerObj);
			outerObj = {};
		}
		this.setState({
			changeRequests: permArray,
			showChangeRequests: true,
		});
	}

	setUpdateLanguageFile(fileName, newFileContents, changeRequests) {
		return updateLanguageStrings(
			fileName,
			newFileContents,
			changeRequests
		).then((res) => {
			if (res.ok) {
				this.setState(
					this.returnUpdateStateValue(
						fileName,
						newFileContents,

						changeRequests
					)
				);
			}
		});
	}

	returnUpdateStateValue(fileName, newFileContents, changeRequests) {
		let obj = {};
		obj[fileName] = newFileContents;
		obj['newStrings'] = changeRequests;
		return obj;
	}

	render() {
		const {activeLanguageFile} = this.state;

		const filteredLanguageFiles = this.getFilteredLanguageFiles();
		return (
			<div>
				<div className="lit-lang-opitons-wrapper">
					<div>
						<select
							className="lit-input__field"
							defaultValue={'Norsk'}
							name="group"
							onChange={(event) => {
								const selectedLanguage = event.target.value;
								this.setState(
									{
										hasSelectedLanguage: true,
										selectedLanguage,
									},
									() => {
										this.renderRelevantStringsFiles(
											selectedLanguage,
											null
										);
									}
								);
							}}
						>
							{' '}
							<option key={1} value={'Valg språk'} disabled>
								{strings.chooselanguage}
							</option>
							{languageNameArray.map((language) => (
								<option key={language} value={language}>
									{language}
								</option>
							))}
						</select>
					</div>

					<div>
						<select
							className="lit-input__field"
							defaultValue={'Valg språk'}
							name="group"
							onChange={(event) => {
								const selectedModule = event.target.value;
								this.setState(
									{
										selectedCodeBase: selectedModule,
									},
									() => {
										this.renderRelevantStringsFiles(
											null,
											selectedModule
										);
									}
								);
							}}
						>
							{' '}
							<option key={1} value={'Valg module'} disabled>
								{'Velg module'}
							</option>
							{moduleNameArray.map((language) => (
								<option key={language} value={language}>
									{language}
								</option>
							))}
						</select>
					</div>
					<div>
						<div className="lit-input_searchUser_main">
							<input
								className="lit-tf_search"
								type="text"
								placeholder={strings.searchhere}
								id="key"
								onChange={(e) => {
									this.setState({
										searchFilter: e.target.value.toLowerCase(),
									});
								}}
							/>
						</div>
					</div>
				</div>
				<div className="lit-lang-buttons-wrapper">
					<div className="lit-lang-uncommon">
						<button
							className="lit-lang-uncommon__bg"
							onClick={() => {
								this.findMissingStringAcrossLanguages();
							}}
						>
							Broad view
						</button>
					</div>
					<div className="lit-lang-change">
						<button
							className="lit-lang-change__bg"
							onClick={() => {
								if (!this.state.checkedStringsIndexes.length) {
									alert('First select something ');
								} else {
									this.prepareStringsForEditing();
								}
							}}
						>
							Change Strings
						</button>
					</div>
					{Boolean(Object.keys(this.state.newStrings).length) && (
						<div className="lit-lang-change">
							<button
								className="lit-lang-change__bgO"
								onClick={() => {
									this.organizeChangeRequests();
								}}
							>
								Change Requests
							</button>
						</div>
					)}
				</div>

				<div className="lit-lang-log">
					<table>
						<tbody>
							{Boolean(filteredLanguageFiles.length) &&
								filteredLanguageFiles.map((text, index) => {
									const {id} = text;

									return (
										<tr
											className="lit-event-log__entry"
											key={id}
										>
											<td>
												<input
													className="user_checkbox"
													type="checkbox"
													checked={text.isChecked}
													id={
														`${this.state.selectedCodeBase}${this.state.selectedLanguage}${id}`
														/* this.state
														.languageSubscription.id */
													}
													key={`${this.state.selectedCodeBase}${this.state.selectedLanguage}${id}`}
													onChange={(event) => {
														if (
															event.target.checked
														) {
															if (
																defaultCheckedStringsIndexes.length <
																5
															) {
																activeLanguageFile[
																	id
																].isChecked = true;
																defaultCheckedStringsIndexes.push(
																	text.name
																);
															} else {
																alert(
																	'Du har bare lov til å endre 5 strenger om gangen.'
																);
															}
														} else {
															activeLanguageFile[
																id
															].isChecked = false;
															const indexToSplice = this.returnIndexToSplice(
																defaultCheckedStringsIndexes,
																text.name
															);

															defaultCheckedStringsIndexes.splice(
																indexToSplice,
																1
															);
														}

														this.setState({
															checkedStringsIndexes: defaultCheckedStringsIndexes,
														});
													}}
												/>
											</td>
											<td
												style={{color: 'blue'}}
											>{`${id}: `}</td>
											<td>{text.name}</td>
											<td>{text.data}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
					{!Boolean(filteredLanguageFiles.length) && (
						<div>
							<br></br>
							<h3>Not set up yet</h3>
						</div>
					)}
				</div>
				{this.state.stringsCatalog && (
					<StringsCatalog
						closeWindow={() => {
							this.setState({
								stringsCatalog: false,
							});
						}}
						allStringsCatalog={this.state.stringsCatalog}
						filesForComparison={this.state.filesForComparison}
						currentCodeBase={this.state.selectedCodeBase}
					/>
				)}
				{this.state.isEditingStrings && (
					<EditStrings
						currentCodeBase={this.state.selectedCodeBase}
						allStringsCatalog={this.state.stringsCatalog}
						closeWindow={() => {
							this.setState({
								isEditingStrings: false,
								stringToChange: null,
							});
						}}
						allStringsCatalog={this.state.selectedStringForEditing}
						filesForComparison={this.state.filesForComparison}
						stringToChange={this.state.stringToChange}
						fetchLanguageFile={(
							keyName,
							codebase,
							language,
							scenario
						) => {
							this.getAndSetLanguageFile(
								keyName,
								codebase,
								language,
								scenario
							);
						}}
						sendChangeRequests={(changeRequests) => {
							this.sendChangedRequests(changeRequests);
						}}
						newStrings={this.state.newStrings}
					/>
				)}
				{this.state.showChangeRequests && (
					<ChangeRequests
						allStringsCatalog={this.state.selectedStringForEditing}
						closeWindow={() => {
							this.setState({
								showChangeRequests: false,
								stringToChange: null,
							});
						}}
						filesForComparison={this.state.filesForComparison}
						fetchLanguageFile={(
							keyName,
							codebase,
							language,
							scenario
						) => {
							this.getAndSetLanguageFile(
								keyName,
								codebase,
								language,
								scenario
							);
						}}
						setAndUpdateLanguageFiles={(
							fileName,
							newFileContents,
							changeRequests
						) => {
							this.setUpdateLanguageFile(
								fileName,
								newFileContents,
								changeRequests
							);
						}}
						newStrings={this.state.newStrings}
						deFileFollowup={this.state.deFileFollowup}
						noFileFollowup={this.state.noFileFollowup}
						seFileFollowup={this.state.seFileFollowup}
						noBackOffice={this.state.noBackOffice}
						deBackOffice={this.state.deBackOffice}
						seBackOffice={this.state.seBackOffice}
						deScreening={this.state.deScreening}
						seScreening={this.state.seScreening}
						noScreening={this.state.noScreening}
						nnScreening={this.state.nnScreening}
						changeRequests={this.state.changeRequests}
					/>
				)}
			</div>
		);
	}
}

export default LanguageStrings;

/* eslint-disable camelcase */

export default {
	mytest: `Mein Test`,
	myCandidates: `Meine Kandidaten`,
	createnewtest: `Neuen Test erstellen`,
	started: `Gestartet`,
	starttest: `Test beginnen`,
	all: `Alle`,
	chooseagroup: `Gruppe auswählen`,
	distributed: `Verteilt`,
	notdistributed: `Nicht verteilt`,
	notstarted: `Nicht gestartet`,
	completed: `abgeschlossen`,
	completeplural: `abgeschlossen:`,
	seeresults: `Ergebnisse ansehen`,
	status: `Status`,
	testkey: `Testschlüssel`,
	type: `Typ`,
	recipient: `Empfänger`,
	actions: `Aktionen`,
	send: `Senden`,
	save: `Speichern`,
	sendcodeemail: `Code per E-Mail senden`,
	january: `Januar`,
	february: `Februar`,
	march: `März`,
	april: `April`,
	may: `Mai`,
	june: `Juni`,
	juli: `Juli`,
	august: `August`,
	september: `September`,
	october: `Oktober`,
	november: `November`,
	december: `Dezember`,
	norway: `Norwegen`,
	sweden: `Schweden`,
	germany: `Deutschland`,
	england: `Großbritannien`,
	nederland: `Niederlande`,
	message_testcannotbedeleted: `Der Test muss mindestens 3 Monate alt sein, um gelöscht zu werden.`,
	warning_deletetest: `Der Test wird nicht länger für die Diagnostik verfügbar sein. Möchtest du fortfahren?`,
	headerchildtest: `Testergebnis für Dysmate – C (Test für Kinder)`,
	dynamicheaderfortestresult_child: `Detaillierte Ergebnisse für Dysmate {0}`,
	dynamicheaderfortestreport_child: `Detaillierter Bericht für Dysmate {0}`,
	dynamicheaderfortestcandidate_child: `Detaillierte Informationen für Dysmate Kandidat {0}`,
	dynamicheaderfortestresult_adult: `Detaillierte Ergebnisse für Dysmate {0}`,
	dynamicheaderfortestreport_adult: `Detaillierter Bericht für Dysmate {0}`,
	dynamicheaderfortestcandidate_adult: `Detaillierte Information für Dysmate Kandidat {0}`,
	headeradulttest: `Ergebnisse für Dysmate A (Screeningtest für Erwachsene)`,
	showbackground: `Zeige die Hintergrundinformationen des Kandidaten`,
	incompleteMessage: `Der Test ist nicht vollständig. Subtests fehlen oder wurden noch nicht begonnen.`,
	summary: `Zusammenfassung`,
	printtestresults: `Ergebnisse drucken`,
	zscorered: `Z-Wert ist mehr als 1 Standardabweichung {0} dem Durchschnitt`,
	zscoregreen: `Z-Wert ist mehr als 1 Standardabweichung {0} dem Durchschnitt`,
	zscoreblue: `Z-Wert ist im Normalbereich`,
	overview: `Übersicht`,
	assessment: `Diagnostik:`,
	assessmentspeechok: `{0} hat für den experimentellen Eine-Minute-Test Ergebnisse im normalen Bereich oder höher erzielt und scheint gute Lesefähigkeiten zu haben.`,
	assessmentspeechnotok: `{0} hat für den experimentellen Eine-Minute-Test Ergebnisse unter dem normalen Bereich erzielt und sollte weiter auf Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	asessmenttextok_1: `{0} hat für {1} der Subtests Ergebnisse im normalen Bereich oder höher erzielt und scheint gute Lese- und Schreibfähigkeiten zu haben.`,
	asessmenttextnotok_1: `{0} hat für {1} der {2} Subtests Ergebnisse unter dem normalen Bereich erzielt und sollte weiter auf Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	assesmenttextok_youthfollowup: `{0} hat keine statistischen Indikatoren für typische kognitive Marker von Dyslexie.`,
	assesmentok_recommendation_youthfollowup: `Falls {0} im Lese- und/oder Wortverständnis oder in einem anderen Bereich des Screeningtests noch immer Punkte unter dem normalen Bereich erzielt hat, wird empfohlen eine weiterführende Diagnostik für … durchzuführen.`,
	workingmemorylowassesment: `{0} hat Indikatoren im Bereich des Arbeitsgedächtnisses und erreicht eine Punktzahl, die {1} Standardabweichung(en) unter dem Durchschnitt für seine/ihre Klassenstufe oder sein/ihr Alter in diesem Test ist. Das Arbeitsgedächtnis ist selber kein kognitiver Marker für Dyslexie, wird jedoch mit Dyslexie und Leseverständnisfehlern verbunden. Eine reduzierte Arbeitsgedächtnisleistung kann unter anderem beeinflussen, wie viele Informationen einem Text entnommen werden können. Es wird deshalb empfohlen zu untersuchen, inwieweit das Arbeitsgedächtnis von {2} den Erwerb neuer Themen beeinflusst und ob in dieser Hinsicht ein Bedarf an akademischer Unterstützung besteht.`,
	rapidnominationlowassesment: `{0} hat Indikatoren im rapid autonaming test und erreicht eine Punktzahl, die {1} Standardabweichung(en) unter dem Durchschnitt für seine/ihre Klassenstufe oder sein/ihr Alter in diesem Test ist. Rapid autonaming misst, wie schnell und genau Wörter aus dem Sprachgedächtnis abgerufen werden können. Schwache Fertigkeiten in diesem Bereich sind ein kognitiver Marker für Dyslexie.`,
	oneminutelowassesment: `{0} hat Indikatoren im Eine-Minute-Test und erreicht eine Punktzahl, die {1} Standardabweichung(en) unter dem Durchschnitt für seine/ihre Klassenstufe oder sein/ihr Alter in diesem Test ist. Der Eine-Minute-Test ist ein genaues Maß für die Anzahl der korrekt gelesenen Wörter pro Minute und liefert ein gutes Bild der Dekodierfertigkeit. Geringe Dekodierfertigkeiten sind ein Marker für Dyslexie.`,
	oneminutelowassesment2: `NB! Es wird empfohlen, die Ergebnisse des Eine-Minute-Tests in Verbindung mit den Ergebnissen des Dictation Test und des Word Chain Test des Dysmate Screeningtests auszuwerten.`,
	spoonerismlowassesment: `{0} hat Indikatoren im Spoonerism test und erreicht in diesem Test eine Punktzahl, die {1} Standardabweichung(en) unter dem Durchschnitt für seine/ihre Klasse oder sein/ihr Alter ist. Der spoonerism test misst die phonologische Bewusstheit und phonologische Verarbeitungsgeschwindigkeit. Schwache Fertigkeiten in diesen Bereichen sind kognitive Marker für Dyslexie.`,
	rawscore: `Rohwert`,
	zscore: `Z-Wert`,
	percentilescore: `Prozentwert`,
	tscore: `T-Wert`,
	scaledscore: `Skalenwert`,
	standardscore: `Standard-Score`,
	theAverage: `Durchschnitt`,
	normalrange: `Normalbereich`,
	assessmentsubtestnormal: `Diese Punktzahl ist im Normalbereich.`,
	assessmentsubtestbelow: `Diese Punktzahl ist {0} Standardabweichungen {1} dem Durchschnitt.`,
	under: `unter`,
	assessmentsubtestabove: `Diese Punktzahl ist {0} Standardabweichungen {1} dem Durchschnitt`,
	over: `über`,
	workingmemorytest: `Arbeitsgedächtnis`,
	spoonerismtest: `Spoonerism`,
	'rapid test': `Rapid autonaming`,
	oneminutetest: `Eine-Minute-Test`,
	letterknowledgetest: `Letter knowledge`,
	phonemisolationtest: `Phoneme Isolation`,
	worddictationtest: `Schreiben nach Diktat`,
	phonemeomissiontest: `Phoneme omission test`,
	wordchaintest: `Wortketten Test`,
	nonwordtest: `Nonword test`,
	typingspeedtest: `Writing speed test`,
	readingtest: `Lesesinnverständnis`,
	wordcomprehensiontest: `Word comprehension`,
	loggin: `Einloggen`,
	loggout: `Ausloggen`,
	trialperiod: `Probezeit`,
	trialuser: `Testbenutzer`,
	basicsupcription: `Basic subscription`,
	fullsupscription: `volles Abonnement`,
	childsubscription: `Dysmate-C - Abonnement`,
	adultsubscription: `Dysmate-A - Abonnement`,
	youthsubscription: `Dysmate-Y - Abonnement`,
	name: `Name:`,
	firstname: `Vorname`,
	lastname: `Nachname`,
	dateofbirth: `Geburtsdatum:`,
	birthDate: `Geburtsdatum`,
	paneMessageContinueTest: `Dies wird einen neuen Testlink an den Kandidaten senden, sodass er einen nicht beendeten Test fortsetzen kann. Fortfahren?`,
	telephone: `Telefon`,
	email: `E-Mail`,
	edit: `Bearbeiten`,
	edituser: `Benutzer bearbeiten`,
	seesubscription: `Siehe Abonnement`,
	adulttests: `Dysmate-A (Test für Erwachsene)`,
	youthtests: `Dysmate-Y (Test für Jugendliche)`,
	confirmationofcost: `Bestätigung der Kosten`,
	sex: `Geschlecht`,
	male: `männlich`,
	female: `weiblich`,
	education: `Ausbildung`,
	cancel: `Abbrechen`,
	confirm: `Bestätigen`,
	age: `Alter`,
	age_specified: `Alter (Jahre)`,
	testdate: `Testdatum`,
	nousedtests: `Keine genutzten {0} Tests`,
	used: `Genutzt:`,
	boy: `Junge`,
	girl: `Mädchen`,
	logg: `Ereignisprotokoll`,
	of: `von {0}`,
	wordsperminute: `Wörter pro Minute`,
	deleteoldtests: `Alten Test löschen`,
	addtesttogroup: `Test zu einer Gruppe hinzufügen`,
	message_incompletData: `Ungültige oder unvollständige Daten`,
	newclasscreated: `Neue Klasse erstellt`,
	testcandidateremoved: `Der Testkandidat wurde aus der Klasse entfernt`,
	testcandidateadded: `Der Testkandidat wurde zu der Klasse hinzugefügt`,
	classexistchoosename: `Diese Klasse existiert bereits, bitte wähle einen anderen Namen.`,
	class: ` Klasse`,
	subscription: `Abonnement`,
	supersubscription: `hat Abonnement`,
	mysubscription: `Mein Abonnement`,
	seetests: `Tests ansehen`,
	currencysymbol: `€`,
	hideWithArrow: `Ausblenden ▲`,
	yourtrialperiodhasexpired: `Deine Probezeit ist abgelaufen`,
	upgradeyoursubscription: `Upgrade your subscription`,
	downloadandsendcontract: `Um mehr Tests auszugeben musst du a subscription haben. Gehe zu “Meine Seite” auf der Website, um a subscription abzuschließen oder lade den Vertrag herunter und sende ihn an`,
	priceandconditioninfo: `. Preisinformationen und Konditionen sind zu finden auf`,
	downloadcontract: `Vertrag herunterladen`,
	onlytwotestspertrialperiod: `Während der Probezeit sind nur 2 Tests verfügbar`,
	upgradetofullsubscription: `upgrade to full subscription to issue new tests.`,
	fetch: `Wiederherstellen`,
	language: `Sprache`,
	child: `Kind`,
	childscreening: `Dysmate-C (Test für Kinder)`,
	childtests: `Dysmate-C (Test für Kinder)`,
	childfollowuptest: `Dysmate-C II (Followup Kinder)`,
	childnorming: `Normierung Kinder`,
	experimentalchildtest: `Experimental childtest`,
	youth: `Jugendliche(r)`,
	youthtest: `Dysmate-Y (Screening)`,
	youthscreening: `Dysmate-Y – (Screeningtest für Jugendliche)`,
	youthfollowup: `Dysmate-Y II (Followup Jugendliche)`,
	youthfollowuptest: `Dysmate-Y II (Followuptest Jugendliche)`,
	youthnorming: `Normierung Jugendliche`,
	adult: `Erwachsene(r)`,
	adulttest: `Dysmate-A (Screening)`,
	adultscreening: `Dysmate A – (Screeningtest für Erwachsene)`,
	adultfollowup: `Dysmate-A II (Followup Erwachsene)`,
	adultfollowuptest: `Dysmate-A II (Followuptest Erwachsene)`,
	adultnorming: `Normierung Erwachsene`,
	deletedon: `Deleted the`,
	basic: `Basis`,
	deletedtests: `Gelöschte Tests`,
	viewsubscription: `See subscription ▼`,
	viewdeleted: `Gelöschte Tests ▼`,
	referalinformation: `persönliche Informationen`,
	referalgroups: `Mitgliedschaft in Gruppen`,
	mygroups: `Meine Gruppen`,
	myinformation: `Meine persönlichen Informationen`,
	message_costsforeachtestthatis_started: `Beachten Sie, dass je nach subscription, Kosten mit dem Verteilen neuer Tests verbunden sein können. Tests, die nicht genutzt werden, werden nicht in Rechnung gestellt.`,
	test_type: `Testtyp`,
	screeningtest_adult: `Dysmate-A (Screeningtest für Erwachsene)`,
	numberoftests: `Anzahl an Tests (max 30)`,
	create_tests: `Neue Tests erstellen`,
	login: `Einloggen`,
	loginwithbankid: `Melden Sie sich mit den folgenden Methoden an`,
	message_thisisforcertifieduser: `Dies ist für zertifizierte Testadministratoren mit subscription`,
	bankid: `BankID`,
	bankidonmobile: `BankID for phones`,
	edittestadministrator: `Testadministrator bearbeiten`,
	personalinformation: `Persönliche Informationen`,
	shcool_or_company: `Schule/ Institut`,
	addanewtestadministrator: `Neuen Testadministrator hinzufügen`,
	addtestadministrator: `Testadministrator hinzufügen`,
	saveuserinformation: `Informationen über den Testkandidaten speichern`,
	delete: `Löschen`,
	deleteuser: `Benutzer löschen`,
	currentstatus: `Aktueller Status:`,
	savesubscription: `Save subscription`,
	organization: `Organisation`,
	newloginmethod: `Neue Methode zum Einloggen`,
	errorhasoccured: `Ein Fehler ist aufgetreten`,
	message_singleuser_perks_trial: `Mit einem Benutzer kannst du eine kostenlose Probezeit von 30 Tagen und 2 kostenlose Tests von jedem Typ erhalten`,
	message_change_loginmethod: `Du bist dabei deine Methode zum Einloggen zu ändern. Du wirst diese Methode in Zukunft nutzen müssen.`,
	info_for_professional_with_either_completed_screening_test: `This is for professionals that have completed or is signed up for a certification course, and wants to try a few tests before subscribing to the service.`,
	info_prerequisites_to_obtain_license: `Um Zugang zu der Probezeit zu erhalten, musst du dich für einen Dysmate Zertifikatskurs anmelden oder bereits einen Kurs abgeschlossen haben. Alle Benutzer werden manuell von Dysmate genehmigt, woraufhin sie eine E-Mail mit Instruktionen erhalten, um die Registrierung abzuschließen und Zugang zu erhalten.`,
	removefiltering: `Filter entfernen`,
	printreport: `Testbericht drucken`,
	hide: `Ausblenden`,
	show: `Anzeigen`,
	userinformation: `Informationen zu dem Kandidaten`,
	localizedwriteuptextforlowresults: `{0} hat für {1} der {2} Subtests Ergebnisse unter dem normalen Bereich erzielt und sollte weiter für Dyslexie und/oder Leseverständnisschwierigkeiten untersucht werden.`,
	localizedwriteuptextforNormalresults: `{0} hat für {1} der Subtests Ergebnisse im normalen Bereich oder höher erzielt und scheint gute Lese- und Schreibfähigkeiten zu haben.`,
	testreultsforliteratescreeningtest: `Testergebnisse für Dysmate Screeningtest`,
	info_belowpresentsinformation: `Die Testergebnisse für {0}, Alter {1} Jahre and {2} Monate werden unten präsentiert.`,
	newinfo_belowpresentsinformation: `Testergebnisse für {0}, geboren am {1}, (Alter: {2} Jahre und {3} Monate) werden unten präsentiert.`,
	newinfo2_belowpresentsinformation: `Testergebnisse für {0}, geboren am {1}, (Alter: {2} Jahre und {3} Monate) werden unten präsentiert.`,
	newinfo3_belowpresentsinformation: `Informationen zu dem Kandidaten {0}, geboren am {1}, (Alter: {2} Jahre und {3} Monate) werden unten präsentiert.`,
	speech1_belowpresentsinformation: `Testergebnisse für {0}, (Alter: {1}) werden unten präsentiert.`,
	speech2_belowpresentsinformation: `Testergebnisse für {0}, (Alter: {1}) werden unten präsentiert.`,
	speech3_belowpresentsinformation: `Informationen zu dem Kandidaten {0}, (Alter: {1} Jahre) werden unten präsentiert.`,
	info_testwascompletedon: `Der Test wurde abgeschlossen am {0}`,
	info_testwasNotcompletedon: `Der Test wurde noch nicht abgeschlossen.`,
	notetsfound: `Es wurden keine abgeschlossenen Tests gefunden`,
	background_info_testcandidate: `Hintergrundinformationen für den Testkandidaten`,
	candidate_answers: `Antworten von dem Kandidaten`,
	selectLanguage: `Sprache auswählen`,
	norwegian: `Norwegisch`,
	german: `Deutsch`,
	dutch: `Niederländisch`,
	english: `Englisch`,
	swedish: `Schwedisch`,
	zscore: `Z-Wert`,
	usersupport: `Unterstützung für Nutzer:innen`,
	groups: `Gruppen`,
	groupname: `Gruppenname`,
	date: `Datum`,
	insert: `Einfügen`,
	selected_users: `Ausgewählte Benutzer`,
	alreadyexistsingroup: `Existiert bereits in dieser Gruppe!`,
	orddiktatheading: `Dieser Test misst die Buchstabierfertigkeiten und das orthografische Bewusstsein. Hier muss der Testkandidat Wörter schreiben, die laut vorgelesen werden. Jedes Wort wird zuerst in einem Satz präsentiert, um sicherzustellen, dass die Bedeutung des Wortes richtig verstanden wurde. Dann wird das Zielwort wiederholt und der Testkandidat schreibt es auf. Es gibt kein Zeitlimit für diesen Test und jeder Satz mit Zielwort kann einmal wiederholt werden.`,
	orddiktatchildexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 13 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	orddiktatvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Mittelwert entfernt. This is a result that places itself`,
	orddiktatadultexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 45 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	orddiktatyouthexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 30 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	fonemisolasjonheading: `Dieser Test identifiziert die phonologische Bewusstheit der jüngsten Testkandidaten. In diesem Test werden dem Testkandidaten vier Bilder präsentiert. Der Testkandidat soll auf das Bild zeigen, welches entweder mit einem bestimmten Laut anfängt oder aufhört.`,
	fonemisolasjonexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 16 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	fonemisolasjonvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	fonemutelatelseheading: `Dieser Test identifiziert die phonologische Bewusstheit von Grundschülern. In diesem Test werden dem Testkandidaten verbal Wörter präsentiert. Der Testkandidat soll das Wort sagen, welches übrig bleibt, wenn ein bestimmter Laut entfernt wird (ie / p / in plane => lane)`,
	fonemutelatelseexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 16 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	fonemutelatelsevariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	spoonerismheading: `Dieser Test identifiziert die phonologische Bewusstheit. In diesem Test werden dem Testkandidaten verbal 20 Wortpaare präsentiert, bei denen er möglichst schnell und genau den ersten Laut der beiden Wörter tauschen soll.`,
	spoonerismexplanation: `Für jedes korrekte Wort, welches der Testkandidat mit dem neuen Anlaut sagt, gibt es einen Punkt (maximal 40 Punkte). Bei diesem Test wird auch die Zeit genommen. Die Punktzahl ergibt sich aus der Anzahl korrekter Wörter, geteilt durch die Zeit, die zum Abschließen des Tests gebraucht wurde. Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	spoonerismvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	ettminuttstestheading: `Dies ist ein Lautlesetest, der die Dekodierfertigkeiten identifiziert. Hier wird dem Testkandidaten eine Liste von Wörtern präsentiert, die er innerhalb von 60 Sekunden möglichst schnell und genau vorlesen soll. Das Ergebnis ist ein Maß für die Anzahl der korrekt gelesenen Wörter pro Minute.`,
	ettminuttstestexplanation: `Für jedes korrekt gelesene Wort gibt es einen Punkt. Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	ettminuttstestvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	hurtigbenevningheading: `Dieser Test identifiziert wie schnell der Testkandidat bekannte Objekte benennen und die Namen von Objekten und Farben aus dem sprachlichen Langzeitgedächtnis abrufen kann.`,
	hurtigbenevningexplanation: `Für jede korrekte Kombination von Farbe und Objekt, die der Testkandidat nennt, gibt es einen Punkt. Bei diesem Test wird die Zeit genommen. Die Punktzahl ergibt sich aus der Anzahl korrekt identifizierter Farbe-Objekt Kombinationen, geteilt durch die Zeit, die für das Nennen aller 50 Farbe-Objekt Kombinationen benötigt wurde. Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	hurtigbenevningvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	arbeidsminnenummberheading: `Dieser Test identifiziert die Fähigkeit des Testkandidaten Informationen zu speichern und zu verarbeiten. In diesem Test wird dem Testkandidaten zunächst eine Folge von Zahlen, dann eine Folge von Wörtern präsentiert, die in umgekehrter Reihenfolge wiederholt werden sollen. Sowohl die Reihe an Zahlen als auch an Wörtern wird zunehmend länger, wenn der Testkandidat die vorherige Folge geschafft hat.`,
	arbeidsminneexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 12 Punkte für die Reihen mit Zahlen und maximal 12 Punkte für die Reihen mit Wörtern, insgesamt maximal 24 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	arbeidsminnevariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	arbeidsminneheading: `Dieser Test identifiziert die Fähigkeit des Testkandidaten Informationen zu speichern und zu verarbeiten. In diesem Test wird dem Testkandidaten zunächst eine Folge von Zahlen, dann eine Folge von Wörtern präsentiert, die in umgekehrter Reihenfolge wiederholt werden sollen. Sowohl die Reihe an Zahlen als auch an Wörtern wird zunehmend länger, wenn der Testkandidat die vorherige Folge geschafft hat.`,
	ordforståelseheading: `Dieser Test misst den Wortschatz. Der Testkandidat muss ein Wort finden, welches die gleiche Bedeutung hat wie das Zielwort. Bei jedem Item muss der Testkandidat aus vier Optionen auswählen. Dieser Test bietet ein Maß für den Umfang und die Tiefe des Vokabulars des Testkandidaten.`,
	ordforståelseexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 15 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	ordforståelsevariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	ordforståelseyouthexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 20 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	leseprøvenheading: `Die ist ein Leiselesetest, der das Leseverständnis misst. Der Testkandidat muss Fragen direkt aus dem Text beantworten, Informationen aus verschiedenen Textabschnitten kombinieren und Schlüsse ziehen. Der Testkandidat hat 10 Minuten, um den Text zu lesen und möglichst viele Multiple-Choice Fragen zu beantworten. N.B. Hier ist es wichtig zu registrieren, ob der Testkandidat Multiple-Choice Fragen falsch beantwortet hat oder ob er sie gar nicht beantwortet hat (aufgrund einer langsamen Lesegeschwindigkeit).`,
	leseprøvenyouthheading: `Die ist ein Leiselesetest, der das Leseverständnis misst. Der Testkandidat muss Fragen direkt aus dem Text beantworten, Informationen aus verschiedenen Textabschnitten kombinieren und Schlüsse ziehen. Der Testkandidat hat 12 Minuten, um den Text zu lesen und möglichst viele Multiple-Choice Fragen zu beantworten. N.B. Hier ist es wichtig zu registrieren, ob der Testkandidat Multiple-Choice Fragen falsch beantwortet hat oder ob er sie gar nicht beantwortet hat (aufgrund einer langsamen Lesegeschwindigkeit).`,
	leseprøvenexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 14 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	leseprøvenvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	leseprøvenyouthexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 9 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	skrivehastighetheading: `Dieser Test misst die Effizienz des Schreibens. Dies ist eine Fertigkeit, die zum Beispiel beim Notizen machen oder Klausuren schreiben, benötigt wird. Der Testkandidat hat während der gesamten Zeit des Tests einen Satz sichtbar, den er während der zwei Minuten so oft und akkurat wie möglich schreiben soll.`,
	skrivehastighetexplanation: `Die Punktzahl ist hier die Anzahl der im Durchschnitt korrekt geschriebenen Wörter pro Minute. Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	skrivehastighetvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	tulleordtestenheading: `Dies ist ein Leiselesetest, der die phonologische Bewusstheit und die Dekodierfertigkeiten misst. Dieser Test basiert darauf, dass der Testkandidat Nichtwörter identifiziert, die wie echte Wörter ausgesprochen werden und sie von Nichtwörtern unterscheidet, die nicht wie echte Wörter ausgesprochen werden. Für jedes Item muss der Testkandidat von fünf Optionen auswählen, von denen nur eine korrekt ist. Der Testkandidat hat zwei Minuten, um so viele Aufgaben wie möglich zu lösen.`,
	tulleordtestenexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 25 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	tulleordtestenvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	ordkjedetestenheading: `Dies ist ein Leiselesetest, der die schnelle Worterkennung und das orthografische Wissen misst. Hier soll der Testkandidat Lücken zwischen Wörtern einfügen, die in einer Kette geschrieben sind. Jede Kette besteht aus vier zufälligen Wörtern. Der Testkandidat hat 4 Minuten, um so viele Wortketten wie möglich zu lösen.`,
	ordkjedetestenexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 40 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	ordkjedetestenvariabletext: `{0} erhält eine Punktzahl von {1} in diesem Test. Diese Punktzahl ist {2} Standardabweichungen von dem Durchschnitt für die Klassenstufe entfernt. This is a result that places itself`,
	ordkjedetestenyouthexplanation: `Für jede korrekte Antwort gibt es einen Punkt (maximal 36 Punkte). Die durchschnittliche Punktzahl für {0} ist {1} und die Standardabweichung ist {2}.`,
	chooseagroup: `Gruppe auswählen`,
	includeLongIntro: `Beinhaltet Selbstbeurteilung / Anamnese`,
	anamneseexplaination: `Wenn der Test nur zur Voruntersuchung verwendet wird, ist es nicht notwendig, eine Selbstbewertung / Anamnese einzuschließen.e`,
	chooselanguage: `Sprache auswählen`,
	seegrouptests: `Tests in Gruppen ansehen`,
	addtogroup: `Zu Gruppe hinzufügen`,
	nogroupsfound: `keine Gruppe gefunden`,
	fromgroups: `aus Gruppe`,
	allmytests: `Alle meine Tests`,
	groupindicator: `-----Gruppen-----`,
	notanswered: `nicht beantwortet`,
	testnotanswered: `{0} Items wurden nicht beantwortet`,
	showunanswered: `Nicht beantwortete Items anzeigen`,
	hideunanswered: `Nicht beantwortete Items ausblenden`,
	rightanswer: `Richtige Antwort: {0}`,
	action: `Aktion`,
	deleteall: `Alle löschen`,
	chooseclass: `Klasse auswählen`,
	confirmationdeleteclass: `Bist du sicher, dass du {0} löschen möchtest`,
	theclass: `Klasse`,
	multipleclasses: `Klassen`,
	deleteclass: `Klasse löschen`,
	message_noclasses: `Du hast noch keine Klassen erstellt`,
	showtests: `Tests ansehen ▼`,
	allmycandidates: `Alle meine Kandidaten`,
	notestsforcandidate: `Dieser Kandidat hat noch keine Tests abgeschlossen`,
	gender: `Geschlecht`,
	nocandidates: `Du hast keine Kandidaten`,
	choosecandidate: `Wähle einen Kandidaten`,
	classindicator: `-----Klassen-----`,
	sendtest: `Test senden`,
	createclass: `Klasse erstellen`,
	removefromclass: `Aus Klasse entfernen`,
	providevalidname: `Füge einen gültigen Namen hinzu`,
	searchhere: `Suche hier...`,
	newclass: `Neue Klasse`,
	writeclassname: `Gib der Klasse einen Namen…`,
	deleting: `Löschen`,
	mypage: `Meine Seite`,
	notavailable: `nicht verfügbar`,
	theagegroup: `Altersgruppe`,
	hiddenname: `<Name verbergen>`,
	wordreadcorrectly: `Richtig gelesene Wörter`,
	correct: `Richtig`,
	wrong: `Falsch`,
	timeused: `benötigte Zeit`,
	thisagegroup: `Altersgruppe`,
	adminpersonalinformation: `Persönliche Informationen`,
	consentText: `Der Vorgesetzte des Testkandidaten hat der Teilnahme an der Normierungsstudie zugestimmt.`,
	creategroups: `Gruppen erstellen`,
	createnewgroup: `Neue Gruppe erstellen`,
	writegroupname: `Gruppenname eingeben...`,
	deletegroup: `die Gruppe löschen`,
	editgroup: `Ändern Sie die Gruppe`,
	cannotaddexistingmembertogroup: `Mitglieder, die bereits in der Gruppe sind, können nicht hinzugefügt werden`,
	newgroup: `Neue Gruppe`,
	removefromgroup: `Aus Gruppen entfernen`,
	showearliermonths: `Siehe Vormonate ▼`,
	teststhismonths: `Test diesen Monat`,
	coststhismonth: `Kosten diesen Monat`,
	previousperiods: `Frühere Perioden`,
	period: `Zeitraum`,
	tests: `Anzahl der Tests`,
	statuses: `Status`,
	costs: `Kosten`,
	notestsafterdatetoinvoice: `Keine Tests nach ausgewähltem Rechnungsdatum`,
	accessNormingMakeTests: `Hat Zugriff zum Erstellen von Standardisierungstests`,
	accessProdMakeTests: `Sie haben Zugriff, um Produktionstests zu erstellen und Testergebnisse anzuzeigen`,
	averagezscore: `Durchschnittlicher Z-Wert`,
	childrenAndAdultTests: `Kinder- und Erwachsenentests`,
	childrenAndYouthTests: `Kinder- und Erwachsenentests`,
	childYouthAndAdultTests: `Kinder-, Jugend- und Erwachsenentests`,
	choosegroup: `wähle die Gruppe`,
	choose: `wählen`,
	classname: `Klassenname`,
	followuptest: `Nachuntersuchung`,
	followuptests: `Nachuntersuchungen`,
	myclasses: `Meine Klasse`,
	mytests: `Meine Tests`,
	norming: `Standardisierung`,
	normingtests: `Normative Prüfungen`,
	numberofcandidates: `Anzahl der Kandidaten`,
	selectedTestAddedToGroup: `Ausgewählte {0} zur Gruppe hinzugefügt`,
	selectedTestRemovedFromGroup: `Ausgewählte {0} aus der Gruppe entfernt`,
	singleChild: `Kinder`,
	testscompleted: `Test durchgeführt`,
	theAverage: `durchschnitt.`,
	undistributedTests: `Unverteilte Tests`,
	warning_candidatesfromdifferentcategori: `Haben einige Kandidaten bereits Tests aus einer anderen Kategorie absolviert. Trotzdem fortfahren?`,
	workingmemorytestNumbers: `Arbeitsgedächtnis (Zahlen)`,
	workingmemorytestWords: `Arbeitsgedächtnis (Wörter)`,
	readingTest_questioncount: 15,
	password: `Passwort`,
};

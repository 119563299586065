import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';
class EvaluateAnswers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasListenedAudio: true,
			hasNotListenedAudio: true,
			hasConfirmedCorrection: false,
		};
	}
	render() {
		/* const redundantTests = this.props.testsForDumping; */
		return (
			<div
				className="lit-modal"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__island"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<span className="sub-test__answer--wrong">
						<h1 className="">{this.props.wrongAnswer}</h1>
					</span>
					{this.state.hasNotListenedAudio && (
						<div className="lit-audio-label">
							<audio key={'index'} controls>
								<source
									src={URL.createObjectURL(
										this.props.blobData
									)}
									type="audio/webm"
								/>
							</audio>
							<br></br>

							<div>
								<p>{strings.reminderScoreChange}</p>
								<p>{strings.correctAnswerConfirmation}</p>
								<div className="lit-btn-grid">
									<div className="lit-confirm-eval">
										<button
											className="lit-btn-confirm-eval"
											onClick={(e) => {
												this.setState({
													hasConfirmedCorrection: true,
												});
												this.props.allAnswers[
													this.props.currentIndex
												].isCorrect = true;

												this.props.reEvaluateSpeechTest(
													this.props.allAnswers
												);
											}}
										>
											{strings.correct}
										</button>
									</div>
									<div className="lit-reject-eval">
										<button
											className="lit-btn-reject-eval"
											onClick={(e) => {
												this.props.closeWindow();
											}}
										>
											{strings.wrong}
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default EvaluateAnswers;

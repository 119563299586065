import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function editTestAdmin(adminUserId) {
	const body = {adminUserId: adminUserId};

	return fetch(
		`${ENDPOINT_URL}/subscription/trial/extend`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(body),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}

import {updateObject} from './reducerUtils';
import roundWithDecimals from './roundWithDecimals';
import {
	CHILD_TEST_STEPS_BY_ID,
	CHILD_SCRNG_TEST_STEPS_BY_ID,
	TEST_STEPS_BY_ID,
} from '../constants';
import strings from './strings';

const negativeBackgroundColor = `rgba(212, 67, 104, 0.4)`;
const negativeColor = `rgba(212, 67, 104, 1)`;

const positiveBackgroundColor = `rgba(86, 240, 180, 0.4)`;
const positiveColor = `rgba(86, 240, 180, 1)`;

const neutralBackgroundAreaColor = `rgba(239, 217, 146, 0.3)`;
const neutralBackgroundColor = `rgba(73, 179, 192, 0.4)`;
const neutralColor = `rgba(73, 179, 192, 1)`;

const negativePrintColor = `#333`;
const neutralPrintColor = `#858585`;
const positivePrintColor = `#c6c6c6`;

export function getChartData(subtests, forPrint = true) {
	const subtestsWithScores = subtests.map((subtest) => {
		const subtestInfo = TEST_STEPS_BY_ID[subtest.id];

		return updateObject(subtestInfo, subtest);
	});

	const labels = subtestsWithScores.map((subtest) => subtest.label);
	const data = subtestsWithScores.map((subtest) =>
		roundWithDecimals(subtest.zScore, 2)
	);
	let backgroundColor;
	let borderColor;

	backgroundColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeBackgroundColor;
		}

		if (zScore >= 1) {
			return positiveBackgroundColor;
		}

		return neutralBackgroundColor;
	});
	borderColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeColor;
		}

		if (zScore >= 1) {
			return positiveColor;
		}

		return neutralColor;
	});

	return {
		labels,
		datasets: [
			{
				label: strings.zscore,
				data,
				backgroundColor,
				borderColor,
				borderWidth: 1,
			},
			{
				label: strings.averageArea,
				type: 'line',
				fill: true,
				data: [
					{
						x: -1,
						y: 0,
					},
					{
						x: 1,
						y: labels[0],
					},
				],
				pointRadius: 0,
				pointHoverRadius: 0,
				backgroundColor: neutralBackgroundAreaColor,
			},
		],
		options: {
			legend: {
				display: false,
			},
		},
	};
}

export function getChildChartData(subtests) {
	const subtestsWithScores = subtests.map((subtest) => {
		const subtestInfo = CHILD_TEST_STEPS_BY_ID[subtest.id];
		return updateObject(subtestInfo, subtest);
	});

	const labels = subtestsWithScores.map((subtest) => subtest.label);
	const data = subtestsWithScores.map((subtest) =>
		roundWithDecimals(subtest.zScore, 2)
	);
	const backgroundColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeBackgroundColor;
		}

		if (zScore >= 1) {
			return positiveBackgroundColor;
		}

		return neutralBackgroundColor;
	});
	const borderColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeColor;
		}

		if (zScore >= 1) {
			return positiveColor;
		}

		return neutralColor;
	});

	return {
		labels,
		datasets: [
			{
				label: strings.zscore,
				data,
				backgroundColor,
				borderColor,
				borderWidth: 1,
			},
			{
				label: strings.averageArea,
				type: 'line',
				fill: true,
				data: [
					{
						x: -1,
						y: labels[0],
					},
					{
						x: 1,
						y: labels[0],
					},
				],
				pointRadius: 0,
				pointHoverRadius: 0,
				backgroundColor: neutralBackgroundAreaColor,
			},
		],
		options: {
			legend: {
				display: false,
			},
		},
	};
}

export function getChildScrChartData(subtests, forPrint = true) {
	const subtestsWithScores = subtests.map((subtest) => {
		const subtestInfo = CHILD_SCRNG_TEST_STEPS_BY_ID[subtest.id];

		return updateObject(subtestInfo, subtest);
	});

	const labels = subtestsWithScores.map((subtest) => subtest.label);
	const data = subtestsWithScores.map((subtest) =>
		roundWithDecimals(subtest.zScore, 2)
	);
	let backgroundColor;
	let borderColor;

	backgroundColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeBackgroundColor;
		}

		if (zScore >= 1) {
			return positiveBackgroundColor;
		}

		return neutralBackgroundColor;
	});
	borderColor = data.map((zScore) => {
		if (zScore <= -1) {
			return negativeColor;
		}

		if (zScore >= 1) {
			return positiveColor;
		}

		return neutralColor;
	});

	return {
		labels,
		datasets: [
			{
				label: strings.zscore,
				data,
				backgroundColor,
				borderColor,
				borderWidth: 1,
			},
			{
				label: strings.averageArea,
				type: 'line',
				fill: true,
				data: [
					{
						x: -1,
						y: 0,
					},
					{
						x: 1,
						y: labels[0],
					},
				],
				pointRadius: 0,
				pointHoverRadius: 0,
				backgroundColor: neutralBackgroundAreaColor,
			},
		],
		options: {
			legend: {
				display: false,
			},
		},
	};
}

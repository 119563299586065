import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import classNames from 'classnames';

import TestAdminModal from './TestAdminModal';
import TestAdminRow from './TestAdminRow';
import AddressList from './AddressList';
import TestAdminActivity from './TestAdminActivity';
import TestAdminInvoices from './TestAdminInvoices';
import TestAdminSummary from './TestAdminSummary';
import TestAdminGroups from './TestAdminGroups';

import addGroupMembers from '../../api/addGroupMembers';
import blockUser from '../../api/blockUser';
import unblockUser from '../../api/unblockUser';
import getAllGroups from '../../api/getAllGroups';
import createAdminGroup from '../../api/createAdminGroup';
import getTestAdmins from '../../api/getTestAdmins';
import editTestAdmin from '../../api/editTestAdmin';
import deleteTestAdmin from '../../api/deleteTestAdmin';
import extendTrial from '../../api/extendTrial';
import deleteLanguage from '../../api/deleteLanguage';
import editTestAdminSubscription from '../../api/editTestAdminSubscription';
import editLanguageSubscription from '../../api/editLanguageSubscription';
import assignLanguageSubscription from '../../api/assignLanguageSubscription';

import {
	USER_STAGES_INFO,
	ALL,
	LOGGED_IN,
	NEEDS_APPROVAL,
	AWAITING_CONFIRMATION,
	TRIAL,
	BASIC,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	ACTIVE,
	INACTIVE,
	BLOCKED,
	language,
} from '../../constants';
import strings from '../../utils/strings';

const NEEDS_APPROVAL_INFO = USER_STAGES_INFO[NEEDS_APPROVAL];
const AWAITING_CONFIRMATION_INFO = USER_STAGES_INFO[AWAITING_CONFIRMATION];
const ACTIVE_INFO = USER_STAGES_INFO[ACTIVE];
const TRIAL_INFO = USER_STAGES_INFO[TRIAL];
const BASIC_INFO = USER_STAGES_INFO[BASIC];
const NORMING_INFO = USER_STAGES_INFO[NORMING];
const PRODUCTION_INFO = USER_STAGES_INFO[PRODUKSJON];
const PROD_NORMING_INFO = USER_STAGES_INFO[PROD_NORMING];

const INACTIVE_INFO = USER_STAGES_INFO[INACTIVE];

const userStageFilters = [
	ALL,
	LOGGED_IN,
	NEEDS_APPROVAL_INFO,
	AWAITING_CONFIRMATION_INFO,
	TRIAL_INFO,
	BASIC_INFO,
	ACTIVE_INFO,
	NORMING_INFO,
	PRODUCTION_INFO,
	PROD_NORMING_INFO,
	INACTIVE_INFO,
	BLOCKED,
];
const defaultSelectedUsers = [];
const defaultSelectedEmails = [];
const defaultadminsToLogout = [];
class Users extends React.Component {
	constructor() {
		super();

		this.state = {
			isCreatingGroup: false,
			isExportingList: false,
			selectedUserEmails: defaultSelectedEmails,
			selectedUsers: defaultSelectedUsers,
			isSingleChecked: false,
			isMainChecked: false,
			groupData: [],
			searchFilter: '',
			activeFilter: ALL,
			isEditingTestAdmin: null,
			testAdmins: [],
			language: null,
			showUsers: true,
			showSummary: false,
			showActivity: false,
			showInvoices: false,
			showUsersActive: 'tablink1-active',
			showActivityActive: 'tablink2',
			showSummaryActive: 'tablink3',
			showInvoicesActive: 'tablink4',
			adminsToLogout: defaultadminsToLogout,
			animator: 'marker-default',
		};

		this.getAndSetTestAdmins = this.getAndSetTestAdmins.bind(this);
		this.getAndSetSelectedAdminGroups = this.getAndSetSelectedAdminGroups.bind(
			this
		);
		this.extendTrial = this.extendTrial.bind(this);
		this.getAndSetAdminGroups = this.getAndSetAdminGroups.bind(this);
	}

	componentDidMount() {
		this.getAndSetTestAdmins();
		this.getAndSetAdminGroups();
	}

	onUserCheckChanged = (e) => {
		const {isMainChecked} = this.state;

		const filteredTestAdmins = this.getFilteredTestAdmins();
		if (e.target.checked) {
			this.setState({
				selectedUserEmails: defaultSelectedEmails,
				selectedUsers: defaultSelectedUsers,
			});

			filteredTestAdmins.forEach((testAdmin) => {
				this.setState((defaultSelectedEmails) => ({
					selectedUserEmails: [
						...defaultSelectedEmails.selectedUserEmails,
						testAdmin.email,
					],
				}));
				this.setState((defaultSelectedUsers) => ({
					selectedUsers: [
						...defaultSelectedUsers.selectedUsers,
						testAdmin,
					],
				}));
			});
		} else {
			this.setState({
				selectedUserEmails: defaultSelectedEmails,
				selectedUsers: defaultSelectedUsers,
			});
		}

		this.setState({
			isMainChecked: !isMainChecked,
		});
	};

	onHandleUsers = (
		e,
		id,
		subscription,
		firstName,
		lastName,
		organization,
		email,
		phone
	) => {
		const usersData = {
			id,
			subscription,
			firstName,
			lastName,
			organization,
			email,
			phone,
		};
		const {selectedUsers} = this.state;

		if (e.target.checked) {
			this.setState({
				selectedUsers: [...selectedUsers, usersData],
			});
		} else {
			const indexVal = this.findWithProp(
				selectedUsers,
				'id',
				usersData.id
			);
			if (indexVal > -1) {
				selectedUsers.splice(indexVal, 1);
				this.setState({
					selectedUsers,
				});
			}
		}
	};
	forceLogoutUsers(selectedUsers) {
		const userIds = selectedUsers.map((users) => {
			return users.id;
		});

		blockUser(userIds).then(() => {
			alert('users logged out forcefully!');
			this.getAndSetTestAdmins();
		});
	}
	unblockUsers(selectedUsers) {
		const userIds = selectedUsers.map((users) => {
			return users.id;
		});
		unblockUser(userIds).then(() => {
			alert('users unblocked successfully!');
			this.getAndSetTestAdmins();
		});
	}
	onHandleEmails = (e, email) => {
		const {selectedUserEmails} = this.state;

		var epost = email;
		if (e.target.checked) {
			//on check - add email
			this.setState({
				selectedUserEmails: [...selectedUserEmails, epost],
			});
		} else {
			//on uncheck - remove email
			const indexVal = selectedUserEmails.indexOf(epost);
			if (indexVal !== -1) {
				selectedUserEmails.splice(indexVal, 1);
				this.setState({
					selectedUserEmails,
				});
			}
		}
	};

	getAndSetTestAdmins() {
		getTestAdmins().then((testAdmins) => {
			if (!testAdmins) {
				alert(testAdmins);
			}
			this.setState({
				testAdmins,
			});
		});
	}
	getAndSetAdminGroups() {
		getAllGroups().then((groupData) => {
			this.setState({
				groupData,
			});
		});
	}
	getAndSetSelectedAdminGroups(selectedAdmins) {
		const selectedSubscriptions = selectedAdmins.map((subsIds) => {
			return subsIds.subscription.id;
		});

		const tempobj = [];
		const extractedSubsriptions = [];
		for (let i = 0; i < selectedSubscriptions.length; i++) {
			for (let j = 0; j < this.state.groupData.length; j++) {
				if (
					selectedSubscriptions[i] ===
					this.state.groupData[j].subscription
				) {
					const obj = {
						groupId: this.state.groupData[j].Id,
						groupName: this.state.groupData[j].groupName,
						subscription: this.state.groupData[j].subscription,
						adminid: this.state.groupData[j].adminid,
					};
					tempobj.push(obj);
				}
			}
		}
		extractedSubsriptions.push(tempobj);
	}

	deleteUserAdmin(adminUserId) {
		if (!adminUserId) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}

		deleteTestAdmin(adminUserId).then(() => {
			this.setState(
				{
					isEditingTestAdmin: null,
				},
				this.getAndSetTestAdmins
			);
		});
	}
	deleteLanguageSubscription(languageId) {
		if (!languageId) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}
		deleteLanguage(languageId).then(() => {
			this.setState(
				/* {
					isEditingTestAdmin: null,
				}, */
				this.getAndSetTestAdmins
			);
		});
	}

	saveTestAdmin(testAdminUpdates, apiCall) {
		if (!testAdminUpdates) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}

		this.setState({
			isEditingTestAdmin: null,
		});
		apiCall(testAdminUpdates).then(() => {
			this.getAndSetTestAdmins();
		});
	}
	SaveLanguageSubscription(testAdminUpdates, apiCall) {
		if (!testAdminUpdates) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}
		apiCall(testAdminUpdates).then(() => {
			this.setState(this.getAndSetTestAdmins);
		});
	}
	NewLanguageSubscription(testAdminUpdates, apiCall) {
		if (!testAdminUpdates) {
			this.setState({
				isEditingTestAdmin: null,
			});
			return;
		}
		apiCall(testAdminUpdates).then(() => {
			this.setState(this.getAndSetTestAdmins);
		});
	}

	extendTrial(adminUserId) {
		extendTrial(adminUserId).then(() => {
			this.setState(
				{
					isEditingTestAdmin: null,
				},
				this.getAndSetTestAdmins
			);
		});
	}

	addNewGroupMembers(group, members) {
		if (!group || !members.length) {
			this.setState({
				isCreatingGroup: false,
			});
			return;
		}
		addGroupMembers(group, members).then(() => {
			this.getAndSetAdminGroups();
			this.setState({
				isCreatingGroup: false,
			});
		});
	}

	createNewAdminGroup(ids, subsIds, groupName) {
		if (!ids || !subsIds || !groupName) {
			this.setState({
				isCreatingGroup: false,
			});
			return;
		}
		createAdminGroup(ids, subsIds, groupName).then(() => {
			this.setState({
				isCreatingGroup: false,
			});
		});
	}
	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}

	getFilteredTestAdmins = () => {
		const {testAdmins, activeFilter} = this.state;

		let filteredTestAdmins = testAdmins;

		if (activeFilter !== ALL) {
			filteredTestAdmins = testAdmins.filter(
				(testAdmin) => testAdmin.userStage === activeFilter.id
			);
		}
		if (activeFilter === LOGGED_IN) {
			const admins = [];
			filteredTestAdmins = testAdmins.filter((testAdmin) => {
				const loginHistory = testAdmin.loginTime;
				const currentTime = Math.floor(Date.now() / 1000 + 3600);
				if (loginHistory && loginHistory - currentTime > 0) {
					admins.push(testAdmin);
				}
			});
			return admins;
		}

		if (activeFilter === BLOCKED) {
			const admins = [];
			filteredTestAdmins = testAdmins.filter((testAdmin) => {
				if (testAdmin.isBlocked) {
					admins.push(testAdmin);
				}
			});
			return admins;
		}

		filteredTestAdmins = filteredTestAdmins.filter((testAdmin) => {
			const searchableFields = [
				'firstName',
				'lastName',
				'phone',
				'email',
				'organization',
			];

			for (const field of searchableFields) {
				if (
					testAdmin[field] &&
					testAdmin[field]
						.toLowerCase()
						.indexOf(this.state.searchFilter) !== -1
				) {
					return true;
				}
			}
			return false;
		});

		return filteredTestAdmins;
	};

	render() {
		const {
			isExportingList,
			isCreatingGroup,
			selectedUserEmails,
			selectedUsers,
			isMainChecked,
			groupData,
			isEditingTestAdmin,
			testAdmins,
			activeFilter,
		} = this.state;

		const filteredTestAdmins = this.getFilteredTestAdmins();

		return (
			<div className="lit-users">
				<nav>
					<div id={this.state.animator}></div>
					<a
						href="#"
						className={this.state.showUsersActive}
						id="defaultOpen"
						onClick={() => {
							this.setState({
								showUsers: true,
								showActivity: false,
								showSummary: false,
								showInvoices: false,
								showUsersActive: 'tablink1-active',
								showActivityActive: 'tablink2',
								showSummaryActive: 'tablink3',
								showInvoicesActive: 'tablink4',
								selectedUsers: defaultSelectedUsers,
								animator: 'marker-default',
							});
						}}
					>
						Brukere
					</a>
					<a
						href="#"
						className={this.state.showActivityActive}
						onClick={() => {
							this.setState({
								showUsers: false,
								showSummary: false,
								showActivity: true,
								showInvoices: false,
								showSummaryActive: 'tablink3',
								showUsersActive: 'tablink1',
								showActivityActive: 'tablink2-active',
								showInvoicesActive: 'tablink4',
								animator: 'marker-activity',
							});
						}}
					>
						Aktivitet
					</a>
					<a
						href="#"
						className={this.state.showSummaryActive}
						onClick={() => {
							this.setState({
								showUsers: false,
								showSummary: true,
								showActivity: false,
								showInvoices: false,
								showSummaryActive: 'tablink3-active',
								showUsersActive: 'tablink1',
								showActivityActive: 'tablink2',
								showInvoicesActive: 'tablink4',
								animator: 'marker-summary',
							});
						}}
					>
						Oppsumering
					</a>
					<a
						href="#"
						className={this.state.showInvoicesActive}
						onClick={() => {
							this.setState({
								showUsers: false,
								showSummary: false,
								showActivity: false,
								showInvoices: true,
								showSummaryActive: 'tablink3',
								showUsersActive: 'tablink1',
								showActivityActive: 'tablink2',
								showInvoicesActive: 'tablink4-active',
								animator: 'marker-invoices',
							});
						}}
					>
						Fakturering
					</a>
				</nav>
				<div className="lit-myTests-scroller">
					{isEditingTestAdmin && (
						<TestAdminModal
							onDelete={(adminUserId) => {
								this.deleteUserAdmin(adminUserId);
							}}
							onDeleteLanguage={(languageId) => {
								this.deleteLanguageSubscription(languageId);
							}}
							onExtendTrial={(adminUserId) => {
								this.extendTrial(adminUserId);
							}}
							onSave={(testAdminUpdates) => {
								this.saveTestAdmin(
									testAdminUpdates,
									editTestAdmin
								);
							}}
							onSaveSubscription={(testAdminUpdates) => {
								this.saveTestAdmin(
									testAdminUpdates,
									editTestAdminSubscription
								);
							}}
							onUpdateLanguageSubscription={(
								testAdminUpdates
							) => {
								this.SaveLanguageSubscription(
									testAdminUpdates,
									editLanguageSubscription
								);
							}}
							onAssignLanguageSubscription={(
								testAdminUpdates
							) => {
								this.NewLanguageSubscription(
									testAdminUpdates,
									assignLanguageSubscription
								);
							}}
							testAdmin={isEditingTestAdmin}
							language={language}
						/>
					)}
					{this.state.showUsers && Boolean(testAdmins.length) && (
						<div>
							<div className="lit-Users_wrapper">
								<div className="lit-input_status">
									{strings.status}
									<select
										className="lit-input__field"
										defaultValue={ALL}
										onChange={(e) => {
											this.setState({
												activeFilter: userStageFilters.filter(
													(filter) =>
														filter.id ===
														e.target.value
												)[0],

												selectedUsers: defaultSelectedUsers,
												selectedUserEmails: defaultSelectedEmails,
												isMainChecked,
											});
										}}
									>
										{userStageFilters.map(
											(userStageFilter) => (
												<option
													key={userStageFilter.id}
													value={userStageFilter.id}
												>
													{userStageFilter.label ||
														userStageFilter.text}
												</option>
											)
										)}
									</select>
								</div>
								<div className="lit-input_searchUser">
									<input
										className="lit-input__field"
										type="text"
										placeholder={strings.searchhere}
										id="testkey"
										onChange={(e) => {
											this.setState({
												searchFilter: e.target.value.toLowerCase(),
											});
										}}
									/>
								</div>
								<div>
									<div className="lit_email-btn">
										<button
											id="exp_mail_list"
											className={classNames(
												'print-hide',
												{
													'lit-btn see-more': true,
													'bg-tertiary': true,
												}
											)}
											onClick={() => {
												if (
													!selectedUserEmails.length
												) {
													alert(strings.select_users);
												} else {
													this.setState({
														isExportingList: !isExportingList,
													});
												}
											}}
										>
											{strings.exportmailingaddress}
										</button>
									</div>
								</div>
								<div className="lit_crate_group-btn">
									<button
										id="create_admin-group"
										className={classNames('print-hide', {
											'lit-btn see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											if (!selectedUsers.length) {
												alert(strings.select_users);
											} else {
												this.setState({
													isCreatingGroup: !isCreatingGroup,
												});
												this.getAndSetSelectedAdminGroups(
													selectedUsers
												);
											}
										}}
									>
										{strings.newgroup}
									</button>
								</div>
							</div>
							{Boolean(filteredTestAdmins.length) && (
								<table
									id="users_table"
									className="lit-list user-list"
								>
									<thead>
										<tr>
											<th>
												<input
													className="lit_cb_users"
													type="checkbox"
													id="myCheck"
													onClick={
														this.onUserCheckChanged
													}
												/>
											</th>
											<th>{strings.fullname}</th>
											<th>{strings.subscription}</th>
											<th>{strings.status}</th>
											<th>{strings.organization}</th>
											<th>{strings.email}</th>
											<th>{strings.telephone}</th>
										</tr>
									</thead>
									<tbody>
										{filteredTestAdmins.map((testAdmin) => {
											return (
												<TestAdminRow
													key={testAdmin.id}
													editUser={(event) => {
														event.preventDefault();
														this.setState({
															isEditingTestAdmin: testAdmin,
														});
													}}
													testAdmin={testAdmin}
													isMainChecked={
														this.state.isMainChecked
													}
													isSingleChecked={
														this.state
															.isSingleChecked
													}
													selectedUserEmails={
														this.state
															.selectedUserEmails
													}
													handleEmails={
														this.onHandleEmails
													}
													handleUsers={
														this.onHandleUsers
													}
												/>
											);
										})}
									</tbody>
								</table>
							)}
							{isExportingList &&
								Boolean(selectedUserEmails.length) && (
									<AddressList
										selectedEmails={selectedUserEmails}
										closeWindow={() => {
											this.setState({
												isExportingList: false,
											});
										}}
									/>
								)}

							{isCreatingGroup && Boolean(selectedUsers.length) && (
								<TestAdminGroups
									selectedUsers={selectedUsers}
									allGroupsUsers={groupData}
									onCreateGroup={(
										ids,
										subsIds,
										groupName
									) => {
										this.createNewAdminGroup(
											ids,
											subsIds,
											groupName
										);
									}}
									onAddingMembers={(group, members) => {
										this.addNewGroupMembers(group, members);
									}}
									closeWindow={() => {
										this.setState({
											isCreatingGroup: false,
										});
									}}
								/>
							)}
							{!filteredTestAdmins.length && (
								<p className="lit-list user-list">
									{strings.formatString(
										strings.message_nouserswiththestatus,
										activeFilter.label || activeFilter.text
									)}
								</p>
							)}
							<div className="lit-btn_grid">
								<div className="lit_crate_group-btn">
									<button
										className="lit-btn bg-primary3-red"
										onClick={() => {
											if (!selectedUsers.length) {
												alert(strings.select_users);
											} else {
												if (
													window.confirm(
														strings.warning_blockuser
													)
												) {
													this.forceLogoutUsers(
														selectedUsers
													);
												}
											}
										}}
									>
										{strings.blockuser}
									</button>
								</div>
								<div className="lit_crate_group-btn">
									<button
										className="lit-btn bg-primary3"
										onClick={() => {
											if (!selectedUsers.length) {
												alert(strings.select_users);
											} else {
												if (
													window.confirm(
														strings.warning_unblockuser
													)
												) {
													this.unblockUsers(
														selectedUsers
													);
												}
											}
										}}
									>
										{strings.unblockuser}
									</button>
								</div>
							</div>
						</div>
					)}
					{this.state.showActivity && !this.state.showUsers && (
						<TestAdminActivity
							testAdmins={testAdmins}
							selectedUsers={selectedUsers}
						/>

						/*  */
					)}
					{this.state.showSummary &&
						!this.state.showActivity &&
						!this.state.showUsers &&
						!this.state.showInvoices && (
							<TestAdminSummary
								totalAdmins={testAdmins.length}
								testAdmins={testAdmins}
							/>
						)}
					{this.state.showInvoices &&
						!this.state.showActivity &&
						!this.state.showUsers &&
						!this.state.showSummary && (
							<TestAdminInvoices
								testAdmins={testAdmins}
								selectedUsers={selectedUsers}
							/>
						)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

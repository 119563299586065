import React from 'react';
import './styles.scss';
import deleteGroupMember from '../../api/deleteGroupMember';
import deleteGroup from '../../api/deleteGroup';
import renameGroup from '../../api/renameGroup';
import getAllGroups from '../../api/getAllGroups';
import strings from '../../utils/strings';
import moment from 'moment';

class Groups extends React.Component {
	constructor() {
		super();
		this.state = {
			groupData: [], //Stores all the groups related data fetched from the back-end
			focusedGroup: '', //Points to the group that is in focus when user clicks 'Endre-gruppen' button
			newName: '',
		};
	}
	componentDidMount() {
		this.getAndSetAdminGroups();
	}
	deleteSelectedgroupmember(groupId, subsId) {
		deleteGroupMember(groupId, subsId).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	deleteSelectedgroup(data) {
		var id = data.map((id) => {
			return id.groupId;
		});
		deleteGroup(id[0]).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	renameSelectedgroup(id, name) {
		renameGroup(id, name).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	getAndSetAdminGroups() {
		getAllGroups().then((groupData) => {
			this.setState({
				groupData,
			});
		});
	}
	render() {
		const {groupData, focusedGroup} = this.state;
		const groups = groupData.map((group) => {
			return group.groupName;
		});
		const uniqueGroups = [...new Set(groups)]; //creatng an array of all groups
		//tempobj and permobj are used to map unique groups to their corresponding group members data
		let tempobj = [];
		const permobj = [];
		//creating array objects by mapping group members to groups
		for (let i = 0; i < uniqueGroups.length; i++) {
			for (let j = 0; j < this.state.groupData.length; j++) {
				if (uniqueGroups[i] === this.state.groupData[j].groupName) {
					tempobj.push({
						firstName: this.state.groupData[j].firstName,
						lastName: this.state.groupData[j].lastName,
						email: this.state.groupData[j].email,
						organization: this.state.groupData[j].organization,
						createdAt: this.state.groupData[j].created_at,
						subsId: this.state.groupData[j].subscription,
						groupId: this.state.groupData[j].Id,
						groupData: [],
					});
				}
			}
			permobj.push({name: uniqueGroups[i], tempobj});

			tempobj = [];
		}
		return (
			<div className="lit-groups">
				{permobj.length === 0 && <p>{strings.nogroupsfound}</p>}
				{permobj.map((obj) => {
					if (!obj.tempobj.length) {
						return null;
					}
					return (
						<div className="Groups" key={obj.name}>
							<div className="lit_group-heading">
								<h3>{obj.name}</h3>
								<div className="delete_Group">
									<div className="inline-btn">
										<button
											className="lit-btn lit-btn--small bg-primary"
											onClick={() => {
												this.setState({
													focusedGroup: obj.name,
												});
											}}
										>
											{strings.editgroup}
										</button>
									</div>
									{focusedGroup === obj.name && (
										<div className="lit-edit-group-container">
											<div className="inline-textFiled2">
												<input
													type="text"
													placeholder={obj.name}
													autoFocus
													className="inline-textFiled"
													onChange={(e) => {
														this.setState({
															newName:
																e.target.value,
														});
													}}
												/>
											</div>
											<div className="inline-btn">
												<button
													className="lit-btn lit-btn--small bg-secondary"
													disabled={
														!Boolean(
															this.state.newName
																.length
														)
													}
													onClick={() => {
														this.renameSelectedgroup(
															obj.tempobj[0]
																.groupId,
															this.state.newName
														);
													}}
												>
													{strings.save}
												</button>
											</div>
											<div className="inline-btn">
												<button
													className="lit-btn lit-btn--small bg-negative"
													onClick={() => {
														if (
															window.confirm(
																strings.warning_deletegroup
															)
														) {
															this.deleteSelectedgroup(
																obj.tempobj
															);
														}
													}}
												>
													{strings.deletegroup}
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
							<table className="lit-list">
								<thead>
									<tr>
										<th>{strings.firstname}</th>
										<th>{strings.lastname}</th>
										<th>{strings.email}</th>
										<th>{strings.organization}</th>
										<th>{strings.date}</th>
										{focusedGroup === obj.name && <th />}
									</tr>
								</thead>
								<tbody>
									{obj.tempobj.map((memberdata, index) => {
										const timeCreated = moment(
											memberdata.createdAt
										);

										return (
											<tr
												key={index}
												className="lit-list__item"
											>
												<td>{memberdata.firstName}</td>
												<td>{memberdata.lastName}</td>

												<td>{memberdata.email}</td>
												<td>
													{memberdata.organization}
												</td>
												<td>
													{moment(timeCreated).format(
														'LL'
													)}
												</td>
												{focusedGroup === obj.name && (
													<td>
														<button
															className="lit-btn lit-btn--small bg-negative"
															onClick={() => {
																if (
																	window.confirm(
																		strings.warning_deletemember
																	)
																) {
																	this.deleteSelectedgroupmember(
																		memberdata.groupId,
																		memberdata.subsId
																	);
																}
															}}
														>
															{strings.delete}
														</button>
													</td>
												)}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					);
				})}
			</div>
		);
	}
}
export default Groups;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import queryString from 'query-string';

import './styles.scss';
import Logo from '../../assets/dysmate-logo-hvit-small.png';

import {initAuthAction} from '../../actions//index';
import {BANK_ID, BANK_ID_MOBILE, FEIDE} from '../../constants';

import strings from '../../utils/strings';
import EmailPasswordLogin from '../../pages//EmailPasswordLogin';

const parsedSearch = queryString.parse(window.location.search);

class NewLoginMethod extends React.Component {
	constructor() {
		super();
		this.state = {
			isCreatingNewUser: false,
			passwordLogin: false,
			hasCreatedUser: false,
			createUserError: null,
			registrationToken: null,
			newMethod: null,
			hasInitiatedLoginChange: false,
			authError: null,
		};

		this.updateRegisterBoxHeight = this.updateRegisterBoxHeight.bind(this);
	}

	componentDidMount() {
		setTimeout(this.updateRegisterBoxHeight, 1000);
	}
	componentWillMount() {
		let params = new URLSearchParams(parsedSearch);
		const testKey = params.get('testKey');
		const regToken = params.get('id');
		const mtd = params.get('mtd') || 'feide';
		this.setState({
			registrationToken: regToken,
			newMethod: mtd,
		});
	}

	updateRegisterBoxHeight() {
		const dimensions = this.refs.registerBox.getBoundingClientRect();
		this.setState({
			registerBoxHeight: dimensions.height + 50,
			authError: this.props.error,
		});
	}

	render() {
		const {
			registerBoxHeight,
			isCreatingNewUser,
			hasCreatedUser,
			createUserError,
		} = this.state;
		const {iframeUrl, initAuth, isInit} = this.props;
		const showIframe = isInit && iframeUrl;

		const {error} = this.props;
		if (error) {
			setTimeout(() => {
				window.location.href = `/my-tests`;
			}, 5000);
		}
		return (
			<div>
				<div className="lit-registration-view">
					<header className="lit-header">
						<h1 className="lit-logo">
							<img
								className="lit-logo__img"
								src={Logo}
								alt="Literate logo"
							/>
						</h1>
						<nav className="lit-menu" />
					</header>
					<div className="lit-view__content">
						{!this.state.hasInitiatedLoginChange && (
							<div className="lit-login">
								{
									<div
										className="lit-login__wrapper lit-login__wrapper--dark"
										ref="registerBox"
									>
										<div
											className="lit-register__bg bg-primary"
											style={{
												height: registerBoxHeight,
												width: '120%',
											}}
										/>
										<div className="lit-register__fg">
											{!createUserError && (
												<h1 className="lit-login__header">
													{hasCreatedUser
														? strings.success
														: strings.newloginmethod}
												</h1>
											)}

											{!createUserError &&
												!hasCreatedUser && (
													<p className="lit-login__description">
														{
															strings.message_change_loginmethod
														}
													</p>
												)}
											<br></br>

											{!this.state
												.hasInitiatedLoginChange &&
												this.state.newMethod ===
													'bankid' && (
													<div className="lit-login__action-wrapper">
														<button
															className="lit-btn bg-white"
															onClick={() => {
																if (
																	!this.state
																		.registrationToken ||
																	!this.state
																		.newMethod
																) {
																	alert(
																		'Du må først bekrefte innloggingsmetoden din. Klikk på Personlig informasjon når du blir omdirigert.'
																	);
																	window.location.href = `/my-subscription`;
																} else {
																	this.setState(
																		{
																			hasInitiatedLoginChange: true,
																		}
																	);
																	initAuth(
																		BANK_ID,
																		null,
																		this
																			.state
																			.registrationToken,
																		this
																			.state
																			.newMethod,
																		'change'
																	);
																}
															}}
														>
															{
																'Logg inn med BankID'
															}
														</button>
														{!this.state
															.hasInitiatedLoginChange &&
															this.state
																.newMethod ===
																'feide' && (
																<div className="lit-login__action-wrapper">
																	<button
																		className="lit-btn bg-white"
																		onClick={() => {
																			window.location.href = `/my-tests`;
																		}}
																	>
																		{
																			'Avbryt'
																		}
																	</button>
																</div>
															)}
													</div>
												)}
											{!this.state
												.hasInitiatedLoginChange &&
												this.state.newMethod ===
													'feide' && (
													<div className="lit-login__action-wrapper">
														<div className="lit-login__proceed_btn">
															<button
																className="lit-btn bg-custom"
																onClick={() => {
																	if (
																		!this
																			.state
																			.registrationToken ||
																		!this
																			.state
																			.newMethod
																	) {
																		alert(
																			'Du må først bekrefte innloggingsmetoden din. Klikk på Personlig informasjon når du blir omdirigert.'
																		);
																		window.location.href = `/my-subscription`;
																	} else {
																		this.setState(
																			{
																				hasInitiatedLoginChange: true,
																			}
																		);
																		initAuth(
																			FEIDE,
																			null,
																			this
																				.state
																				.registrationToken,
																			this
																				.state
																				.newMethod,
																			'change'
																		);
																	}
																}}
															>
																{
																	'Logg inn med Feide'
																}
															</button>
														</div>
														<div className="lit-login__cancel_btn">
															{!this.state
																.hasInitiatedLoginChange &&
																this.state
																	.newMethod ===
																	'feide' && (
																	<div className="lit-login__action-wrapper">
																		<button
																			className="lit-btn bg-custom"
																			onClick={() => {
																				window.location.href = `/my-tests`;
																			}}
																		>
																			{
																				'Avbryt'
																			}
																		</button>
																	</div>
																)}
														</div>
													</div>
												)}
											{!this.state
												.hasInitiatedLoginChange &&
												this.state.newMethod ===
													'password' && (
													<div className="lit-login__action-wrapper">
														<div className="lit-login__proceed_btn">
															<button
																className="lit-btn bg-custom"
																onClick={() => {
																	if (
																		!this
																			.state
																			.registrationToken ||
																		!this
																			.state
																			.newMethod
																	) {
																		alert(
																			'Du må først bekrefte innloggingsmetoden din. Klikk på Personlig informasjon når du blir omdirigert.'
																		);
																		window.location.href = `/my-subscription`;
																	} else {
																		this.setState(
																			{
																				hasInitiatedLoginChange: true,
																				passwordLogin: true,
																			}
																		);
																	}
																}}
															>
																{
																	'Logg inn med Epost'
																}
															</button>
														</div>
														<div className="lit-login__cancel_btn">
															{!this.state
																.hasInitiatedLoginChange &&
																this.state
																	.newMethod ===
																	'feide' && (
																	<div className="lit-login__action-wrapper">
																		<button
																			className="lit-btn bg-custom"
																			onClick={() => {
																				window.location.href = `/my-tests`;
																			}}
																		>
																			{
																				'Avbryt'
																			}
																		</button>
																	</div>
																)}
														</div>
													</div>
												)}
										</div>
									</div>
								}
							</div>
						)}
						{error && (
							<div className="lit-change-login-error">
								<p className="find-me">
									Unable to change login Method. <br />
									Error: <strong>{error}.</strong>
									<br />
									Please try again later.
								</p>
							</div>
						)}

						{showIframe && this.state.newMethod === 'feide' && (
							<div className="bank-id-iframe-wrapper-modal">
								<iframe
									title="login-frame"
									frameBorder="0"
									id="authFrame"
									scrolling="no"
									src={iframeUrl}
									style={{
										width: '120%',
										minHeight: '605px',
										left: '90%',
									}}
								/>
							</div>
						)}
						{showIframe && this.state.newMethod !== 'feide' && (
							<div
								className="bank-id-iframe-wrapper-modal"
								style={{
									width: '90%',
									minHeight: '200px',

									alignItems: 'center',
								}}
							>
								<iframe
									title="login-frame"
									frameBorder="0"
									id="authFrame"
									scrolling="no"
									src={iframeUrl}
									style={{
										width: '70%',
										minHeight: '200px',
										left: '90%',
									}}
								/>
							</div>
						)}
						{this.state.passwordLogin &&
							this.state.newMethod === 'password' && (
								<EmailPasswordLogin
									regTokenChange={
										this.state.registrationToken
									}
								/>
							)}
					</div>
				</div>
			</div>
		);
	}
}

NewLoginMethod.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
	currentUser: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;
	const {currentUser} = state;

	return {
		currentUser,
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLoginMethod);

import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';
import strings from '../utils/strings';

import getFetchOptions from '../utils/getFetchOptions';

export default function editTestAdmin(info) {
	return fetch(
		`${ENDPOINT_URL}/adminuser/renameclass`,
		getFetchOptions({
			method: 'PUT',
			body: JSON.stringify(info),
		})
	)
		.then((response) => {
			if (response.status === 409) alert(strings.classexistchoosename);
			else if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}

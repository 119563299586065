import React from 'react';
import './styles.scss';
import moment from 'moment';
import strings from '../../utils/strings';

const relevantNames = {
	noBackOffice: 'Norsk',
	deBackOffice: 'Tysk',
	seBackOffice: 'Svensk',
	nnBackOffice: 'Nynorsk',
	enBackOffice: 'Engelsk',
	deFileFollowup: 'Tysk',
	noFileFollowup: 'Norsk',
	seFileFollowup: 'Svensk',
	nnFileFollowup: 'Nynorsk',
	enFileFollowup: 'Engelsk',
	noScreening: 'Norsk',
	deScreening: 'Tysk',
	seScreening: 'Svensk',
	nnScreening: 'Nynorsk',
	enScreening: 'Engelsk',
	valuePushed: false,
	newValue: null,
};

const totalLanguages = ['Norsk', 'Tysk', 'Svensk', 'Nynorsk', 'Engelsk'];
let forLoopSuccess = false;
let folrLoopHeaderCount = 0;
let folrLoopBodyCount = 0;
class EditStrings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			globalChangeRequest: this.props.newStrings,
		};
		this.textarea = React.createRef();
	}

	reOrderTableHeader(filesForComparison) {
		if (folrLoopHeaderCount === 5) {
			folrLoopBodyCount = 0;
		}

		switch (folrLoopHeaderCount) {
			case 0:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'no') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 1:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'de') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 2:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'se') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 3:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'nn') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
			case 4:
				for (let i = 0; i < filesForComparison.length; i++) {
					if (filesForComparison[i].slice(0, 2) === 'en') {
						folrLoopHeaderCount = folrLoopHeaderCount + 1;
						return (
							<th key={i}>
								{relevantNames[filesForComparison[i]]}
							</th>
						);
					}
				}
		}
	}

	returnLanguageTickAndCross(
		includedIn,
		index,
		mainIndex,
		stringName,
		currentCodeBase
	) {
		forLoopSuccess = false;
		let languageIncludedIn = null;
		let languageExcludedIn = null;
		let currentIndex = null;
		if (folrLoopBodyCount === 5) {
			folrLoopBodyCount = 0;
		}

		for (let i = 0; i < includedIn.length; i++) {
			if (includedIn[i].includes(totalLanguages[folrLoopBodyCount])) {
				languageIncludedIn = totalLanguages[folrLoopBodyCount];
				folrLoopBodyCount = folrLoopBodyCount + 1;
				forLoopSuccess = true;
				break;
			}
		}
		if (forLoopSuccess) {
			return (
				<td
					className="lit-list__col"
					key={`main${mainIndex}index${index}`}
				>
					<button
						id={folrLoopBodyCount}
						className="edit-sl large"
						onClick={(event) => {
							this.props.fetchLanguageFile(
								stringName,
								this.props.currentCodeBase,
								languageIncludedIn,
								'edit'
							);
						}}
					>
						Edit
					</button>
				</td>
			);
		} else {
			currentIndex = folrLoopBodyCount;
			languageExcludedIn = totalLanguages[currentIndex];
			folrLoopBodyCount = folrLoopBodyCount + 1;
			return (
				<td
					className="lit-list__col"
					key={`main${mainIndex}index${index}`}
				>
					<button
						id={folrLoopBodyCount}
						className="add-sl norming large"
						onClick={(event) => {
							this.props.fetchLanguageFile(
								stringName,
								this.props.currentCodeBase,
								languageExcludedIn,
								'add'
							);
						}}
					>
						Add
					</button>
				</td>
			);
		}
	}
	sendValueForChange(value, stringToChange) {
		value = value.replace(/&nbsp|&nbsp;|&nbsp;/g, ' ');
		value = value.replace(/;/g, '');
		let changeRequestObj = this.state.globalChangeRequest;
		let {keyName, fileName} = stringToChange;
		if (!changeRequestObj.hasOwnProperty(fileName)) {
			changeRequestObj[fileName] = {};
			changeRequestObj[fileName][keyName] = value;
		} else {
			changeRequestObj[fileName][keyName] = value;
		}

		this.setState({
			globalChangeRequest: changeRequestObj,
			valuePushed: true,
		});
		setTimeout(() => {
			this.setState({
				valuePushed: false,
			});
		}, 2000);
	}
	render() {
		const {
			allStringsCatalog,
			stringToChange,
			filesForComparison,
			currentCodeBase,
		} = this.props;
		folrLoopHeaderCount = 0;
		return (
			<div className="lit-modal-stringsCatalog">
				<div
					className="lit-modal__ls-continent"
					onClick={(event) => {
						event.stopPropagation();
					}}
				></div>
				<div className="lit-string-catalog">
					<div className="lit-string-catalog-container">
						<div className="lit-strings-info-header">
							<h3>{currentCodeBase}</h3>
						</div>
						<div className="lit-close-icon">
							<span
								className="lit-close-sl-icon"
								onClick={() => {
									folrLoopHeaderCount = 0;
									this.props.closeWindow();
								}}
							>
								X
							</span>
						</div>
						<div className="lit-strings-theader-wrapper">
							<table className="lit-list-strings">
								<thead className="lit-strings-theader">
									<tr>
										<th>{'Key'}</th>
										{filesForComparison.map(
											(file, index) => {
												return this.reOrderTableHeader(
													filesForComparison
												);
											}
										)}
									</tr>
								</thead>

								<tbody className="lit-strings-tbody">
									{allStringsCatalog.map(
										(signleString, mainIndex) => {
											const {
												stringName,
												includedIn,
											} = signleString;

											return (
												<tr
													className="lit-event-log__entry"
													key={mainIndex}
												>
													<td>{stringName}</td>
													{includedIn.map(
														(isIn, index) => {
															const valueReturned = this.returnLanguageTickAndCross(
																includedIn,
																index,
																mainIndex,
																stringName,
																currentCodeBase
															);
															return valueReturned;
														}
													)}
												</tr>
											);
										}
									)}
								</tbody>
							</table>
							<br></br>
						</div>
						{stringToChange && (
							<div className="lit-edistrings">
								<h3>{`${stringToChange.language} - ${stringToChange.codeBase}`}</h3>
								<div className="lit-edistrings__change">
									<div>
										<span>{`${stringToChange.keyName}:`}</span>
									</div>
									<div
										className="lit-content-area"
										ref={this.textarea}
										id="contenteditable-root"
										contenteditable="plaintext-only"
										dir="auto"
									>
										{stringToChange.keyValue}
									</div>
								</div>
								<div className="lit-change-btn-group">
									<div className="lit-lang-sendChange">
										<button
											className="lit-lang-sendChange__bg"
											onClick={() => {
												const value = this.textarea
													.current.innerHTML;
												if (value)
													this.sendValueForChange(
														value,
														stringToChange
													);
												else {
													alert(
														'Du har ikke endret noe'
													);
												}
											}}
										>
											Push Change
										</button>
									</div>
									<div className="lit-lang-sendChange">
										<button
											className="lit-lang-sendChange__hg"
											onClick={() => {
												this.props.sendChangeRequests(
													this.state
														.globalChangeRequest
												);
											}}
										>
											Submit Changes
										</button>
									</div>
								</div>
								{this.state.valuePushed && (
									<div className=" lit-short-msg">
										Pushed!
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default EditStrings;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './styles.scss';

import strings from '../../utils/strings';
import {updateObject} from '../../utils/reducerUtils';
import isTrialActive from '../../utils/isTrialActive';
import contract from '../../assets/Literate_KundeAvtale.pdf';

import {
	ALL,
	NOT_STARTED,
	DISTRIBUTED,
	STARTED,
	COMPLETED,
	TRIAL,
	BASIC,
	ACTIVE,
} from '../../constants';

import NewTestModal from './NewTestModal';
import MyCandidates from './MyCandidates';
import MyClasses from './MyClasses';
import TestRow from './TestRow';
import DumpTests from './DumpTests';
import CandidateInfo from './CandidateInfo';
import AddTestToGroup from './AddTestToGroup';
import InformationPane from '../../components/InformationPane';

import distributeKey from '../../api/distributeKey';
import softDeleteTest from '../../api/softDeleteTest';
import distributeKeyAndStoreInfo from '../../api/distributeKeyAndStoreInfo';
import getCorrespondingGroups from '../../api/getCorrespondingGroups';
import getTestAdminGroupResults from '../../api/getTestAdminGroupResults';
import addCandidatesToClass from '../../api/addCandidatesToClass';

import createAdminClass from '../../api/createAdminClass';
import addTestToGroup from '../../api/addTestToGroup';
import removeTestsFromGroups from '../../api/removeTestsFromGroups';
import createTests from '../../api/createTests';
import createBulkTests from '../../api/createBulkTests';
import getTestsForDump from '../../api/getTestsForDump';
import deleteClassMembers from '../../api/deleteClassMembers';
import deleteClasses from '../../api/deleteClasses';
import deleteAllTestsPermanently from '../../api/deleteAllTestsPermanently';
import deleteSingleTestPermanently from '../../api/deleteSingleTestPermanently';
import getFilteredTestResults from '../../utils/getFilteredTestResults';
import getAllClasses from '../../api/getAllClasses';
import getBatchedTestResults from '../../utils/getBatchedTestResults';
import addMomentsToTestResults from '../../utils/addMomentsToTestResults';
import getTestAdminTestResults from '../../api/getTestAdminTestResults';
import getTestUsersForTestAdmin from '../../api/getTestUsersForTestAdmin';
import renameClass from '../../api/renameClass';
import restartTest from '../../api/restartTest';
const statusFilters = [ALL, NOT_STARTED, DISTRIBUTED, STARTED, COMPLETED];

const allMyTests = strings.allmytests;
const defaultExistingKeysForGroup = [];

const batchTestResult = (testResults) => {
	const testResultsWithStatus = testResults.map((testResult) => {
		let status;
		if (testResult.completedAt) {
			status = COMPLETED;
		} else if (testResult.usedAt) {
			status = STARTED;
		} else if (testResult.distributedAt) {
			status = DISTRIBUTED;
		} else {
			status = NOT_STARTED;
		}

		return {...testResult, status};
	});

	const testResultsWithMoments = addMomentsToTestResults(
		testResultsWithStatus
	);

	return getBatchedTestResults(testResultsWithMoments);
};

class MyTests extends React.Component {
	constructor() {
		super();

		this.monthRefs = {};

		this.state = {
			activeFilter: ALL,
			isAddingNewTest: false,
			isAddingTestToGroup: false,
			batchedTestResults: null,
			testResults: [],
			selectedGroup: '',
			groupsobject: [],
			groups: [],
			isGroupPanelActive: false,
			groupTests: [],
			grouptests: false,
			adminClasses: [],
			classData: [],
			showPricingInfo: false,
			subscription_id: null,
			userLanguages: null,
			testsForDumping: [],
			isDumpingTests: false,
			showMyTests: true,
			showMyCandidates: false,
			showMyClasses: false,
			showTestActive: 'tablink1-active',
			showCandidatesactive: 'tablink2',
			showClassesActive: 'tablink3',
			averageZscore: [],
			testUsers: [],
			batchedFilteredTestResults: null,
			existingKeysForGroup: defaultExistingKeysForGroup,
			animator: 'marker-default',
			showInfoPanel: false,
			infoPanelTestKey: null,
			initiatesRedistribution: false,
			paneVariable: null,
			paneMessage: '',
			initiateSoftDeletion: false,
			renderPaneMessage: false,
		};

		/* this.getTestsReadyForDump = this.getTestsReadyForDump.bind(this); */

		this.getAndsetTestAdminTestUsers = this.getAndsetTestAdminTestUsers.bind(
			this
		);
		/* this.getCorrespondingGroups = this.getCorrespondingGroups.bind(this); */
		this.getAndSetTestAdminGroupTestResults = this.getAndSetTestAdminGroupTestResults.bind(
			this
		);
		this.renderCandidateInfoPanel = this.renderCandidateInfoPanel.bind(
			this
		);
		this.setStateWithBatchedFilteredResults = this.setStateWithBatchedFilteredResults.bind(
			this
		);
		/* 
		this.getAndSetUserClasses = this.getAndSetUserClasses.bind(this); */
		this.setUserLanguages = this.setUserLanguages.bind(this);
		this.determineValidSubscription = this.determineValidSubscription.bind(
			this
		);
		this.dumpAllTests = this.dumpAllTests.bind(this);
		/* this.dumpSingleTest = this.dumpSingleTest.bind(this); */
	}

	componentDidMount() {
		this.loadMyTests();
		this.getAndSetGroupMenu();

		this.getAndsetTestAdminTestUsers();
		this.getAndSetAdminClasses();
		//window.addEventListener('focus', this.loadMyTests);
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.loadMyTests);
	}
	reloadPage(time) {
		setTimeout(function () {
			window.location.reload();
		}, time);
	}

	loadMyTests = async () => {
		const {currentUser} = this.props;
		const id = currentUser.id;

		let testResults = await getTestAdminTestResults(id);
		const batchedTestResults = batchTestResult(testResults);

		let batchedFilteredTestResults = getFilteredTestResults(
			batchedTestResults,
			this.state.activeFilter
		);

		this.setState({
			testResults,
			batchedTestResults,
			showInfoPanel: false,
			batchedFilteredTestResults,
		});
	};

	determineValidSubscription(languages) {
		const searchAbaleSubscriptionFields = [
			'child',
			'youth',
			'adult',
			'norming_child',
			'adult_followup',
			'child_followup',
			'youth_followup',
			'norming_youth',
		];
		for (let i = 0; i < languages.length; i++) {
			for (const field of searchAbaleSubscriptionFields) {
				if (languages[i][field] !== 0) {
					return false;
				}
			}
		}
		return true;
	}

	getTestsReadyForDump() {
		const {currentUser} = this.props;
		if (currentUser.isSuperAdmin) {
			return getTestsForDump().then((results) => {
				const tests = results;

				return new Promise((resolve) => {
					if (tests.length) {
						this.setState(
							{
								testsForDumping: tests,
							},
							resolve
						);
					} else {
						this.setState(
							{
								testsForDumping: [],
							},
							resolve
						);
					}
				});
			});
		}
	}
	sendCodeUsingEmail = (id, testKey, emailAddress) => {
		distributeKey(testKey, emailAddress)
			.then((res) => {
				if (res.message) {
					this.setState({
						initiatesRedistribution: false,
						paneMessage: 'Testen er sendt til kandidaten',
						renderPaneMessage: true,
					});
				}
			})
			.then(() => {
				return this.loadMyTests();
			});
	};
	continueTest(id, testKey) {
		this.setState({
			initiatesRedistribution: true,
			paneVariable: {id, testKey},
			paneMessage: strings.paneMessageContinueTest,
		});
	}
	redistributeContinuedTest(paneVariables) {
		if (paneVariables) {
			restartTest(paneVariables.id, paneVariables.testKey).then((res) => {
				if (res.message) {
					this.setState({
						initiatesRedistribution: false,
						paneMessage: strings.formatString(
							strings.testredistributed,
							<b>{`${res.pinCode}`}</b>
						),
						renderPaneMessage: true,
					});
					this.loadMyTests();
				}
			});
		}
	}

	renderStaticMessagePane(message) {
		this.setState({
			paneMessage: message,
			renderPaneMessage: true,
		});
	}
	renderDynamicMessagePane(message, id, key) {
		this.setState({
			initiateSoftDeletion: true,
			paneVariable: {id, key},
			paneMessage: message,
		});
	}
	dumpAllTests(testsForDump) {
		const allTestKeys = testsForDump.map((testKeys) => {
			return {testKey: testKeys.keyString, type: testKeys.type};
		});
		return deleteAllTestsPermanently(allTestKeys).then(() => {
			this.getTestsReadyForDump();
			this.loadMyTests();
			this.setState({
				isDumpingTests: false,
				initiatesRedistribution: false,
				paneMessage: 'Tester blir permanent sletter',
				renderPaneMessage: true,
			});
		});
	}

	dumpSingleTest(testId, testType, testKey) {
		return deleteSingleTestPermanently(testId, testType, testKey).then(
			() => {
				this.setState({
					initiatesRedistribution: false,
					paneMessage: 'Testen blir permanent sletter',
					renderPaneMessage: true,
				});
				this.getTestsReadyForDump();
				this.loadMyTests();
			}
		);
	}
	softDeleteTest = (returnedProps) => {
		const id = returnedProps.id;
		const testKey = returnedProps.testKey;
		/* e.preventDefault(); */

		softDeleteTest(id, testKey).then(() => {
			this.setState({
				initiateSoftDeletion: false,
				paneMessage: 'Testen er slettet',
				renderPaneMessage: true,
			});
			this.loadMyTests();
		});
	};
	renderCandidateInfoPanel(testKey, id, pinCode) {
		this.setState({
			showInfoPanel: true,
			infoPanelTestKey: {testKey: testKey, id: id, pinCode: pinCode},
		});
	}
	distributeTestWithInfo(
		isNorming,
		testKey,
		testUser,
		excludeReading,
		pinCode
	) {
		if (!testKey || !testUser) {
			return;
		}

		distributeKeyAndStoreInfo(
			isNorming,
			testKey,
			testUser,
			excludeReading,
			pinCode
		)
			.then((res) => {
				if (res.message) {
					const msgText =
						pinCode && pinCode !== 'undefined'
							? `Informasjonen til kandidaten er lagret. Testen er klar og kan startes med pinkoden ${pinCode}`
							: `Informasjonen til kandidaten er lagret. Testen er klar og kan startes med pinkoden.`;
					this.setState({
						initiatesRedistribution: false,
						paneMessage: msgText,
						renderPaneMessage: true,
					});
				}
			})
			.then(this.loadMyTests);
	}
	assignCandidatesToClass(testCandidates) {
		if (!testCandidates.length) return;
		addCandidatesToClass(testCandidates).then(() => {
			this.getAndSetAdminClasses();
			alert(strings.testcandidateadded);
			this.setState({
				showClassesActive: 'tablink3-active',
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2',
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
			});
		});
	}
	deleteSelectedClassMembers(testCandidates, targetClass, subsid) {
		deleteClassMembers(testCandidates, targetClass, subsid).then(() => {
			this.getAndSetAdminClasses();
			alert(strings.testcandidateremoved);
			this.setState({
				animator: 'marker-myclasses',
				showClassesActive: 'tablink3-active',
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2',
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
			});
		});
	}
	deleteSelectedClasses(extractedIds, subsId) {
		deleteClasses(extractedIds, subsId).then(() => {
			this.getAndSetAdminClasses();
			this.setState({
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
			});
		});
	}

	createNewAdminClass(usersIds, adminId, subsId, className) {
		if (!usersIds || !adminId || !subsId || !className) {
			alert(strings.message_incompletData);
			return;
		}
		createAdminClass(usersIds, adminId, subsId, className).then((res) => {
			if (!res.error) {
				this.setState({
					paneMessage: 'Ny Klasse opprettet',
					renderPaneMessage: true,
				});
				this.getAndSetAdminClasses();
				this.setState({
					animator: 'marker-myclasses',
					showClassesActive: 'tablink3-active',
					showTestActive: 'tablink1',
					showCandidatesactive: 'tablink2',
					showMyTests: false,
					showMyCandidates: false,
					showMyClasses: true,
				});
			}
			/* this.setState({
				isCreatingGroup: false,
			}); */
		});
	}

	isRenamingClass(info) {
		return renameClass(info).then(() => {
			this.getAndSetAdminClasses();
			this.setState({
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3-active',
			});
		});
	}

	getAndSetGroupMenu() {
		const {currentUser} = this.props;
		const id = currentUser.id;
		if (currentUser.isSuperAdmin) {
			this.getTestsReadyForDump();
		}
		return getCorrespondingGroups(id).then((results) => {
			const groupsobject = results;

			return new Promise((resolve) => {
				if (groupsobject.length) {
					const groups = groupsobject.map((groups) => {
						return groups.name;
					});

					this.setState(
						{
							groups,
							selectedGroup: groupsobject[0].id,
							groupsobject,
						},
						resolve
					);
				} else {
					this.setState(
						{
							groups: [],
							selectedGroup: '',
						},
						resolve
					);
				}
			});
		});
	}

	async getAndSetTestAdminGroupTestResults(selectedGroup) {
		if (selectedGroup !== '') {
			this.loadMyTests();
		}
		return null;
	}

	getAndsetTestAdminTestUsers() {
		return getTestUsersForTestAdmin().then((testUsers) => {
			return new Promise((resolve) => {
				this.setState(
					{
						testUsers,
					},
					resolve
				);
			});
		});
	}
	getAndSetAdminClasses() {
		const {currentUser} = this.props;
		const id = currentUser.id;
		this.getAndsetTestAdminTestUsers();
		getAllClasses(id).then((classData) => {
			const adminClasses = classData.map((usersClass) => ({
				className: usersClass.className,
				classId: usersClass.classId,
			}));

			this.setState({
				classData,
				adminClasses,
			});
		});
	}

	setUserLanguages(user) {
		const languages = user.subscription.languages;
		const finalProduct = [];
		const allLanguages = [];
		for (let i = 0; i < languages.length; i++) {
			const languageKey = languages[i].language;
			allLanguages[languageKey] = languages[i];
		}
		finalProduct.push(allLanguages);
		this.setState({userLanguages: finalProduct});
	}

	toggleShowPricingInfo() {
		this.setState({showPricingInfo: !this.state.showPricingInfo});
	}
	validateLanguageSubscription(selectedLanguage, testkeyTypes) {
		const {currentUser} = this.props;
		const languages = currentUser.subscription.languages;
		const subscriptionFields = [
			'child',
			'youth',
			'adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
			'norming_child',
			'norming_youth',
			'norming_adult',
			'basic',
		];
		let flag = 0;
		for (let i = 0; i < languages.length; i++) {
			if (languages[i].language === selectedLanguage) {
				for (let j = 0; j < testkeyTypes.length; j++) {
					for (const field of subscriptionFields) {
						if (languages[i][field] === 1) {
							if (
								field === 'child' &&
								testkeyTypes[j].type === 'child'
							) {
								flag = flag + 1;
							}
							if (
								field === 'youth' &&
								testkeyTypes[j].type === 'youth-screening'
							) {
								flag = flag + 1;
							}
							if (
								field === 'adult' &&
								testkeyTypes[j].type === 'adult-screening'
							) {
								flag = flag + 1;
							}
							if (
								field === 'youth_followup' &&
								testkeyTypes[j].type === 'youth-followup'
							) {
								flag = flag + 1;
							}
							if (
								field === 'adult_followup' &&
								testkeyTypes[j].type === 'adult-followup'
							) {
								flag = flag + 1;
							}
						}
					}
				}
				break;
			}
		}
		if (flag === testkeyTypes.length) {
			return true;
		}
		return false;
	}
	getTestKey(selectedKey) {
		this.setState({
			existingKeysForGroup: [
				...this.state.existingKeysForGroup,
				{key: selectedKey},
			],
		});
	}
	removeTestKey(selectedKey) {
		const allTestKeys = this.state.existingKeysForGroup;
		const indexVal = this.findWithProp(allTestKeys, 'key', selectedKey);

		if (indexVal > -1) {
			allTestKeys.splice(indexVal, 1);
			this.setState({
				existingKeysForGroup: allTestKeys,
			});
		}
	}
	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}
	addingTestToGroup(groupId, selectedTests) {
		for (let i = 0; i < selectedTests.length; i++) {
			selectedTests[i].groupId = groupId;
		}

		addTestToGroup(selectedTests).then(() => {
			alert(
				strings.formatString(
					strings.selectedTestAddedToGroup,
					`${selectedTests.length > 1 ? 'tester' : 'test'}`
				)
			);
			this.loadMyTests();

			this.setState({
				isAddingTestToGroup: false,
				existingKeysForGroup: [],
			});
			this.setState({
				showMyTests: false,
				showMyCandidates: true,
				showMyClasses: false,
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2-active',
				showClassesActive: 'tablink3',
			});
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
		});
	}
	removingTestsFromGroups(selectedTests) {
		removeTestsFromGroups(selectedTests).then(() => {
			/* alert(
				`Valgte ${
					selectedTests.length > 1 ? 'tester' : 'test'
				} fjernet fra grupper`
			); */
			alert(
				strings.formatString(
					strings.selectedTestRemovedFromGroup,
					`${selectedTests.length > 1 ? 'tester' : 'test'}`
				)
			);
			this.loadMyTests();
			this.setState({
				isAddingTestToGroup: false,
				existingKeysForGroup: [],
			});
		});
	}
	renderMonth(monthBatch) {
		const {testResults} = this.state;

		if (!monthBatch.testResults.length) {
			return null;
		}

		return (
			<div
				className="month"
				key={monthBatch.key}
				ref={(ref) => {
					this.monthRefs[monthBatch.key] = ref;
				}}
			>
				{/* {monthBatch.length === 0 && <p>{strings.nogroupsfound}</p>} */}
				<h3 className="month__heading">{monthBatch.name}</h3>
				<table className="lit-tests">
					<thead>
						<tr>
							{!this.state.isGroupPanelActive && (
								<td className="lit-test__header">
									{strings.choose}
								</td>
							)}
							<td className="lit-test__header">
								{strings.status}
							</td>
							<td className="lit-test__header">
								{strings.testkey}
							</td>
							<td className="lit-test__header">{strings.type}</td>
							<td className="lit-test__header">
								{strings.recipient}
							</td>
							<td className="lit-test__header">
								{strings.status}
							</td>
							<td colSpan="2" className="lit-test__header">
								{strings.actions}
							</td>
						</tr>
					</thead>
					<tbody>
						{monthBatch.testResults.map((testResult) => (
							<TestRow
								key={testResult.id}
								processTestResults={batchTestResult}
								testResult={testResult}
								testResults={testResults}
								onGettingTestKey={(selectedKey) => {
									return this.getTestKey(selectedKey); //removeTestKey
								}}
								onRemovingTestKey={(selectedKey) => {
									return this.removeTestKey(selectedKey); //removeTestKey
								}}
								groupPanel={this.state.isGroupPanelActive}
								onDelete={this.loadMyTests}
								onSoftDelete={this.loadMyTests}
								isSuperAdmin={
									this.props.currentUser.isSuperAdmin
								}
								onDistributeTest={(id, key, email) => {
									this.sendCodeUsingEmail(id, key, email);
								}}
								onStartTest={this.loadMyTests}
								onContinueTest={(e, id, testKey) => {
									this.continueTest(id, testKey);
								}}
								onshowInfoPanel={(testKey, id, pinCode) => {
									this.renderCandidateInfoPanel(
										testKey,
										id,
										pinCode
									);
								}}
								onRenderStaticMessagePane={(message) => {
									this.renderStaticMessagePane(message);
								}}
								onRenderDynamicMessagePane={(
									message,
									id,
									key
								) => {
									this.renderDynamicMessagePane(
										message,
										id,
										key
									);
								}}
							/>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	onSaveNewTest = async (
		type,
		newTestCount,
		groupId,
		language,
		anamnese,
		childSelfStarting
	) => {
		if (!type || !newTestCount || !language) {
			this.setState({
				isAddingNewTest: false,
			});
			return;
		}

		const newTestCountInt = parseInt(newTestCount, 10);

		if (!isFinite(newTestCountInt)) {
			return;
		}
		await createTests(
			type,
			newTestCountInt,
			groupId,
			language,
			anamnese,
			childSelfStarting
		);

		await this.loadMyTests();

		this.setState({
			isAddingNewTest: false,
		});
	};
	setStateWithBatchedFilteredResults(results) {
		this.setState({getBatchedTestResults: results});
	}
	onSaveNewBulkTest = async (
		type,
		newTestCount,
		groupId,
		language,
		testUsers,
		testkeyTypes,
		childSelfStarting
	) => {
		if (!type) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}

		if (newTestCount !== 1) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}
		if (!language) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}

		const newTestCountInt = parseInt(testkeyTypes.length, 10);
		if (!isFinite(newTestCountInt)) {
			return;
		}
		await createBulkTests(
			testkeyTypes,
			newTestCountInt,
			groupId,
			language,
			type,
			childSelfStarting
		);

		await this.loadMyTests();

		this.setState({
			showMyTests: true,
			showMyCandidates: false,
			showMyClasses: false,
			showTestActive: 'tablink1-active',
			showCandidatesactive: 'tablink2',
			showClassesActive: 'tablink3',
			animator: 'marker-default',
		});
	};

	onSelectGroup = (e) => {
		if (e.target.value === allMyTests) {
			this.loadMyTests();
			this.setState({
				isGroupPanelActive: false,
			});
		} else {
			const chosenGroup = this.state.groupsobject.filter(
				(groupname) => groupname.name === e.target.value
			);
			const activegroup = chosenGroup[0].id;
			this.getAndSetTestAdminGroupTestResults(activegroup);

			this.setState({
				selectedGroup: chosenGroup[0].id,
				isGroupPanelActive: true,
			});
		}
	};

	onSelectStatus = (e) => {
		const {batchedTestResults} = this.state;

		const activeFilter = statusFilters.filter(
			(filter) => filter.id === e.target.value
		)[0];

		let batchedFilteredTestResults;
		if (batchedTestResults) {
			batchedFilteredTestResults = getFilteredTestResults(
				batchedTestResults,
				activeFilter
			);
		}

		this.setState({
			activeFilter,
			showInfoPanel: false,
			batchedFilteredTestResults,
		});
	};

	renderUpgradeBox(isBasicUser, isTrialUser, trialIsActive) {
		return (
			(isTrialUser || isBasicUser) && (
				<div className="lit-new-subscription">
					{isTrialUser && !trialIsActive && (
						<h3 className="lit-new-subscription__header">
							{strings.yourtrialperiodhasexpired}
						</h3>
					)}
					{(isBasicUser || (isTrialUser && trialIsActive)) && (
						<h3 className="lit-new-subscription__header">
							{strings.upgradeyoursubscription}
						</h3>
					)}
					<p className="lit-new-subscription__description">
						{strings.downloadandsendcontract}
						<a
							href="mailto:admin@literate.no"
							target="_blank"
							rel="noopener noreferrer"
						>
							{`admin@literate.no`}
						</a>
						{strings.priceandconditioninfo}
						<a
							href="https://literate.no/pris-og-kjopsbetingelser/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{`https://literate.no/pris-og-kjopsbetingelser/`}
						</a>
					</p>
					<a
						className="lit-btn bg-primary"
						download="Literate_KundeAvtale.pdf"
						href={contract}
					>
						{strings.downloadcontract}
					</a>
				</div>
			)
		);
	}

	render() {
		const {currentUser} = this.props;
		const languages = currentUser.subscription.languages;
		const hasInValidSubscription = this.determineValidSubscription(
			languages
		);

		const {userStage} = currentUser;
		const {
			isAddingNewTest,
			userLanguages,
			testsForDumping,
			isDumpingTests,
			classData,
			classes,
		} = this.state;

		const trialIsActive = isTrialActive(currentUser);
		const isBasicUser = userStage === BASIC;
		const isTrialUser = userStage === TRIAL;
		const isActiveUser = userStage === ACTIVE;
		return (
			<div className="lit-tests-view">
				<nav>
					<div id={this.state.animator}></div>
					<a
						href="#"
						/* className={this.state.showTestActive} */
						id="defaultOpen"
						onClick={() => {
							this.setState({
								showMyTests: true,
								showMyCandidates: false,
								showMyClasses: false,
								showTestActive: 'tablink1-active',
								showCandidatesactive: 'tablink2',
								showClassesActive: 'tablink3',
								existingKeysForGroup: defaultExistingKeysForGroup,
								animator: 'marker-default',
							});
						}}
					>
						{strings.mytests}
					</a>
					<a
						href="#"
						/* className={this.state.showCandidatesactive} */
						onClick={() => {
							this.setUserLanguages(currentUser);
							this.setState({
								showMyTests: false,
								showMyCandidates: true,
								showMyClasses: false,
								showCandidatesactive: 'tablink2-active',
								showTestActive: 'tablink1',
								showClassesActive: 'tablink3',
								animator: 'marker-mycandidates',
							});
						}}
					>
						{strings.myCandidates}
					</a>
					<a
						href="#"
						/* className={this.state.showClassesActive} */
						onClick={() => {
							this.setState({
								showClassesActive: 'tablink3-active',
								showTestActive: 'tablink1',
								showCandidatesactive: 'tablink2',
								showMyTests: false,
								showMyCandidates: false,
								showMyClasses: true,
								animator: 'marker-myclasses',
							});
						}}
					>
						{strings.myclasses}
					</a>
				</nav>

				{/*scrollers starting div*/}
				{this.state.renderPaneMessage && (
					<InformationPane
						paneMessage={this.state.paneMessage}
						onApproval={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						onCloseWindow={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						onDisproval={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						category={'information'}
					/>
				)}
				{this.state.showMyTests && (
					<div className="lit-tests-view__header">
						<div className="lit-new-test__wrapper">
							{isTrialUser && (
								<p>{strings.onlytwotestspertrialperiod}</p>
							)}
							{isBasicUser && (
								<p>{strings.upgradetofullsubscription}</p>
							)}
							<div className="lit-btn_grid">
								<div>
									<button
										className="lit-btn bg-primary"
										disabled={
											hasInValidSubscription ||
											isBasicUser ||
											isTrialUser
										}
										onClick={() => {
											this.setUserLanguages(currentUser);
											this.setState({
												isAddingNewTest: true,
												grouptests: false,
											});
										}}
									>
										{strings.createnewtest}
									</button>
								</div>
								{Boolean(this.state.groups.length) && (
									<div>
										<button
											className="lit-btn bg-primary3"
											onClick={() => {
												this.setUserLanguages(
													currentUser
												);
												this.setState({
													isAddingTestToGroup: true,
													grouptests: false,
												});
											}}
											disabled={
												hasInValidSubscription ||
												Boolean(
													!this.state.groups.length
												) ||
												Boolean(
													!this.state
														.existingKeysForGroup
														.length
												)
											}
										>
											{strings.addtesttogroup}
										</button>
									</div>
								)}

								{currentUser.isSuperAdmin &&
									!this.state.isGroupPanelActive &&
									Boolean(testsForDumping.length) && (
										<div className="delete-olderTests">
											<button
												className={
													testsForDumping.length > 50
														? 'lit-btn bg-primary2'
														: 'lit-btn bg-primary2-disbaled'
												}
												disabled={
													!testsForDumping.length
												}
												onClick={() => {
													this.setState({
														isDumpingTests: true,
													});
												}}
											>
												{strings.deleteoldtests}
											</button>
										</div>
									)}
							</div>

							<div className="lit-View_tests">
								<div className="lit-dropdown_filter">
									{strings.statuses}
									<select
										className="lit-input__field"
										onChange={(e) => {
											this.onSelectStatus(e);
										}}
									>
										{statusFilters.map((filters) => (
											<option
												key={filters.id}
												value={filters.id}
											>
												{filters.label}
											</option>
										))}
									</select>
								</div>

								{Boolean(this.state.groups.length) && (
									<div className="Select_group-function">
										{strings.fromgroups}
										<select
											className="lit-input__field"
											defaultValue={allMyTests}
											onChange={(e) => {
												if (
													e.target.value ===
													allMyTests
												) {
													this.loadMyTests();
													this.setState({
														isGroupPanelActive: false,
													});
												} else {
													const chosenGroup = this.state.groupsobject.filter(
														(groupname) =>
															groupname.name ===
															e.target.value
													);

													const activegroup =
														chosenGroup[0].id;
													this.getAndSetTestAdminGroupTestResults(
														activegroup
													);

													this.setState({
														selectedGroup:
															chosenGroup[0].id,
														isGroupPanelActive: true,
													});
												}
											}}
										>
											<option key={1} value={allMyTests}>
												{allMyTests}
											</option>
											<option
												className="label_grupper-option"
												disabled="disabled"
											>
												{strings.groupindicator}
											</option>
											{this.state.groupsobject.map(
												(group) => (
													<option
														key={group.id}
														value={group.name}
													>
														{group.name}
													</option>
												)
											)}
										</select>
									</div>
								)}
							</div>
							{isAddingNewTest && (
								<NewTestModal
									onSave={this.onSaveNewTest}
									userStage={userStage}
									userGroups={this.state.groupsobject}
									userLanguages={userLanguages}
									currentUser={this.props.currentUser}
								/>
							)}

							{isDumpingTests && (
								<DumpTests
									testsForDumping={testsForDumping}
									closeWindow={() => {
										this.setState({
											isDumpingTests: false,
										});
									}}
									onDumpAllTests={this.dumpAllTests}
									onDumpSingleTest={(
										testId,
										testType,
										testKey
									) => {
										this.dumpSingleTest(
											testId,
											testType,
											testKey
										);
									}}
								/>
							)}
							{this.state.showInfoPanel && (
								<CandidateInfo
									closeWindow={() => {
										this.setState({
											showInfoPanel: false,
										});
									}}
									testResults={this.state.testResults}
									processTestResults={batchTestResult}
									infoPanelTestKey={
										this.state.infoPanelTestKey
									}
									onDistributeTest={this.loadMyTests}
									onDistributeKeyAndStoreInfo={(
										isNorming,
										testKey,
										testUser,
										excludeReading,
										pinCode
									) => {
										this.distributeTestWithInfo(
											isNorming,
											testKey,
											testUser,
											excludeReading,
											pinCode
										);
									}}
								/>
							)}
							{this.state.initiatesRedistribution && (
								<InformationPane
									paneMessage={this.state.paneMessage}
									variables={this.state.paneVariable}
									onApproval={(returnedProps) => {
										this.redistributeContinuedTest(
											returnedProps
										);
									}}
									onDisproval={() => {
										this.setState({
											initiatesRedistribution: false,
										});
									}}
									onCloseWindow={() => {
										this.setState({
											initiatesRedistribution: false,
										});
									}}
									category={'confirmation'}
								/>
							)}
							{this.state.initiateSoftDeletion && (
								<InformationPane
									paneMessage={this.state.paneMessage}
									variables={this.state.paneVariable}
									onApproval={(returnedProps) => {
										this.softDeleteTest(returnedProps);
									}}
									onDisproval={() => {
										this.setState({
											initiateSoftDeletion: false,
										});
									}}
									onCloseWindow={() => {
										this.setState({
											initiateSoftDeletion: false,
										});
									}}
									category={'confirmation'}
								/>
							)}

							{this.state.isAddingTestToGroup &&
								Boolean(
									this.state.existingKeysForGroup.length
								) && (
									<AddTestToGroup
										userGroups={this.state.groupsobject}
										groupPanel={
											this.state.isGroupPanelActive
										}
										selectedTests={
											this.state.existingKeysForGroup
										}
										closeWindow={() => {
											this.setState({
												isAddingTestToGroup: false,
											});
										}}
										onAddingTestToGroup={(
											groupId,
											selectedTests
										) => {
											this.addingTestToGroup(
												groupId,
												selectedTests
											);
										}}
										onRemovingFromGroups={(
											selectedTests
										) => {
											this.removingTestsFromGroups(
												selectedTests
											);
										}}
									/>
								)}
						</div>
						{this.renderUpgradeBox(
							isBasicUser,
							isTrialUser,
							trialIsActive
						)}
					</div>
				)}

				{this.state.batchedFilteredTestResults &&
					this.state.showMyTests &&
					this.state.batchedFilteredTestResults.map((monthBatch) =>
						this.renderMonth(monthBatch)
					)}
				{this.state.showMyCandidates && !this.state.showMyTests && (
					<MyCandidates
						userStage={userStage}
						userGroups={this.state.groupsobject}
						userLanguages={userLanguages}
						hasInValidSubscription={hasInValidSubscription}
						currentUser={currentUser}
						testUsers={this.state.testUsers}
						isBasicUser={isBasicUser}
						isTrialUser={isTrialUser}
						trialIsActive={trialIsActive}
						isActiveUser={isActiveUser}
						testResult={this.state.batchedFilteredTestResults}
						onSaveBulk={this.onSaveNewBulkTest}
						batchedFilteredTestResults={
							this.state.batchedFilteredTestResults
						}
						adminClasses={this.state.adminClasses}
						classData={classData}
						onRenamingClass={(newTitleAndId) => {
							this.isRenamingClass(newTitleAndId);
						}}
						onAddingCandidates={(testCandidates) => {
							this.assignCandidatesToClass(testCandidates);
						}}
						onDeletingCandidates={(
							testCandidates,
							targetClass,
							subsId
						) => {
							this.deleteSelectedClassMembers(
								testCandidates,
								targetClass,
								subsId
							);
						}}
						onCreateClass={(
							usersIds,
							adminId,
							subsId,
							className
						) => {
							this.createNewAdminClass(
								usersIds,
								adminId,
								subsId,
								className
							);
						}}
					/>
				)}
				{this.state.showMyClasses &&
					!this.state.showMyTests &&
					!this.state.showMyCandidates && (
						<MyClasses
							hasInValidSubscription={hasInValidSubscription}
							currentUser={currentUser}
							testUsers={this.state.testUsers}
							isBasicUser={isBasicUser}
							isTrialUser={isTrialUser}
							trialIsActive={trialIsActive}
							isActiveUser={isActiveUser}
							testResult={this.state.batchedFilteredTestResults}
							usersGroups={this.state.groupsobject}
							onSave={this.onSaveNewTest}
							userStage={userStage}
							userLanguages={userLanguages}
							batchedFilteredTestResults={
								this.state.batchedFilteredTestResults
							}
							onDeletingCLass={(extractedIds, subsId) => {
								this.deleteSelectedClasses(
									extractedIds,
									subsId
								);
							}}
							classes={classes}
							classData={classData}
						/>
					)}
				{/* Scrollers ending div */}
			</div>
		);
	}
}

MyTests.propTypes = {
	currentUser: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyTests);

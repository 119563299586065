import React, {useEffect, useState} from 'react';

import Login from './pages/Login';
import Backoffice from './pages/Backoffice';
import Registration from './pages/Registration';
import NewLoginMethod from './pages/NewLoginMethod';
import MyTests from './pages/MyTests';
import Subscription from './pages/MySubscription';
import Users from './pages/Users';
import Groups from './pages/Groups';
import LanguageStrings from './pages/LanguageStrings';
import AllTests from './pages/AllTests';
import AllChildTests from './pages/AllChildTests';
import SingleTest from './pages/SingleTest';
import SingleChildTest from './pages/SingleChildTest';
import SingleSpeechTest from './pages/SingleSpeechTest';
import EventLog from './pages/EventLog';

import {
	BrowserRouter as Router,
	Route,
	// Redirect,
	Switch,
} from 'react-router-dom';
import EnvironmentBanner from './components/EnvironmentBanner';
import {ENVIRONMENT, LANGUAGE} from './constants';
import strings from './utils/strings';

export const LanguageContext = React.createContext(null);
const App = () => {
	const [language, setLanguage] = useState();
	// const auth = useSelector((state) => state.auth);

	useEffect(() => {
		//Set up language switcher

		if (origin === 'http://localhost:3002') {
			setLanguage('en');
			strings.setLanguage('en');
		} else {
			setLanguage(LANGUAGE);
			strings.setLanguage(LANGUAGE);
		}
	});

	return (
		<div className="PageWrapper">
			<LanguageContext.Provider value={language}>
				<EnvironmentBanner environment={ENVIRONMENT} />
				<Router>
					<Switch>
						<Route path="/" exact component={Login} />
						<Route path="/login/" component={Login} />
						<Route path="/new-method/" component={NewLoginMethod} />
						<Route path="/registration/" component={Registration} />
						<Route path="/register/" component={Registration} />
						<Route
							path="/"
							render={() => (
								<Backoffice>
									<Route
										path="/my-tests"
										component={MyTests}
									/>
									<Route
										path="/single-test/:id"
										component={SingleTest}
									/>
									<Route
										path="/single-child-test/:id"
										component={SingleChildTest}
									/>
									<Route
										path="/single-speech-test/:id"
										component={SingleSpeechTest}
									/>
									<Route
										path="/my-subscription"
										component={Subscription}
									/>
									<Route
										path="/subscription/:id"
										component={Subscription}
									/>
									<Route
										path="/users"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{}
												</h1>
												<Users />
											</div>
										)}
									/>
									<Route
										path="/groups"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.groups}
												</h1>
												<Groups />
											</div>
										)}
									/>
									<Route
										path="/languages"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.language}
												</h1>
												<LanguageStrings />
											</div>
										)}
									/>

									<Route
										path="/all-child-scr-tests"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.childtests}
												</h1>
												<AllTests type="child-screening" />
											</div>
										)}
									/>
									<Route
										path="/all-tests"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.adulttests}
												</h1>
												<AllTests type="adult-screening" />
											</div>
										)}
									/>
									<Route
										path="/all-youth-tests"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.youthtests}
												</h1>
												<AllTests type="youth-screening" />
											</div>
										)}
									/>
									<Route
										path="/all-child-tests"
										render={() => (
											<div>
												<h1 className="lit-page-title">
													{strings.followuptests}
												</h1>
												<AllChildTests />
											</div>
										)}
									/>
									<Route
										path="/event-log"
										component={EventLog}
									/>
								</Backoffice>
							)}
						/>
					</Switch>
				</Router>
			</LanguageContext.Provider>
		</div>
	);
};

export default App;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import logout from '../../api/logout';

import strings from '../../utils/strings';
import Logo from '../../assets/dysmate-logo-hvit-small.png';

import MenuItem from './MenuItem';
import {LANGUAGE} from '../../constants';

strings.setLanguage(LANGUAGE);

const USERS = {name: strings.users, url: '/users'};
const GROUPS = {name: strings.groups, url: '/groups'};
const LANGUAGES = {name: strings.language, url: '/languages'};
const SUBSCRIPTION = {name: strings.mypage, url: '/my-subscription'};
const MY_TESTS = {name: strings.mytests, url: '/my-tests', altUrl: ''};
const ALL_SCREENING_TESTS = {name: strings.adulttests, url: '/all-tests'};
const ALL_YOUTH_SCREENING_TESTS = {
	name: strings.youthtests,
	url: '/all-youth-tests',
};
const ALL_CHILD_SCREENING_TESTS = {
	name: strings.childtests,
	url: '/all-child-scr-tests',
};
const ALL_CHILD_TESTS = {name: strings.followuptests, url: '/all-child-tests'};
const EVENT_LOG = {name: strings.logg, url: '/event-log'};
const USERSUPPORT = {
	name: strings.usersupport,
	url: 'https://literate.no/brukerstotte/',
	external: true,
};

const TEST_ADMIN_TABS = [MY_TESTS, SUBSCRIPTION, USERSUPPORT];
const SUPER_ADMIN_TABS = [
	MY_TESTS,
	USERS,
	GROUPS,
	LANGUAGES,
	ALL_CHILD_SCREENING_TESTS,
	ALL_SCREENING_TESTS,
	ALL_YOUTH_SCREENING_TESTS,
	ALL_CHILD_TESTS,
	USERSUPPORT,
	EVENT_LOG,
];

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {isSuperAdmin} = this.props;

		const {pathname} = this.props.location;

		const tabs = isSuperAdmin ? SUPER_ADMIN_TABS : TEST_ADMIN_TABS;

		return (
			<aside className="lit-sidebar">
				<h1 className="lit-logo">
					<img
						className="lit-logo__img"
						src={Logo}
						alt="literate logo"
					/>
				</h1>
				<nav className="lit-menu">
					<ul>
						{tabs.map((tab, index) => (
							<MenuItem
								key={index}
								tab={tab}
								pathname={pathname}
							/>
						))}
						<li>
							<button
								className="lit-menu__item lit-no-button"
								onClick={(e) => {
									e.preventDefault();
									logout();
								}}
							>
								{strings.loggout}
							</button>
						</li>
					</ul>
				</nav>
			</aside>
		);
	}
}

export default withRouter(Sidebar);

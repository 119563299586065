import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function updateTestScores(testKey, grade, answers) {
	return fetch(
		`${ENDPOINT_URL}/rescorespeechtest`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				testKey: testKey,
				grade: grade,
				answers,
				answers,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}

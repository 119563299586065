import {ENDPOINT_URL} from '../constants';
import {apiErrorHandling} from '../utils/apiUtils';

import getFetchOptions from '../utils/getFetchOptions';

export default function addCandidatesToClass(testUsers) {
	return fetch(
		`${ENDPOINT_URL}/addcandidates`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				testUsers,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
